import React, {useEffect, useState} from 'react';
import ApiRequest from "../../../utility/ApiRequest";
import sendForm from "../../../utility/SendForm";
import {toast} from "react-toastify";
import TableSales from "../../../components/TableSales/TableSales";
import LoadingIcon from "../../../components/LoadingIcon/LoadingIcon";
import Select from "react-select";

const AdminSalseTable = (props) => {

    const [loadsBy, setLoadsBy] = useState(false);
    const [sales, setSales] = useState(false);
    const [date_start, setDate_start] = useState("");
    const [date_end, setDate_end] = useState("");
    const [typePartID, setTypePartID] = useState("");

    const loadBy = (id_shop = "", date_start = "", date_end = "") => {
        setLoadsBy(false)

        ApiRequest("getSales?", "id_shop=" + id_shop + "&date_start=" + date_start + "&date_end=" + date_end).then((data) => {
            if (data['sales'])
                setSales(data['sales'])

            setLoadsBy(true);
        });
    }

    useEffect(() => {
        loadBy(props.shopData.id, "", "");
    }, []);

    return (
        <div>
            <hr />
            <div>
                <h6>Укажите период продаж</h6>
                <form action="getSales" id="getSalesForm" noValidate method="GET" className="form needs-validation"
                      onSubmit={(event) => sendForm(event, (data) => {
                          if (data['success']){
                              if (data['sales'])
                                  setSales(data['sales'])
                              toast.success(data['success']);
                          } else if (data['error'])
                              toast.error(data['error']);
                      }, false, )}>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="inputPassword" className="col-form-label">Дата начала периода</label>
                            <div className="form-group mt-1">
                                <div>
                                    <input type="date" className="form-control w-100" placeholder="" required onChange={(event) => setDate_start(event.target.value)} />
                                    <input type="text" className="form-control d-none" name="date_start" value={date_start} />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input type="text" className="form-control d-none" name="id_shop" value={props.shopData.id} />
                        <div className="col-md-3">
                            <label htmlFor="inputPassword" className="col-form-label">Дата окончания периода</label>
                            <div className="form-group mt-1">
                                <div>
                                    <input type="date" className="form-control w-100" placeholder="" required onChange={(event) => setDate_end(event.target.value)} />
                                    <input type="text" className="form-control d-none" name="date_end" value={date_end} />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputPassword" className="col-form-label">Выберите продавца (не обязательно)</label>
                            <div className="form-group mt-1">
                                <div>
                                    <select className="form-select" name="id_salesman" aria-label="Default select example">
                                        <option value="" selected>Выберите продавца</option>
                                        {
                                            Object.keys(props.shopUsers).map((index, i) => {
                                                return (props.shopUsers[index].role.includes("Продавец"))
                                                    ? <option value={props.shopUsers[index].id}>{props.shopUsers[index].fio}</option>
                                                    : false
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-10">
                            <div className="form-group">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Тип запчасти</label>
                                <Select
                                    options={props.mainData.sp_parts}
                                    placeholder="Тип запчасти"
                                    isSearchable={true}
                                    onChange={(event) => setTypePartID(event.value)}
                                />
                                <input type="text" className="form-control d-none" name="typePart" placeholder="" defaultValue={typePartID} />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label"></label>
                            <button className="btn btn-success btn-sm w-100 mt-3" type="submit">
                                Получить продажи
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="mt-3"></div>
            {
                (loadsBy)
                    ? <TableSales type="shop" id="tableSales1" sales={sales} />
                    : <LoadingIcon />
            }
        </div>
    );
};

export default AdminSalseTable;
