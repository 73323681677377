import React from 'react';
import {CallPrint, objectSize} from "../../../../utility/Utility";
import Modal2 from "../../../../components/Modal/Modal2";

const ModalPrintBarcode = (props) => {
    return (

        <Modal2 title="Печать штрихкода " id={(props.id_modal) ? props.id_modal : "printBarcode"}>
            {
                (objectSize(props.editPartData))
                    ? <div className="d-flex w-100" key={"printBarcode" + props.editPartData.id}>
                    <div id={"barcode_part_one_img_" + props.editPartData.id}>
                        <img src={props.editPartData.barcode_img} alt="" />
                    </div>
                    <button className="btn btn-success w-100" onClick={(event) => CallPrint("barcode_part_one_img_" + props.editPartData.id)}>Печать</button>
                    </div>
                    : false
            }
        </Modal2>
    );
};

export default ModalPrintBarcode;