import React from 'react';
import logo from "../images/logo-kuban_avto.png";
import {Link} from "react-router-dom";
import appGlobalParam from "../utility/AppGlobalParam";

const OpenPartTemplatePage = (props) => {
    return (
        <div>
            <div className="bg-dark vh-100">
                <div className="sufee-login d-flex align-content-center flex-wrap">
                    <div className="container" style={{maxWidth: 400}}>
                        <div className="login-content">
                            <div className="login-logo pb-2 text-center">
                                <Link to={appGlobalParam.domain}>
                                    <img className="align-content" src={logo} width="70%" alt="" />
                                </Link>
                            </div>
                            <div className="login-form  bg-light p-2 shadow">
                                <h2 className="text-center">{props.title}</h2>
                                <hr />
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpenPartTemplatePage;