import React from 'react';
import sendForm, {formDefaultStatusAllFrom} from "../../../utility/SendForm";
import {toast} from "react-toastify";
import PhoneInput from "../../../components/PhoneInput/PhoneInput";
import Modal2 from "../../../components/Modal/Modal2";

const SallesModalSetBooking = (props) => {
    return (
        <Modal2 title="Оформление брони на запчасть" id="modalsetbooking" key="modalsetbooking">
            <form onSubmit={(event) => sendForm(event, (data) => {
                if (data['success']){
                    toast.success(data['success']);

                    formDefaultStatusAllFrom();
                } else
                    toast.error(data['error']);
            }, true, true)} action="AddBookingPart" id="bookingPartCar" method="POST"
                  className="needs-validation" noValidate>

                <input type="text" className="form-control d-none" name="id_shop" value={props.id_shops} />

                <div className="form-group row mt-3">
                    <div className="col-12">
                        <div className="input-group mb-2">
                            <input type="number" className="form-control" name="id_part"
                                   placeholder="Номер запчасти: 12345678926539" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group row mt-3">
                    <div className="col-12">
                        <div className="input-group mb-2">
                            <input type="number" className="form-control" name="count"
                                   placeholder="Количество: 5" required />
                            <div className="input-group-prepend">
                                <div className="input-group-text">шт.</div>
                            </div>
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group row mt-3">
                    <div className="col-12">
                        <div className="input-group mb-2">
                            <input type="text" className="form-control" name="fio"
                                   placeholder="Иванов Иван Иванович" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group row mt-3">
                    <div className="col-12">
                        <div className="input-group mb-2">
                            <PhoneInput name="phone" id="phoneInputSetBooking" />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group row mt-3">
                    <div className="col-12">
                        <div className="input-group mb-2">
                            <input type="number" className="form-control" name="predoplat"
                                   placeholder="Предоплата: 1000"/>
                            <div className="input-group-prepend">
                                <div className="input-group-text">руб.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Оформить бронь" className="btn btn-success mt-3"/>
            </form>
        </Modal2>
    );
};

export default SallesModalSetBooking;