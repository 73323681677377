import React, {useEffect, useState} from 'react';
import AdminModalEditCar from "./AdminModalEditCar";
import AdminModalCarPart from "./AdminModalCarPart";
import AdminModalDeleteCarShop from "./AdminModalDeleteCarShop";
import {convertDateFormat, formatNumberWithCommas, isValidYear, objectSize} from "../../../utility/Utility";
import Select from "react-select";
import AdminModalProfileAvto from "./AdminModalProfileAvto";

const AdminTableCarsPage = (props) => {

    const [focusCarID, setFocusCarID] = useState("f");
    const [copyListCar, setCopyListCar] = useState(props.shopCars);
    const [pMarka, setPMarka] = useState("");
    const [pModel, setPModel] = useState("");
    const [pYearCreate, setPYearCreate] = useState("");
    const [pDateBy, setPDateBy] = useState("");

    useEffect(() => {
        if (objectSize(props.shopCars)){
            setCopyListCar(props.shopCars);
            setFocusCarID("f");

            const filteredData = props.shopCars.filter(item => {
                const markaFilter = pMarka !== "" ? item.id_marka === pMarka : true;
                const modelFilter = pModel !== "" ? item.model.includes(pModel) : true;
                const dateCreateFilter = pYearCreate !== "" ? parseInt(item.date_create) === parseInt(pYearCreate) : true;
                const dateBy = pDateBy !== "" ? item.date_add.includes(pDateBy) : true;

                // Возвращаем true только если все активные фильтры соответствуют элементу
                return markaFilter && modelFilter && dateCreateFilter && dateBy;
            });
            setCopyListCar(filteredData);
        }
    }, [props.shopCars, pMarka, pModel, pYearCreate, pDateBy]);

    return (
        <div>
            {
                (!props.notCreateCar)
                    ? <div>
                        <button className="btn btn-sm btn-primary" modal-open="true" modal-id="AddNewCarShop">+ Добавить автомобиль</button>
                        <hr />
                    </div>
                    : false
            }

            <div className="row mb-3">
                <div className="col-md-2">
                    <Select
                        options={props.mainData.sp_cars}
                        placeholder="Марка"
                        isSearchable="true"
                        onChange={(event) => {
                            setPMarka(event.value);
                        }}
                    />
                </div>
                <div className="col-md-2">
                    <input type="text" className="form-control" onChange={(event) => {
                        setPModel(event.target.value);
                    }} name="model" placeholder="Модель" required />
                </div>
                <div className="col-md-2">
                    <input type="text" className="form-control" placeholder="Год выпуска" required onChange={(event) => {
                        if (isValidYear(event.target.value))
                            setPYearCreate(event.target.value);
                    }} />
                </div>
                <div className="col-md-2">
                    <input type="text" className="form-control" placeholder="Год покупки" required onChange={(event) => {
                        if (event.target.value !== "")
                            setPDateBy(event.target.value);
                    }} />
                </div>
                <div className="col-md-2">
                    <button className="btn btn-danger" style={{marginLeft: 5}} onClick={(event) => {
                        setPMarka("");
                        setPModel("");
                        setPYearCreate("");
                        setPDateBy("");
                        setCopyListCar(props.shopCars);
                        setFocusCarID("f");
                    }}>Очистить фильтры</button>
                </div>
            </div>
            <table className="">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Автомобиль</th>
                    <th scope="col">Vin номер</th>
                    <th scope="col">Гос. номер</th>
                    <th scope="col">Состояния</th>
                    <th scope="col">Цена авто.</th>
                    <th scope="col">Стоимость доставки</th>
                    <th scope="col">Цена за информ.</th>
                    <th scope="col">Цена всех запчастей</th>
                    <th scope="col">Дата покупки</th>
                    <th scope="col">Действия</th>
                </tr>
                </thead>
                <tbody>
                {
                    (objectSize(copyListCar))
                        ? Object.keys(copyListCar).map((index, i) => {
                            return <tr key={i+1}>
                                <th scope="row">{i+1}</th>
                                <td className="text-decoration-underline" style={{cursor: "pointer"}} modal-open="true" modal-id="viewPartsCar" onClick={(event) => setFocusCarID(i)}>{copyListCar[index].marka['name']}, {copyListCar[index].model}, {copyListCar[index].date_create} / {copyListCar[index].dop_parametr}</td>
                                <td className="text-decoration-underline" style={{cursor: "pointer"}} modal-open="true" modal-id="profileCarModal" onClick={(event) => setFocusCarID(i)}>{copyListCar[index].vinnomer}</td>
                                <td>{copyListCar[index].gosnomer}</td>
                                <td>{copyListCar[index].status}</td>
                                <td>{formatNumberWithCommas(copyListCar[index].price)}</td>
                                <td>{formatNumberWithCommas(copyListCar[index].delivery_price)}</td>
                                <td>{formatNumberWithCommas(copyListCar[index].information_price)}</td>
                                <td>{formatNumberWithCommas(copyListCar[index].summ_all_parts)}</td>
                                <td>{convertDateFormat(copyListCar[index].date_add)}</td>
                                <td>
                                    {
                                        (!props.notCreateCar)
                                            ? <div className="dropdown dropstart">
                                                <button className="btn btn-дшпре dropdown-toggle" type="button"
                                                        id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                    Действия
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <button className="dropdown-item" modal-open="true"
                                                            modal-id="EditCarShop" onClick={(event) => {
                                                        Object.keys(props.shopCars).map((indexz, iz) => {
                                                            if (props.shopCars[indexz].id === copyListCar[index].id)
                                                                setFocusCarID(iz);
                                                        });
                                                    }}>Редактировать
                                                    </button>
                                                    <button className="dropdown-item" modal-open="true"
                                                            modal-id="deleteCarShop" onClick={(event) => {
                                                        Object.keys(props.shopCars).map((indexz, iz) => {
                                                            if (props.shopCars[indexz].id === copyListCar[index].id)
                                                                setFocusCarID(iz);
                                                        });
                                                    }
                                                    }>Удалить
                                                    </button>
                                                </div>
                                            </div>
                                            : false
                                    }

                                </td>
                            </tr>
                        })
                        : false
                }
                </tbody>
            </table>

                <AdminModalEditCar setShopCars={props.setShopCars} editDataCar={copyListCar[focusCarID]} sp_cars={props.mainData.sp_cars} id_shop={props.shopData.id} />
                <AdminModalCarPart sp_shops={props.mainData.sp_shops} sp_cars={props.mainData.sp_cars} sp_parts={props.mainData.sp_parts} carData={copyListCar[focusCarID]} id_shop={props.shopData.id} />
                <AdminModalDeleteCarShop setShopCars={props.setShopCars} id_shop={props.shopData.id} editDataCar={copyListCar[focusCarID]} />
                <AdminModalProfileAvto editDataCar={copyListCar[focusCarID]} />
            </div>
    );
};

export default AdminTableCarsPage;