import React from 'react';
import Sidebar from "../components/SideBar/Sidebar";
import {useResize} from "../utility/ResizeHook";
import NavBar from "../components/NavBar/NavBar";
import LoadingIcon from "../components/LoadingIcon/LoadingIcon";
import ModalSendMessage from "../components/ModalSendMessage/ModalSendMessage";


const AccountTemplatePage = (props) => {

    let isScreenLg = useResize().isScreenLg;

    return (
        <div>
            {
                props.mainData
                    ? !isScreenLg
                        ? <div>
                            <div>
                                <NavBar receive={props.mainData['receive']} role={props.mainData['role']} />
                            </div>
                            <div className="p-2 vh-100 bg-light">
                                {
                                    props.content
                                }
                            </div>
                            <ModalSendMessage role={props.mainData.role} />
                        </div>
                        : <div className="d-flex flex-row">
                            <div className="bd-highlight">
                                <Sidebar receive={props.mainData['receive']} role={props.mainData['role']} />
                            </div>
                            <div className={"vh-100 bg-light w-100 " + (!props.dontSetPaddingTitle ? "p-3" : "")} style={{overflowX: "hidden"}}>
                                {
                                    props.content
                                }
                            </div>
                            <ModalSendMessage role={props.mainData.role} />
                        </div>
                    : <LoadingIcon />
            }
        </div>
    );
};

export default AccountTemplatePage;
