import React, {useState} from 'react';
import sendForm from "../../../../utility/SendForm";
import {toast} from "react-toastify";
import parts_form_file from '../../../../other/parts_form.csv';
import {copyTextToClipboard, objectSize} from "../../../../utility/Utility";
import Modal2 from "../../../../components/Modal/Modal2";
import Select from "react-select";

const ModalAddNewPart = (props) => {
    const [sendAddNewPart, setSendAddNewPart] = useState(false);
    const [markaAvto, setMarkaAvto] = useState("");
    const [typePart, setTypePart] = useState("");
    const [statusPart, setStatusPart] = useState("");

    const addNewPart = (event) => {

        event.preventDefault();

        let files = document.getElementById('file_parts_paket_add_part_' + ((props.id_modal) ? props.id_modal : "")).files;

        if (!sendAddNewPart && objectSize(files)){

            setSendAddNewPart(true);
            sendForm(event, (data) => {
                if (data['success']){
                    if (data['parts']['success'])
                        props.setCarParts(data['parts']['parts']);
                    toast.success(data['success']);
                }
                setSendAddNewPart(false);
            }, true, false, true, files, () => {
                setSendAddNewPart(false);
            });
        }
    }


    return (
        <Modal2 title="Пакетное добавление запчастей" id={(props.id_modal) ? props.id_modal : "addNewPartFromFileModal"} style={{minHeight: 600}}>
            <form onSubmit={(event) => addNewPart(event)} action="addPacketAddPartCar" id={(props.id_form) ? props.id_form : "addPacketAddPartCarForm"} method="POST"
                  className="form needs-validation" noValidate>

                <p>
                    <ul className="list-unstyled">
                        <li>1. <a href={parts_form_file}>Скачайте файл</a></li>
                        <li>2. Заполните его</li>
                        <li>3. Загрузите в форму</li>
                    </ul>
                </p>

                <input type="text" className="form-control d-none" name="id_shop" defaultValue={props.id_shop} />
                <input type="text" className="form-control d-none" name="id_car" defaultValue={props.id_car} />

                <div className="form-group mt-2">
                    <input type="file" accept=".csv" id={"file_parts_paket_add_part_" + ((props.id_modal) ? props.id_modal : "")} name="partFile" />
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Добавить запчасть" className="btn btn-success mt-3"/>
            </form>
            <hr />
            <div className="mt-2" key="367473647">
                <Select
                    options={props.sp_cars}
                    placeholder="Марка автомобиля"
                    isSearchable="true"
                    onChange={(event) => setMarkaAvto(event.label)}
                />
                <b>Значение: <button id="textMarkaAvto" className="btn btn-sm btn-primary mt-2" onClick={(event) => copyTextToClipboard("textMarkaAvto")}>{markaAvto}</button></b>
            </div>
            <div className="mt-2" key="36747364337">
                <Select
                    options={props.sp_parts}
                    placeholder="Тип запчасти"
                    isSearchable={true}
                    onChange={(event) => setTypePart(event.label)}
                />
                <b>Значение: <button id="typePart" className="btn btn-sm btn-primary mt-2" onClick={(event) => copyTextToClipboard("typePart")}>{typePart}</button></b>
            </div>
            <div className="mt-2" key="367473643337">
                <Select
                    options={[ { value: '1', label: 'Б/У' },
                        { value: '2', label: 'Новая' },]}
                    placeholder="Состояние запчасти"
                    isSearchable={true}
                    onChange={(event) => setStatusPart(event.label)}
                />
                <b>Значение: <button id="statusPart" className="btn btn-sm btn-primary mt-2" onClick={(event) => copyTextToClipboard("statusPart")}>{statusPart}</button></b>
            </div>
        </Modal2>
    );
};

export default ModalAddNewPart;
