import React, {useState} from 'react';

const PasswordInput = (props) => {
    const [password, setPassword] = useState("");
    const [seePassword, setSeePassword] = useState(false);

    const setSeeStatus = () => {
        if (seePassword)
            setSeePassword(false);
        else
            setSeePassword(true);
    }

    return (
        <div className="input-group">
            <input value={password} onChange={(event) => setPassword(event.target.value)} type="text" className={(seePassword) ? "form-control d-block" : "form-control d-none"} placeholder={props.placeholder} defaultValue={props.defaultValue} required />
            <input value={password} onChange={(event) => setPassword(event.target.value)} name={props.name} type="password" id={props.id} className={(!seePassword) ? "form-control d-block" : "form-control d-none"} defaultValue={props.defaultValue} placeholder={props.placeholder} />
            <div className="input-group-prepend">
                <div className="input-group-text" onClick={setSeeStatus}><i className="bi bi-eye-fill"></i></div>
            </div>
            <div className="valid-feedback">
                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
            </div>
            <div className="invalid-feedback">
                Поле обязательно к заполнению
            </div>
        </div>
    );
};

export default PasswordInput;
