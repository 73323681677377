import React, {useEffect, useState} from 'react';
import Modal2 from "../../../components/Modal/Modal2";
import sendForm from "../../../utility/SendForm";
import {toast} from "react-toastify";
import {closeModalWindow, objectSize} from "../../../utility/Utility";
import ReactQuill from "react-quill";
import $ from 'jquery'
import apiRequest from "../../../utility/ApiRequest";

const AdminModalTreningEditPost = (props) => {
    const [value, setValue] = useState('');
    const [dataPost, setDataPost] = useState(false);

    let modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean']
        ],
    }

    useEffect(() => {
        if (objectSize(props.postData)){
            setDataPost(props.postData);
            setValue(props.postData['text_lesson']);

            if (props.postData['role'].includes("manager"))
                $("#type_manager").prop("checked", true);
            else
                $("#type_manager").prop("checked", false);

            if (props.postData['role'].includes("salesman"))
                $("#type_salesman").prop("checked", true);
            else
                $("#type_salesman").prop("checked", false);

            if (props.postData['role'].includes("disassembler"))
                $("#type_disassembler").prop("checked", true);
            else
                $("#type_disassembler").prop("checked", false);
        }
    }, [props.postData]);

    return (
        (objectSize(props.postData))
            ? <Modal2 size="modal-medium2" id="EditPost" title="Редактирование статьи">
                <form onSubmit={(event) => sendForm(event, (data) => {
                    if (data['success']) {
                        toast.success(data['success'])
                        setValue("");
                        closeModalWindow("EditPost");
                        if(data['posts']['success'])
                            props.setPosts(data['posts']['posts']);
                    }
                }, true, true)} action="editPost" id="formEditPost" method="POST"
                      className="needs-validation" noValidate>
                    <div>
                        <input type="text" className="d-none" value={dataPost['id']} name="post_id" />
                        <label htmlFor="inputPassword" className="col-form-label">Название</label>
                        <div className="form-group mt-1">
                            <div className="input-group">
                                <input type="text" className="form-control w-100" name="name"
                                       placeholder="Название" defaultValue={dataPost['name']} required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="inputPassword" className="col-form-label">Содержание</label>
                        <div className="form-group mt-1">
                            <div className="input-group">
                                <ReactQuill modules={modules} theme="snow" value={value} defaultValue="" onChange={setValue} className="w-100" style={{minHeight: 500}}/>
                                <textarea className="d-none" rows="10" value={value} defaultValue="" name="content" required></textarea>
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="mt-4">
                        <label htmlFor="inputPassword" className="col-form-label">Для кого статья ?</label>
                        <div className="form-group mt-1">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="type_manager" name="type_manager" value="1" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Управляющий
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="type_salesman" name="type_salesman" value="1" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Продавец
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="type_disassembler" name="type_disassembler" value="1" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Разборщик
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="alert alert-danger d-none mt-3">

                    </div>
                    <div className="alert alert-success mt-3 d-none">

                    </div>
                    <button type="submit" className="btn btn-sm btn-success w-100 mt-2">Редактировать</button>
                </form>
            </Modal2>
            : false
    );
};

export default AdminModalTreningEditPost;
