import React from 'react';
import {convertDateFormat, formatNumberWithCommas, objectSize} from "../../../utility/Utility";
import Modal2 from "../../../components/Modal/Modal2";
import {Link} from "react-router-dom";
import not_img from "../../../images/notimage.png";

const AdminjModalProfileAvto = (props) => {
    return (
        <Modal2 title="Карточка автомобиля" id="profileCarModal">
            {
                (objectSize(props.editDataCar))
                    ? <div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <Link className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile_car_photo_car">Фото автомобиля</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#profile_car_photo_document">Фото документов</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#profile_car_info">Информация</Link>
                            </li>
                        </ul>

                        <div className="tab-content p-2">
                            <div id="profile_car_photo_car" className="tab-pane active">
                                {
                                    (objectSize(props.editDataCar.images))
                                        ? <div id="carouselExampleControls2" className="carousel slide" data-ride="carousel">
                                            <div className="carousel-inner">
                                                {
                                                    props.editDataCar.images.map((image, key) => {
                                                        if (key === 0)
                                                            return (
                                                                <div className="carousel-item active" key={key}>
                                                                    <img className="d-block w-100" src={image} alt="" />
                                                                </div>
                                                            )
                                                        else
                                                            return (
                                                                <div className="carousel-item" key={key}>
                                                                    <img className="d-block w-100" src={image} alt="" />
                                                                </div>
                                                            )
                                                    })
                                                }
                                            </div>
                                            <a className="carousel-control-prev" href="#carouselExampleControls2" role="button"
                                               data-bs-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Назад</span>
                                            </a>
                                            <a className="carousel-control-next" href="#carouselExampleControls2" role="button"
                                               data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Вперед</span>
                                            </a>
                                        </div>
                                        : <div className="d-flex justify-content-center align-items-center">
                                            <img className="d-block" style={{width: 500}} src={not_img} alt="" />
                                        </div>
                                }
                            </div>
                            <div id="profile_car_photo_document" className="tab-pane">
                                {
                                    (objectSize(props.editDataCar.images_doc))
                                        ? <div id="carouselExampleControls2" className="carousel slide" data-ride="carousel">
                                            <div className="carousel-inner">
                                                {
                                                    props.editDataCar.images_doc.map((image, key) => {
                                                        if (key === 0)
                                                            return (
                                                                <div className="carousel-item active" key={key}>
                                                                    <img className="d-block w-100" src={image} alt="" />
                                                                </div>
                                                            )
                                                        else
                                                            return (
                                                                <div className="carousel-item" key={key}>
                                                                    <img className="d-block w-100" src={image} alt="" />
                                                                </div>
                                                            )
                                                    })
                                                }
                                            </div>
                                            <a className="carousel-control-prev" href="#carouselExampleControls2" role="button"
                                               data-bs-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Назад</span>
                                            </a>
                                            <a className="carousel-control-next" href="#carouselExampleControls2" role="button"
                                               data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Вперед</span>
                                            </a>
                                        </div>
                                        : <div className="d-flex justify-content-center align-items-center">
                                            <img className="d-block" style={{width: 500}} src={not_img} alt="" />
                                        </div>
                                }
                            </div>
                            <div id="profile_car_info" className="tab-pane">
                                <ul className="list-group">
                                    <h5 className="mb-2 mt-2">Общие данные</h5>
                                    <li className="list-group-item">
                                        <strong>Марка</strong> {props.editDataCar.marka.name}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Модель</strong> {props.editDataCar.model}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Дополнительные параметры</strong> {props.editDataCar.dop_parametr}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Модель/Номер двигателя</strong> {props.editDataCar.dvigatel_number}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>VIN номер</strong> {props.editDataCar.vinnomer}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Гос. номер</strong> {props.editDataCar.gosnomer}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Год выпуска</strong> {props.editDataCar.date_create}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Цена покупки</strong> {formatNumberWithCommas(props.editDataCar.price)}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Цена всех запчастей</strong> {formatNumberWithCommas(props.editDataCar.summ_all_parts)}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Цена доставки</strong> {formatNumberWithCommas(props.editDataCar.delivery_price)}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Цена за информацию</strong> {formatNumberWithCommas(props.editDataCar.information_price)}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Дата покупки</strong> {convertDateFormat(props.editDataCar.date_add)}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Состояние</strong> {props.editDataCar.status}
                                    </li>
                                    <li className="list-group-item">
                                        <strong>Комментарий</strong> {props.editDataCar.description}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    : <div className={"alert alert-danger"}>Извините, данные автомобиля не найдены.</div>
            }
        </Modal2>
    );
};

export default AdminjModalProfileAvto;
