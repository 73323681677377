import React from 'react';

const AccountMainPage = (props) => {
    return (
        <div className="vh-100 w-100 d-flex flex-column justify-content-center align-items-center">
            <div>
                <h1 className="text-success text-center" style={{fontSize: 48}}>
                    {
                        props.role['is_admin']
                            ? <div>Администратор</div>
                            : false
                    }
                    {
                        props.role['is_manager']
                            ? <div>Управляющий</div>
                            : false
                    }
                    {
                        props.role['is_salesman']
                            ? <div>Продавец</div>
                            : false
                    }
                    {
                        props.role['is_disassembler']
                            ? <div>Разборщик</div>
                            : false
                    }
                </h1>
            </div>
            <div>
                <b>Пожалйуста, выберите нужный раздел.</b>
            </div>
        </div>
    );
};

export default AccountMainPage;
