import React, {useState} from 'react';
import sendForm from "../../../utility/SendForm";
import PhoneInput from "../../../components/PhoneInput/PhoneInput";
import Modal2 from "../../../components/Modal/Modal2";
import {formatNumberWithCommas, objectSize} from "../../../utility/Utility";
import {toast} from "react-toastify";
import apiRequest from "../../../utility/ApiRequest";
import $ from "jquery";

const SallesModalGetBooking = (props) => {

    const [bokkings, setBookings] = useState([]);

    const deleteBookingPartServer = (id_shop, id_car, id_part, id_booking, id_elem) => {
        apiRequest("deleteBookingPart?", "id_shop=" + id_shop + "&id_car=" + id_car + "&id_part=" + id_part + "&id_booking=" + id_booking, "POST").then((data) => {
            setBookings([]);
            if (data['success']){
                setBookings(bokkings.filter((element, index) => element['id'] !== id_booking));

                $("#product-number").val(bokkings[id_elem].part.barcode);
                $("#cashregisterform input[type='submit']").trigger("click");

            } else {
                toast.error(data['error']);
            }
        });
    }

    return (
        <Modal2 size="modal-large2" title="Выдача брони" id="modalgetbooking" key="modalgetbooking">
            <form onSubmit={(event) => sendForm(event, (data) => {
                if (data['success']){
                    setBookings(data['bookings']);
                } else if (data['error']){
                    toast.error(data['error']);
                }
            }, true, true)} action="GetBookingKassa" id="GetBookingKassa" method="POST"
                  className="needs-validation" noValidate>

                <input type="text" className="form-control d-none" name="id_shop" value={props.id_shops} />

                <div className="form-group row mt-3">
                    <div className="col-12">
                        <div className="input-group mb-2">
                            <PhoneInput name="phone" id="phoneInputGetBooking" />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Найти бронь" className="btn btn-success mt-3"/>
            </form>

            {
                (objectSize(bokkings))
                    ? <div className="mt-2">
                        <h5>Забронированные запчасти</h5>
                        <table>
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Магазин</th>
                                <th scope="col">ФИО</th>
                                <th scope="col">Номер Телефона</th>
                                <th scope="col">Наименование запчасти</th>
                                <th scope="col">Количество</th>
                                <th scope="col">Цена реализации</th>
                                <th scope="col">Предоплата</th>
                                <th scope="col">Дата</th>
                                <th scope="col">Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(bokkings).map((indexb, b) => {
                                    return <tr key={b}>
                                        <th scope="row">{b+1}</th>
                                        <td>{bokkings[indexb].shop_data.name}</td>
                                        <td>{bokkings[indexb].fio}</td>
                                        <td>{bokkings[indexb].phone}</td>
                                        <td>{bokkings[indexb].part.val_type_part}, {bokkings[indexb].part.marka_name} {bokkings[indexb].part.model}</td>
                                        <td>{bokkings[indexb].count} шт.</td>
                                        <td>{formatNumberWithCommas(bokkings[indexb].part.price_by)}</td>
                                        <td>{formatNumberWithCommas(bokkings[indexb].predoplata)}</td>
                                        <td>{bokkings[indexb].date}</td>
                                        <td>
                                            {
                                                bokkings[indexb].part.id_shop === props.id_shops
                                                    ? <button className="dropdown-item" onClick={(event) => deleteBookingPartServer(bokkings[indexb].part.id_shop, bokkings[indexb].part.id_car, bokkings[indexb].part.id, bokkings[indexb].id, indexb)}>Оформить выдачу</button>
                                                    : false
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    : false
            }

        </Modal2>
    );
};

export default SallesModalGetBooking;