import React, {useEffect, useState} from 'react';
import $ from 'jquery';
import sendForm from "../../utility/SendForm";
import {CallPrint, currentDate, formatNumberWithCommas, isEmpty} from "../../utility/Utility";
import apiRequest from "../../utility/ApiRequest";
import {toast} from "react-toastify";
import SallesModalGetBooking from "./Modals/SallesModalGetBooking";
import SallesModalSetBooking from "./Modals/SallesModalSetBooking";
import SallesReturnPart from "./Modals/SallesReturnPart";
import Select from "react-select";
import {Link} from "react-router-dom";
import PartCard from "../../components/PartCard/PartCard";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";

const SalesmanBoxoffice = (props) => {

    const [partWitchReceipt, setPartWitchReceipt] = useState([]);
    const [partWitchReceiptInfo, setPartWitchReceiptInfo] = useState([]);
    const [partSearchResult, setPartSearchResult] = useState([]);
    const [summReceipt, setSummReceipt] = useState(0);
    const [allCountPartReceipt, setAllCountPartReceipt] = useState(0);
    const [discountSumm, setDiscountSumm] = useState(0);
    const [startSendBy, setStartSendBy] = useState(false);
    const [adsAvtoMarka, setAdsAvtoMarka] = useState("");
    const [searchPartType, setSearchPartType] = useState("");
    const [dateCreateSales, setDateCreateSales] = useState("");
    const [currentPartCardId, setCurrentPartCardId] = useState(false);
    const [currentPartCardIdInCassa, setCurrentPartCardIdInCassa] = useState(false);
    const [searchParamModel, setSearchParamModel] = useState("");
    const [searchParamStatus, setSearchParamStatus] = useState("");
    const [searchParamBarcode, setSearchParamBarcode] = useState("");
    const [searchParamAllShop, setSearchParamAllShop] = useState("");
    const [loadParts, setLoadParts] = useState(false);

    useEffect(() => {

        $("#product-number").focus();

        generalizedData();

    }, [partWitchReceipt]);

    const addPartKorzina = (data) => {
        if (data['success']) {
            if (!startSendBy) {
                if (!partWitchReceipt.filter((element, index) => element['id'] === data['data']['id']).length){
                    setPartWitchReceipt([...partWitchReceipt, data['data']]);
                    // Фильтрация элементов объекта1 на основе id из объекта2
                    let filteredObject1 = partSearchResult.filter(item1 => item1.id !== data['data'].id);
                    setPartSearchResult(filteredObject1);
                }
            }
        }
    }

    const deletePartFromReceipt = (id) => {
        let data_delete_part = partWitchReceipt.filter((element, index) => element['id'] === id)[0];
        setPartWitchReceipt(partWitchReceipt.filter((element, index) => element['id'] !== id));
        setPartSearchResult([...partSearchResult, data_delete_part]);
    }

    const default_saless_message = () => {
        $(".alert").each(() => {
           $(this).empty();
           if (!$(this).hasClass("d-none"))
               $(this).addClass("d-none");
        });
    }

    const setMessageSeles = (message, type) => {
        default_saless_message();

        if (type === "success"){
            $("#sales_message_success").empty();
            $("#sales_message_success").text(message);
            $("#sales_message_success").removeClass("d-none");
        } else {
            $("#sales_message_error").empty();
            $("#sales_message_error").text(message);
            $("#sales_message_error").removeClass("d-none");
        }
    }

    const generalizedData = () => {
        let allsumm = 0;
        let allcount = 0;

        partWitchReceipt.map((elem, i) => {
            allsumm += parseFloat(elem['price_by']) * parseInt(elem['count']);
            allcount += parseInt(elem['count']);
            return true;
        });

        setAllCountPartReceipt(allcount);

        let discount = $("#input_discont").val();
        if (discount !== "" && partWitchReceipt.length){

            if (discount.includes('%')) {
                const discount_procent = parseFloat(discount.split("%")[0]);
                if (discount_procent > 0 && discount_procent <= 100){
                    let skidka = (allsumm * discount_procent) / 100;
                    allsumm -= skidka;
                    setDiscountSumm(skidka.toFixed(2));
                } else
                    setDiscountSumm(0);
            } else {
                if (parseFloat(discount) > 0 && parseFloat(discount) <= allsumm){
                    setDiscountSumm(parseFloat(discount).toFixed(2));
                    allsumm -= parseFloat(discount);
                } else
                    setDiscountSumm(0);
            }
        } else
            setDiscountSumm(0);

        setSummReceipt(allsumm.toFixed(2));
    }

    const emptyCorzinaForm = () => {
        setPartWitchReceipt([]);
        $(".alert").empty().addClass("d-none");
        $("#serch_part_form_btn").trigger("click");
    }

    const sendNewBy = () => {
        default_saless_message();

        if (partWitchReceipt.length) {
            if ($(".border-red").length <= 0) {
                setStartSendBy(true);

                // Преобразование массива объектов в массив текстовых строк
                const textArray = partWitchReceipt.map(item => `id:${item.id},count:${item.count},barcode:${item.barcode}`);

                // Объединение строк в один текст
                const resultText = textArray.join('\n');
                const date_sales = $("#date_create_sales").val();
                const discont = $("#input_discont").val();
                const type_payment = $("#type_payment").val();
                const summ_nalich = $("#summ_nalich").val();
                const summ_beznalich = $("#summ_beznalich").val();

                let data_send = "data=" + resultText + "&date=" + date_sales;
                data_send += "&discont=" + discont;
                data_send += "&type_payment=" + type_payment;
                data_send += "&summ_nalich=" + summ_nalich;
                data_send += "&summ_beznalich=" + summ_beznalich;

                apiRequest("newByPart?", data_send, "POST").then((data) => {
                    setStartSendBy(false);

                    if (data['success']){
                        $("#receipt_number").empty().text(data['receipt_number']);
                        setPartWitchReceiptInfo(partWitchReceipt);
                        emptyCorzinaForm();
                        toast.success(data['success']);
                    }

                    if (data['error'])
                        setMessageSeles(data['error'], "error");
                });
            } else
                toast.error("Извините, проверьте правильность заполнения корзины.");
        } else
            toast.error("Извините, корзина пуста.");

    }

    useEffect (() => {
        $("#serch_part_form_btn").trigger("click");
        setLoadParts(true);
    }, [adsAvtoMarka, searchPartType, searchParamModel, searchParamStatus, searchParamBarcode, searchParamAllShop]);

    return (
        <div>
            <div className="row sticky-top p-3 bg-light">
                <div className="col">
                    <h2>Касса</h2>
                </div>
                <div className="col d-flex justify-content-end">
                    <button type="button" style={{marginRight: 10}} className="btn btn-primary" modal-open="true" modal-id="modalsetbooking">Оформить бронь</button>
                    <button type="button" style={{marginRight: 10}} className="btn btn-primary" modal-open="true" modal-id="modalgetbooking">Выдать бронь</button>
                    <button type="button" style={{marginRight: 10}} className="btn btn-primary" modal-open="true" modal-id="ReturnPart">Оформить возврат</button>
                    <SallesModalSetBooking id_shops={props.mainData.id_shops} />
                    <SallesModalGetBooking id_shops={props.mainData.id_shops} />
                    <SallesReturnPart id_shops={props.mainData.id_shops} />
                </div>
                <div className="p-3"></div>
                <ul className="nav nav-tabs w-100" id="kassaTb" role="tablist">
                    <li className="nav-item flex-fill text-center">
                        <Link className="nav-link active" id="s1-tab" data-bs-toggle="tab" role="tab" data-bs-target="#sales_tab_1s"><b>Поиск запчастей</b></Link>
                    </li>
                    <li className="nav-item flex-fill  text-center">
                        <Link className="nav-link" id="s2-tab" data-bs-toggle="tab" role="tab" data-bs-target="#sales_tab_2s"><b>Корзина (товаров {allCountPartReceipt} ед.)</b></Link>
                    </li>
                    <li className="nav-item flex-fill  text-center">
                        <Link className="nav-link" id="s3-tab" data-bs-toggle="tab" role="tab" data-bs-target="#sales_tab_3s"><b>Чек</b></Link>
                    </li>
                </ul>
            </div>

            <div className="tab-content p-3">
                <div id="sales_tab_1s" role="tabpanel" className="tab-pane active">
                    <div className="bg-secondary-subtle p-2">
                        <h3>Поиск запчастей</h3>
                        <form onSubmit={(event) => sendForm(event, (data)=>{
                            if (data['success']){
                                if (data['parts']){
                                    setPartSearchResult(data['parts']);
                                }
                            }
                            setLoadParts(false);
                        }, false, false)} action="searchPartForReceipt" id="searchPartForReceipt" method="POST">
                            <input type="text" className="d-none" value={props.mainData.id_shops} name="id_shop"/>
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <Select
                                            options={props.mainData.sp_cars}
                                            placeholder="Марка"
                                            isSearchable="true"
                                            onChange={(event) => setAdsAvtoMarka(event.value)}
                                        />
                                        <input name="search_marka" id="search_marka" type="text" defaultValue={adsAvtoMarka} className="d-none" />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="field2"
                                               placeholder="Модель" value={searchParamModel} name="search_model"
                                               onChange={(event) => setSearchParamModel(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <Select
                                            options={props.mainData.sp_parts}
                                            placeholder="Тип запчасти"
                                            isSearchable="true"
                                            onChange={(event) => setSearchPartType(event.value)}
                                        />
                                        <input name="search_type_part" id="search_type_part" type="text" defaultValue={searchPartType} className="d-none" />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <select name="status_part" onChange={(event) => {
                                            if (!isEmpty(event.target.value))
                                                setSearchParamStatus(event.target.value);
                                        }} id="search_part_form_select_shop_status" className="form-select">
                                            <option value="">Состояние</option>
                                            <option value="1">Б/У</option>
                                            <option value="2">Новое</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <input type="text" className="form-control"
                                               placeholder="Номер запчасти" value={searchParamBarcode}
                                               onChange={(event) => setSearchParamBarcode(event.target.value)} name="search_barcode" />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <select name="all_shop" id="search_part_form_select_shop_type" onChange={(event) => setSearchParamAllShop(event.target.value)} className="form-select">
                                        <option value="0">Поиск по всем магазинам</option>
                                        <option value="0">Нет</option>
                                        <option value="1">Да</option>
                                    </select>
                                </div>
                            </div>

                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-3 d-none">

                            </div>
                            <input type="submit" className="btn btn-success w-100 mt-2 d-none" id="serch_part_form_btn" value="Поиск" />
                            <button className="btn btn-danger w-100 mt-2" onClick={(event) => {
                                setSearchParamModel("");
                                setSearchParamStatus("");
                                setSearchParamBarcode("");
                                setSearchParamAllShop("");
                                setAdsAvtoMarka("");
                                setSearchPartType("");
                                setLoadParts(false);
                                $('#search_part_form_select_shop_type').prop('selectedIndex', 0);
                                $('#search_part_form_select_shop_status').prop('selectedIndex', 0);
                            }}>Очистить фильтры</button>
                        </form>
                    </div>
                    {
                        (!loadParts)
                            ? <table className="mt-2" id="search_result_table">
                                <thead>
                                <tr>
                                    <th scope="col">Тип запчасти</th>
                                    <th scope="col">Марка, Модель</th>
                                    <th scope="col">Состояние</th>
                                    <th scope="col">Количество</th>
                                    <th scope="col">Цена реализации</th>
                                    <th scope="col">Действия</th>
                                    <th scope="col">Магазин</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    partSearchResult.map((elem, i) => {
                                        return <tr key={i}>
                                            <td className="text-decoration-underline" style={{cursor: "pointer"}} modal-open="true" modal-id="partCard1" onClick={(event) => setCurrentPartCardId(i)}>
                                                {elem.val_type_part}
                                                {
                                                    (elem.dop_param_parts)
                                                        ? " / " + elem.dop_param_parts
                                                        : false
                                                }
                                            </td>
                                            <td>{elem.marka_name}, {elem.model}</td>
                                            <td>{
                                                (elem.status_part === 1)
                                                    ? "Б/У"
                                                    : "Новое"
                                            }</td>
                                            <td>{elem.count} {elem.edinica_type}</td>
                                            <td>{formatNumberWithCommas(elem.price_by)}</td>
                                            <td className="text-center">
                                                <button className="btn btn-sm btn-success" onClick={(event) => {
                                                    $("#product-number").val(elem.barcode);
                                                    $("#cashregisterform input[type='submit']").trigger("click");
                                                }}>Добавить в карзину</button>
                                            </td>
                                            <td>{elem.shopInfo.name}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                            : <LoadingIcon />
                    }
                    {
                        (currentPartCardId !== false)
                            ? <PartCard dataPart={partSearchResult[currentPartCardId]} id="partCard1" />
                            : false
                    }

                </div>
                <div id="sales_tab_2s" role="tabpanel" className="tab-pane">

                    <div className="">
                        <div id="checkout-table">
                            <h3>Корзина</h3>
                            <table style={{maxHeight: 500, overflowY: "auto"}}>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Номер запчасти</th>
                                    <th>Наименование запчасти</th>
                                    <th>Цена реализации</th>
                                    <th>Количество</th>
                                    <th>Общая цена, руб.</th>
                                    <th>Действия</th>
                                </tr>
                                </thead>
                                <tbody id="cart-items">
                                {
                                    (partWitchReceipt.length)
                                        ? partWitchReceipt.map((elem, i) => {

                                            let raznicaCount = parseInt(elem['count_sclad']) - parseInt(elem['count']);

                                            return <tr key={i} className={(raznicaCount < 0) ? "border-red" : ""}>
                                                <td>{i+1}</td>
                                                <td>{elem['barcode']}</td>
                                                <td className="text-decoration-underline" style={{cursor: "pointer"}} modal-open="true" modal-id="partCard2" onClick={(event) => setCurrentPartCardIdInCassa(i)}>
                                                    {elem.val_type_part}
                                                    {
                                                        (elem.dop_param_parts)
                                                            ? " / " + elem.dop_param_parts
                                                            : false
                                                    }
                                                </td>
                                                <td>{formatNumberWithCommas(elem['price_by'])}</td>
                                                <td>{elem['count']} {elem.edinica_type}</td>
                                                <td>{formatNumberWithCommas(elem['price_summ'])}</td>
                                                <td>
                                                    <button className="dropdown-item" onClick={(event) => deletePartFromReceipt(elem['id'])}>Удалить</button>
                                                </td>
                                            </tr>
                                        })
                                        : false
                                }
                                </tbody>
                            </table>

                            {
                                (currentPartCardIdInCassa !== false)
                                    ? <PartCard dataPart={partWitchReceipt[currentPartCardIdInCassa]} id="partCard2" />
                                    : false
                            }

                            <div className="bg-secondary-subtle p-2" key="receiptDataInfo">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Тип оплаты</label>
                                            <div className="col-sm-10">
                                                <select onChange={(event) => {
                                                    if (event.target.value === "3")
                                                        $("#detail_type_pay").removeClass("d-none");
                                                    else
                                                        $("#detail_type_pay").addClass("d-none");
                                                }} name="type_payment" id="type_payment" className="form-select">
                                                    <option value="1">Наличный</option>
                                                    <option value="2">Безналичный</option>
                                                    <option value="3">Комбинированный</option>
                                                </select>
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                        </div>

                                        <div id="detail_type_pay" className="d-none">
                                            <div className="form-group row mt-3">
                                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Наличным способом</label>
                                                <div className="col-sm-10">
                                                    <input type="number" className="form-control" placeholder="Введите сумму оплаты наличным способом" name="summ_nalich" id="summ_nalich" />
                                                    <div className="valid-feedback">
                                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        Поле обязательно к заполнению
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row mt-3">
                                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Безналичным способом</label>
                                                <div className="col-sm-10">
                                                    <input type="number" className="form-control" placeholder="Введите сумму оплаты безналичным способом" name="summ_beznalich" id="summ_beznalich" />
                                                    <div className="valid-feedback">
                                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        Поле обязательно к заполнению
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row mt-3">
                                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Скидка</label>
                                            <div className="col-sm-10">
                                                <input type="text" className="form-control" placeholder="Введите сумму или процент скидки" id="input_discont" name="discont"
                                                       onChange={(event) => generalizedData()}/>
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row mt-3">
                                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Дата продажи</label>
                                            <div className="col-sm-10">
                                                <input type="date" className="form-control" placeholder="Выберите дату" onChange={(event) => setDateCreateSales(event.target.value)} />
                                                <input type="text" className="form-control d-none" value={dateCreateSales} name="date_create_sales" id="date_create_sales" placeholder="" />
                                                <div className="valid-feedback">
                                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                </div>
                                                <div className="invalid-feedback">
                                                    Поле обязательно к заполнению
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <button type="button" className="btn btn-success w-100 mt-2" onClick={(event) => sendNewBy()}>Оформить продажу</button>
                                            </div>
                                            <div className="col-md-6">
                                                <button type="button" className="btn btn-danger w-100 mt-2" onClick={(event) => emptyCorzinaForm()}>Очистить корзину</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{fontSize: 20}}>
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <p>Общее количество: <b id="total-quantity">{allCountPartReceipt}</b> ед.</p>
                                            </li>
                                            <li className="list-group-item">
                                                <p>Скидка: <b id="total-cost">{formatNumberWithCommas(discountSumm)}</b> руб.</p>
                                            </li>
                                            <li className="list-group-item">
                                                <p>Общая стоимость: <b id="total-cost">{formatNumberWithCommas(summReceipt)}</b> руб.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div id="sales_message_error" className="mt-3 alert alert-danger d-none"></div>
                            <div id="sales_message_success" className="mt-3 alert alert-success d-none"></div>
                        </div>
                    </div>
                </div>
                <div id="sales_tab_3s" role="tabpanel" className="tab-pane">

                    <div id="receipt-info">
                        <div id="receipt-info" className="mt-2">
                            <button className="btn btn-primary w-100 mt-2" onClick={(event) => CallPrint("receipt")}>Напечатать чек</button>
                            <div className="mt-3" id="receipt">
                                <h3>Чек № <b id="receipt_number"></b></h3>

                                <p><strong>Дата:</strong> {currentDate()}</p>

                                <table className="table-receipt">
                                    <thead>
                                    <tr>
                                        <th>Номер</th>
                                        <th>Наименование</th>
                                        <th>Цена за штуку</th>
                                        <th>Количество</th>
                                        <th>Итого</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        (partWitchReceiptInfo.length)
                                            ? partWitchReceiptInfo.map((elem, i) => {
                                                return <tr key={i}>
                                                    <td>{elem['barcode']}</td>
                                                    <td>{elem['name']}</td>
                                                    <td>{formatNumberWithCommas(elem['price_by'])}</td>
                                                    <td>{elem['count']} {elem.edinica_type}</td>
                                                    <td>{formatNumberWithCommas(elem['price_summ'])}</td>
                                                </tr>
                                            })
                                            : false
                                    }
                                    </tbody>
                                </table>


                                <p className="total">Общее количество: <b id="total-quantity">{Object.keys(partWitchReceiptInfo).length}</b> ед.</p>
                                <p className="total">Скидка: <b id="total-cost">{formatNumberWithCommas(discountSumm)}</b> руб.</p>
                                <p className="total">Итоговая сумма: <b id="total-cost">{formatNumberWithCommas(summReceipt)}</b> руб.</p>


                                <div className="seller-info">
                                    <p><strong>Продавец:</strong> {props.mainData.userData['fio']}, {props.mainData.userData['phone']}</p>
                                </div>

                                <div className="signature">
                                    <p>______________________</p>
                                    <p>Подпись продавца</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row d-none">
                <div className="col-md-5">

                    <div className="receipt mt-2">
                        <h3>Предварительный заказ</h3>
                        <form onSubmit={(event) => sendForm(event, addPartKorzina, true, true)} action="getPartFromBarCode" method="POST" className="needs-validation" noValidate id="cashregisterform">
                            <div className="form-group">
                                <input name="barcode" type="text" className="form-control mt-2" id="product-number" placeholder="Введите номер запчасти"
                                       required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>

                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-3 d-none">

                            </div>
                            <input type="submit" className="btn btn-primary mt-2 w-100" value="Добавить в корзину" />
                        </form>
                    </div>

                </div>

                <div className="col-md-7">




                </div>
            </div>
        </div>
    );
};

export default SalesmanBoxoffice;
