import React, {useEffect, useState} from 'react';
import sendForm, {formDefaultStatusAllFrom} from "../../../../utility/SendForm";
import {toast} from "react-toastify";
import Select from "react-select";
import {closeModalWindow, objectSize} from "../../../../utility/Utility";
import Modal2 from "../../../../components/Modal/Modal2";
import $ from "jquery";
import apiRequest from "../../../../utility/ApiRequest";
import ImgAds from "../../../../components/ImgFormPreview/ImgAds";

let indexLastImage = 0;

const ModalEditPart = (props) => {

    const [typePartID, setTypePartID] = useState();
    const [adsAvtoMarka, setAdsAvtoMarka] = useState();
    const id_modal = (props.id_modal) ? props.id_modal : "editPartModal";
    const [displayImgAds, setdisplayImgAds] = useState([]);
    const [issetImages, setIssetImages] = useState({});
    const [sendEditDataPart, setSendEditDataPart] = useState(false);

    const closeModal = () => {
        props.setFocusPartID(0);
        formDefaultStatusAllFrom();
    }

    useEffect(() => {
        setTypePartID(props.editPartData.type_part_sys['id']);
        setAdsAvtoMarka(props.editPartData.marka_id);
        setdisplayImgAds([]);
        setIssetImages(props.editPartData.images);

        if (props.editPartData.is_remont)
            $("#edit_is_remont_checked" + props.id_modal).prop("checked", true);

        let myModal = document.getElementById(id_modal);
        myModal.addEventListener("hide.bs.modal", closeModal);
        return () => {
            myModal.removeEventListener("hide.bs.modal", closeModal);
        }

    }, [props.editPartData]);

    const addImgAds = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImage++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAds([...displayImgAds, ...arrImg]);
        event.target.value = null;
    }

    const deleteImgAds = (idImg) => {
        setdisplayImgAds(displayImgAds.filter(obj => {
            return obj['index'] !== idImg;
        }));
    }

    const deleteImageServer = (id_img, id_part, id_shop, type) => {
        apiRequest("deletePartImg?", "id_shop=" + id_shop + "&id_part=" + id_part + "&id_img=" + id_img + "&type=" + type).then((data) => {
            if (data['success']){
                setIssetImages(issetImages.filter(i => i.indexOf(id_img) < 0));
            } else
                alert(data['error']);
        });
    }

    const editDataPart = (event) => {

        event.preventDefault();

        if (!sendEditDataPart){
            let files = [];
            for (let i = 0; i < displayImgAds.length; i++)
                files.push(displayImgAds[i]['file']);

            setSendEditDataPart(true);

            sendForm(event, (data) => {
                if (data['success']) {
                    if (data['parts']['success']){
                        props.setCarParts(data['parts']['parts']);
                        console.log("Обновляем данные запчастей")
                    } else
                    if (data['parts']['error'])
                        props.setCarParts(false);

                    toast.success(data['success']);
                    closeModalWindow((props.id_modal) ? props.id_modal : "editPartModal");
                } else {
                    toast.error(data['error']);
                }
                setSendEditDataPart(false);

            }, true, false, true, files, () => {
                setSendEditDataPart(false);
            });
        }
    }

    return (
        <Modal2 title="Редактирование данных запчасти" id={(props.id_modal) ? props.id_modal : "editPartModal"}>
            {
                (objectSize(props.editPartData) && objectSize(props.sp_cars))
                    ? <div>
                    <form onSubmit={(event) => editDataPart(event)} action="editDataPart" id={(props.id_form) ? props.id_form : "editDataPartCar"} method="POST"
                          className="needs-validation" noValidate key={"editDataPartCar" + props.editPartData.id}>
                        <input type="submit" value="Изменить данные" className="btn btn-success mt-3"/>
                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <div className="alert alert-success mt-3 d-none">

                        </div>
                        {
                            (!props.editPartData.id_car)
                                ? <div>
                                    <div className="form-group row mt-3">
                                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Марка автомобиля</label>
                                        <div className="col-sm-10">
                                            <Select
                                                options={props.sp_cars}
                                                placeholder="Марка автомобиля"
                                                isSearchable="true"
                                                onChange={(event) => setAdsAvtoMarka(event.value)}
                                                defaultValue = {
                                                    props.sp_cars.filter(option =>
                                                        option.value === parseInt(props.editPartData.marka_id))
                                                }
                                            />
                                            <input name="marka" type="text" value={adsAvtoMarka} className="d-none" required />
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row mt-3">
                                        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Модель</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" name="model" placeholder="Camry" defaultValue={props.editPartData.model} required />
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : false
                        }

                        <input type="text" className="form-control d-none" name="id_shop" value={props.id_shop} />
                        <input type="text" className="form-control d-none" name="id_car" value={props.id_car} />
                        <input type="text" className="form-control d-none" name="id_part" value={props.editPartData.id} />

                        <div className="form-group row mt-3">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Тип</label>
                            <div className="col-sm-10">
                                <Select
                                    options={props.sp_parts}
                                    placeholder="Тип запчасти"
                                    isSearchable={true}
                                    onChange={(event) => setTypePartID(event.value)}
                                    defaultValue = {
                                        props.sp_parts.filter(option =>
                                            option.value === parseInt(props.editPartData.type_part_sys['id']))
                                    }
                                />
                                <input type="text" className="form-control d-none" name="typePart" placeholder="" value={typePartID} required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                                <input type="text" className="form-control mt-1" name="typePartOther" defaultValue={props.editPartData.dop_param_parts} placeholder="Доп. параметры запчасти" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">Закупочная цена</label>
                                <div className="form-group row mt-1">
                                    <div className="col-auto">
                                        <div className="input-group mb-2">
                                            <input type="number" className="form-control" name="cost_price"
                                                   placeholder="0" defaultValue={props.editPartData.cost_price} required />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">руб.</div>
                                            </div>
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">Стоимость доставки</label>
                                <div className="form-group row mt-1">
                                    <div className="col-auto">
                                        <div className="input-group mb-2">
                                            <input type="number" className="form-control" name="delivery_price"
                                                   placeholder="0" defaultValue={props.editPartData.price_delivery} required />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">руб.</div>
                                            </div>
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">Наценка</label>
                                <div className="form-group row mt-1">
                                    <div className="col-auto">
                                        <div className="input-group mb-2">
                                            <input type="number" className="form-control" name="price"
                                                   placeholder="0" defaultValue={props.editPartData.price} required />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">руб.</div>
                                            </div>
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="inputPassword" className="col-form-label">Количество</label>
                                        <div className="form-group row mt-1">
                                            <div className="w-100">
                                                <div className="input-group">
                                                    <input type="number" className="form-control"
                                                           placeholder="" disabled="true" />
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text p-0">
                                                            <select name="edinica_name" className="form-select" required="true">
                                                                {
                                                                    (props.editPartData.edinica_type_sys === 1)
                                                                        ? <option value="1" selected="true">шт.</option>
                                                                        : <option value="1">шт.</option>
                                                                }
                                                                {
                                                                    (props.editPartData.edinica_type_sys === 2)
                                                                        ? <option value="2" selected="true">пар.</option>
                                                                        : <option value="2">пар.</option>
                                                                }
                                                                {
                                                                    (props.editPartData.edinica_type_sys === 3)
                                                                        ? <option value="3" selected="true">комп.</option>
                                                                        : <option value="3">комп.</option>
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="valid-feedback">
                                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        Поле обязательно к заполнению
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row mt-1">
                                    <label htmlFor="inputPassword" className="col-form-label">Состояние</label>
                                    <div className="col-sm-10">
                                        <div className="input-group mb-2">
                                            <select name="status_part" className="form-select" id="editDataPartStatusChecked" required="true" onChange={(event) => {
                                                if (event.target.value === "3")
                                                    $("#edit_comment_input" + props.id_modal).attr('required', 'true');
                                                else {
                                                    $("#edit_comment_input" + props.id_modal).removeAttr('required');
                                                    $("#edit_comment_input" + props.id_modal).removeClass('is-invalid');
                                                }
                                            }}>
                                                <option value=""></option>
                                                {
                                                    (props.editPartData.status_part === 1)
                                                        ? <option value="1" selected="true">Б/У</option>
                                                        : <option value="1">Б/У</option>
                                                }
                                                {
                                                    (props.editPartData.status_part === 3)
                                                        ? <option value="3" selected="true">Б/У c дефектом</option>
                                                        : <option value="3">Б/У c дефектом</option>
                                                }
                                                {
                                                    (props.editPartData.status_part === 2)
                                                        ? <option value="2" selected="true">Новое</option>
                                                        : <option value="2">Новое</option>
                                                }
                                            </select>
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group row mt-1">
                                    <label htmlFor="inputPassword" className="col-form-label">№ стелажа</label>
                                    <div className="col-auto">
                                        <div className="input-group mb-2">
                                            <input type="number" className="form-control" name="row"
                                                   placeholder="0" defaultValue={props.editPartData.row} required />
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">№ полка</label>
                                <div className="form-group row mt-1">
                                    <div className="col-auto">
                                        <div className="input-group mb-2">
                                            <input type="number" className="form-control" name="shelf"
                                                   placeholder="0" defaultValue={props.editPartData.shelf} required />
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">Место</label>
                                <div className="form-group row mt-1">
                                    <div className="col-auto">
                                        <div className="input-group mb-2">
                                            <input type="number" className="form-control" name="place"
                                                   placeholder="0" required defaultValue={props.editPartData.place} />
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="is_remont" value="1" id={"edit_is_remont_checked" + props.id_modal} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Запчасть находится на ремонте
                            </label>
                        </div>

                        <div className="form-group row mt-3">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Комментарии</label>
                            <div className="col-sm-10">
                            <textarea rows="5" name="comment" id={"edit_comment_input" + props.id_modal} className="form-control" defaultValue={props.editPartData.description}>

                            </textarea>
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>

                        <hr />
                        Фотографии
                        <div className="mt-3 w-100">
                            <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                                {
                                    (objectSize(props.editPartData))
                                        ? (objectSize(issetImages))
                                            ? Object.keys(issetImages).map((image, key) => {
                                                return (
                                                    <ImgAds src={props.editPartData.images[image]} id_shop={props.id_shop} id_part={props.editPartData.id} id_cars={props.id_car} id_img={issetImages[image].split("/")[issetImages[image].split("/").length - 1]} key={key} deleteImageServer={deleteImageServer} deleteImgServer={true} delete_type="img" />
                                                )
                                            })
                                            : false
                                        : false
                                }
                                {
                                    (displayImgAds.length)
                                        ? displayImgAds.map((image, key) => {
                                            return (
                                                <ImgAds src={image['url']} id_img={image.index} deleteImage={deleteImgAds} key={key} />
                                            )
                                        })
                                        : false
                                }
                                <label className="mt-2">
                                    <div className="imagebutton">
                                        <i className="bi bi-camera"></i>
                                    </div>
                                    <input type="file" multiple="multiple" onChange={(event) => addImgAds(event)} hidden />
                                </label>
                            </div>
                        </div>

                        <hr />

                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <div className="alert alert-success mt-3 d-none">

                        </div>
                        <input type="submit" value="Изменить данные" className="btn btn-success mt-3"/>
                    </form>
                    </div>
                : false
            }
        </Modal2>
    );
};
export default ModalEditPart;