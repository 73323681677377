import React, {useState} from 'react';
import Modal2 from "../../../../components/Modal/Modal2";
import sendForm from "../../../../utility/SendForm";
import {toast} from "react-toastify";
import {closeModalWindow, formatNumberWithCommas, objectSize, parseRemontWork} from "../../../../utility/Utility";
import $ from "jquery";

const ModalEvakuatorRemontRabota = (props) => {

    const [datePoezdka, setDatePoezdka] = useState("");
    const [remontWork, setRemontWork] = useState(false);

    return (
        <Modal2 size="modal-medium2" title="Добавление ремонтных работ" id="ModalAddRemontRabot">
            <form onSubmit={(event) => sendForm(event, (data) => {
                if(data['success']) {
                    props.setShopEvakuatorRemontRabot(data['remont_rabot']);
                    toast.success(data['success']);
                    if (data['is_to'])
                        $("#container_to_info").remove();
                    closeModalWindow("ModalAddRemontRabot");
                }
            }, true, false)} action="setRemontRabota" id="FormAddNewRemontRabota" method="POST"
                  className="needs-validation" noValidate>
                <input type="submit" value="Добавить ремонтные работы" className="btn btn-success w-100"/>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>

                <input type="text" className="form-control d-none" name="id_shop" value={props.id_shop} />

                <div className="form-group mt-3">
                    <label htmlFor="inputPassword">Дата ремонтных работ</label>
                    <div className="col-auto">
                        <div className="input-group mb-2">
                            <input type="date" className="form-control w-100" onChange={(event) => setDatePoezdka(event.target.value)} />
                            <input type="text" className="d-none" name="date" value={datePoezdka} />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="is_to" value="1" onChange={(event) => {
                        if (event.target.checked)
                            $("#name_to_input").val("Проведение ТО");
                        else
                            $("#name_to_input").val("");
                    }} />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Проведение ТО
                    </label>
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="inputPassword">Сокращенное описание работ</label>
                    <div className="col-auto">
                        <div className="input-group mb-2">
                            <input type="text" id="name_to_input" className="form-control w-100" name="short_description"
                                   placeholder="Замена тормозных колодок, замена масла" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="inputPassword">Полное описание работ и стоимости</label>
                    <div className="col-auto">
                        <div className="input-group mb-2">
                            <textarea rows="10" className="form-control w-100" name="description"
                                      onChange={(event) => {
                                          setRemontWork(parseRemontWork(event.target.value));
                                      }}
                                      placeholder="Замена масла 3500" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>
                <table className="mt-2">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Выполненная работа</th>
                        <th scope="col">Стоимость</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (objectSize(remontWork))
                            ? remontWork.map((elem, i) => {
                                return <tr>
                                    <td>{i+1}</td>
                                    <td>{elem.type}</td>
                                    <td>{formatNumberWithCommas(elem.sum)}</td>
                                </tr>
                            })
                            : false
                    }
                    </tbody>
                </table>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Добавить ремонтные работы" className="btn btn-success mt-3 w-100"/>
            </form>
        </Modal2>
    );
};

export default ModalEvakuatorRemontRabota;
