import React, {useEffect, useState} from 'react';
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import apiRequest from "../../utility/ApiRequest";
import {Link, useSearchParams} from "react-router-dom";
import appGlobalParam from "../../utility/AppGlobalParam";
import AdminTableUserShop from "./AdminShopsCardPage/AdminTableUserShop";
import AdminTableCarsPage from "./AdminShopsCardPage/AdminTableCarsPage";
import AdminModalAddNewUser from "./AdminShopsCardPage/AdminModalAddNewUser";
import AdminModalAddNewCar from "./AdminShopsCardPage/AdminModalAddNewCar";
import AdminTableAllPartShop from "./AdminShopsCardPage/AdminTableAllPartShop";
import AdminSettingsShopPage from "./AdminShopsCardPage/AdminSettingsShopPage";
import AdminSalseTable from "./AdminShopsCardPage/AdminSalseTable";
import AdminDopFinance from "./AdminShopsCardPage/AdminDopFinance";
import AdminModalEvakuatorPage from "./AdminShopsCardPage/AdminModalEvakuatorPage";
import AdminModalShopStatistic from "./AdminShopsCardPage/AdminModalShopStatistic";

const AdminShopCardPage = (props) => {
    const [shopData, setShopData] = useState(false);
    const [shopUsers, setShopUsers] = useState(false);
    const [shopCars, setShopCars] = useState(false);
    const [shopParts, setShopParts] = useState(false);
    const [shopEvakuatorPerevozki, setShopEvakuatorPerevozki] = useState(false);
    const [shopEvakuatorTO, setShopEvakuatorTO] = useState(false);
    const [shopEvakuatorRemontRabot, setShopEvakuatorRemontRabot] = useState(false);
    const [getDopFinance, setGetDopFinance] = useState(0);
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get("shopId") === null || isNaN(+searchParams.get("shopId")))
            document.location.href = appGlobalParam.accountMainUrl;

        apiRequest("getMainDataShop?", "shop_id=" + searchParams.get("shopId")).then((data) => {
            if (data['success']){
                setShopData(data.data);
                setShopUsers(data.users);
                setShopCars(data.cars);
                setShopParts(data.parts['parts']);
                setShopEvakuatorPerevozki(data.perevozki);
                setShopEvakuatorTO(data.to);
                setShopEvakuatorRemontRabot(data.remont_rabot);
                console.log(data);
            } else
                 if (data['error'])
                    document.location.href = appGlobalParam.accountMainUrl;
        });
    }, [searchParams]);

    const getFinanceOperation = () => {
        apiRequest("getMainDataShop?", "shop_id=" + searchParams.get("shopId")).then((data) => {
            if (data['success']){
                setShopData(data.data);
                setShopUsers(data.users);
                setShopCars(data.cars);
                setShopParts(data.parts['parts']);
                setShopEvakuatorPerevozki(data.perevozki);
                setShopEvakuatorTO(data.to);
                setShopEvakuatorRemontRabot(data.remont_rabot);
                console.log(data);
            } else
            if (data['error'])
                document.location.href = appGlobalParam.accountMainUrl;
        });
    }

    return (
        shopData
            ? <div key="conteiner_shop_all_data">
                <div className="d-flex flex-column">
                    <h2><i className="bi bi-layout-sidebar-inset-reverse"> </i>Магазин - {shopData.name}</h2>
                    <p><b>{shopData.description}</b>, {shopData.adress}</p>
                </div>
                <hr />
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link className="nav-link active" data-bs-toggle="tab" data-bs-target="#sclad"><i className="bi bi-server"></i> Склад</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#cars"><i className="bi bi-car-front-fill"></i> Автомобили</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#users"><i className="bi bi-people-fill"></i> Персонал</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" data-bs-toggle="tab" aria-current="page" data-bs-target="#sales"><i className="bi bi-cash"></i> История продаж</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" data-bs-toggle="tab" aria-current="page" data-bs-target="#finance" onClick={(event) => setGetDopFinance(getDopFinance + 1)}><i className="bi bi-currency-exchange"></i> Финансовые операции</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" data-bs-toggle="tab" aria-current="page" data-bs-target="#avakuator"><i className="bi bi-truck-flatbed"></i> Эвакуатор</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" data-bs-toggle="tab" aria-current="page" data-bs-target="#statistics"><i className="bi bi-graph-up-arrow"></i> Статистика</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" data-bs-toggle="tab" aria-current="page" data-bs-target="#settingsShop"><i className="bi bi-gear-fill"></i> Настройки</Link>
                    </li>
                </ul>
                <div className="tab-content p-2">
                    <div id="sclad" className="tab-pane active">
                        <h2>Склад</h2>
                        <AdminTableAllPartShop shopParts={shopParts} setShopParts={setShopParts} shopData={shopData} mainData={props.mainData} key="9485763564" />
                    </div>
                    <div id="cars" className="tab-pane">
                        <h2>Автомобили</h2>
                        <AdminTableCarsPage setShopCars={setShopCars} shopData={shopData} shopCars={shopCars} mainData={props.mainData} />
                    </div>
                    <div id="users" className="tab-pane">
                        <h2>Персонал</h2>
                        <AdminTableUserShop shopData={shopData} setShopUsers={setShopUsers} shopUsers={shopUsers} />
                    </div>
                    <div id="sales" className="tab-pane ">
                        <h2>История продаж</h2>
                        <AdminSalseTable shopData={shopData} shopUsers={shopUsers} mainData={props.mainData}/>
                    </div>
                    <div id="avakuator" className="tab-pane ">
                        <h2>Эвакуатор</h2>
                        <AdminModalEvakuatorPage setShopEvakuatorRemontRabot={setShopEvakuatorRemontRabot} shopEvakuatorRemontRabot={shopEvakuatorRemontRabot} shopEvakuatorTO={shopEvakuatorTO} shopData={shopData} mainData={props.mainData} shopEvakuatorPerevozki={shopEvakuatorPerevozki} setShopEvakuatorPerevozki={setShopEvakuatorPerevozki} />
                    </div>
                    <div id="finance" className="tab-pane ">
                        <h2>Финансовые операции</h2>
                        <AdminDopFinance getDopFinance={getDopFinance} shopData={shopData} mainData={props.mainData} />
                    </div>
                    <div id="statistics" className="tab-pane ">
                        <h2>Статистика</h2>
                        <AdminModalShopStatistic setShop={true} shopData={shopData} />
                    </div>
                    <div id="settingsShop" className="tab-pane ">
                        <h2>Настройки магазина</h2>
                        <AdminSettingsShopPage setShopData={setShopData} shopData={shopData} />
                    </div>
                </div>

                <AdminModalAddNewCar setShopCars={setShopCars} sp_cars={props.mainData.sp_cars} id_shop={shopData.id} />
                <AdminModalAddNewUser setShopUsers={setShopUsers} id_shop={shopData.id} />
            </div>
            : <LoadingIcon />
    );
};

export default AdminShopCardPage;
