import React from 'react';
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import sendForm from "../../utility/SendForm";
import $ from "jquery";
import ShopCard from "../../components/ShopCard/ShopCard";
import Modal2 from "../../components/Modal/Modal2";

const AdminShopsPage = (props) => {

    const SendFormAddNewShop = (event) => {

        let files = document.getElementById('imageShop').files;

        sendForm(event, (data) => {
            if (data['success']){
                document.location.reload();
            }
        }, true, false, true, files);

    }
    const setGeolocation = (event) => {
        $("#addressInput").attr("value", event.value);
    }

    return (
        <div>
            <div className="d-flex align-items-center">
                <h2><i className="bi bi-layout-sidebar-inset-reverse"> </i>Магазины</h2>
                {
                    (props.mainData.role['is_admin'])
                    ? <Modal2 style_mainModal={{height: "auto"}} btn_title="+ создать" btn_class="btn-success btn-sm m-2" title="Создание нового магазина" id="addNewShop">
                            <form action="addNewShop" id="formAddNewShop" method="POST" className="needs-validation" noValidate onSubmit={(event) => SendFormAddNewShop(event)}>
                                <div className="form-group">
                                    <input type="text" required id="inputName" name="name" className="form-control" placeholder="Название магазина" />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                                <div className="form-group mt-2">
                                    <AddressSuggestions inputProps={{placeholder: "Адрес магазина", className: "form-control"}} onChange={(event) => setGeolocation(event)} token="6cc4b26d810fcf2537bcd2ba90f37b926acf6558" />
                                    <input type="text" required className="d-none" name="address" defaultValue="" id="addressInput" />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                                <div className="form-group mt-2">
                                    <textarea name="description" placeholder="Описание" className="form-control" rows="4"></textarea>
                                </div>
                                <div className="form-group mt-2">
                                    <input type="file" accept="image/png, image/gif, image/jpeg" id="imageShop" name="imageShop" />
                                </div>
                                <hr />
                                <div>
                                    <div className="mt-3 alert alert-danger d-none"></div>
                                    <div className="mt-3 alert alert-success d-none"></div>
                                    <input type="submit" className="btn btn-small btn-success" value="Создать магазин" />
                                </div>
                            </form>
                        </Modal2>
                        : false
                }

            </div>
            <hr />
            <div className="d-flex bd-highlight">
                {
                    Object.keys(props.mainData.shops).map((index, i) => (
                      <ShopCard key={i} shop={props.mainData.shops[index]} />
                    ))
                }
            </div>
        </div>
    );
};

export default AdminShopsPage;
