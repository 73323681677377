import $ from 'jquery';
import apiRequest from "./ApiRequest";
export const formDefaultStatus = (event) => {
    const id = "#" + event.target.getAttribute("id");

    $(id + " .alert-danger").removeClass("d-none").addClass("d-none");
    $(id + " .alert-success").removeClass("d-none").addClass("d-none");

    $(id).find('input[type="text"],input[type="number"], input[type="date"],input[type="color"],input[type="password"],input[type="checkbox"],select,textarea').each(function() {
        if ($(this).attr("required")){
            $(this).removeClass("is-invalid");
            $(this).removeClass("is-valid");
        }
    });
}

export const formDefaultStatusFromId = (id_form) => {
    const id = '#' + id_form;

    $(id + ' .alert').empty().addClass("d-none");

    $(id).find('input[type="text"],input[type="number"], input[type="date"], input[type="color"],input[type="password"],input[type="checkbox"],select,textarea').each(function() {
        if ($(this).attr("required")){
            $(this).removeClass("is-invalid");
            $(this).removeClass("is-valid");
        }
    });
}

export const formDefaultStatusAllFrom = () => {

    $('form .alert-danger').removeClass("d-none").addClass("d-none");
    $('form .alert-success').removeClass("d-none").addClass("d-none");

    $("form").find('input[type="text"],input[type="number"], input[type="date"], input[type="color"],input[type="password"],input[type="checkbox"],select,textarea').each(function() {
        if ($(this).attr("required")){
            $(this).removeClass("is-invalid");
            $(this).removeClass("is-valid");
        }
        $(this).val("")
    });
}

const formMessageError = (id, message) => {
    $(id).find(".alert-danger").empty();
    $(id).find(".alert-danger").append(message);
    $(id).find(".alert-danger").removeClass("d-none");
}
const formMessageRessponse = (id, message) => {
    $(id).find(".alert-success").empty();
    $(id).find(".alert-success").append(message);
    $(id).find(".alert-success").removeClass("d-none");
}

const SendForm = (event, callback = () => {}, add_response = true, resset = false, uploadFile = false, file= [], callbackResponseError = () => {}, calbackForStart = () => {}) => {
    const id = "#" + event.target.getAttribute("id");
    const action = event.target.getAttribute("action") + "?";
    const method = event.target.getAttribute("method");

    formDefaultStatus(event);
    $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").addClass("disabled");
    $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").attr("disabled", true);
    event.preventDefault();

    let find_empty_input = false;

    $("#" + event.target.getAttribute("id")).find('input[type="text"],input[type="number"],input[type="color"],input[type="password"],input[type="date"],textarea,select').each(function() {
        if ($(this).attr("required"))
            if ($(this).val() !== ""){
                $(this).addClass("is-valid");
                $(this).removeClass("is-invalid");
            } else {
                if (!find_empty_input){
                    $(this).focus();
                    $('html, body').scrollTop($(this).offset().top);
                    find_empty_input = true;
                }
                $(this).removeClass("is-valid");
                $(this).addClass("is-invalid");
            }

        if($(this).attr('type') === "password")
            if ($(this).val().length <= 8) {
                $(this).removeClass("is-valid");
                $(this).addClass("is-invalid");
            }
    });

    if ($(id).find(".is-invalid").length <= 0){

        calbackForStart();

        let formData = "";

        if (method === "GET")
            formData = $(id).serialize();
        else if (method === "POST"){
            formData = new FormData();
            $("#" + event.target.getAttribute("id")).find('input[type="text"],input[type="number"],input[type="color"],input[type="password"],input[type="checkbox"],select,textarea').each(function(){
                let name = $(this).attr("name");
                let value = $(this).val();
                if ($(this).attr("type") !== "checkbox")
                    formData.append(name, value);
                else
                    if ($(this).prop('checked'))
                        formData.append(name, value);
            });
            if (uploadFile && file) {

                let doc_file = "";

                for (let i = 0; i < file.length; i++){
                    formData.append("images_" + [i], file[i]);
                    if (file[i]['doc'] === "true")
                        doc_file += "images_" + [i] + "|";
                }

                formData.append("doc_file", doc_file);
            }
        }

        apiRequest(action, formData, method, uploadFile).then(data => {

            if (data['success'] !== undefined){

                callback(data);

                if (add_response) {
                    formMessageRessponse(id, data['success']);
                }

                if (resset) {
                    $(id).trigger("reset");
                    formDefaultStatusFromId(event.target.getAttribute("id"));
                }

            }

            if (data['error'] !== undefined){
                formMessageError(id, data['error']);
                callbackResponseError();
            }

            $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").removeClass("disabled");
            $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").attr("disabled", false);

        });

    } else {
        formMessageError(id, "Пожалуйста, заполните все обязательные поля.");
        callbackResponseError();
        $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").removeClass("disabled");
        $("#" + event.target.getAttribute("id")).find("input[type='submit'],button[type='submit']").attr("disabled", false);

    }
}

export default SendForm;