import React, {useState} from 'react';
import Modal2 from "../../../../components/Modal/Modal2";
import sendForm from "../../../../utility/SendForm";
import {toast} from "react-toastify";
import {closeModalWindow, isNumeric} from "../../../../utility/Utility";
import $ from 'jquery';

const ModalEvakuatorNewPoezdka = (props) => {

    const [datePoezdka, setDatePoezdka] = useState("");

    const generateProfit = () => {
        let all_price = $("#all_price").val();
        let summ_one_km = $("#summ_one_km").val();
        let stoimost_zapravk = $("#stoimost_zapravk").val();
        let kilometraz = $("#kilometraz").val();

        if (isNumeric(all_price) && isNumeric(summ_one_km) && isNumeric(stoimost_zapravk) && isNumeric(kilometraz))
            $("#profit").val(parseFloat(all_price) - ((parseFloat(summ_one_km) * parseFloat(kilometraz)) + parseFloat(stoimost_zapravk)));
        else
            $("#profit").val("");
    }

    return (
        <Modal2 size="modal-medium2" title="Добавление новой поездки" id="ModalAddNewPoezdka">
            <form onSubmit={(event) => sendForm(event, (data) => {
                if(data['success']) {
                    props.setShopEvakuatorPerevozki(data['perevozki']);
                    toast.success(data['success']);
                    closeModalWindow("ModalAddNewPoezdka");
                }
            }, true, true)} action="evakuatorAddNewPoezdka" id="FormAddNewPoezdka" method="POST"
                  className="needs-validation" noValidate>

                <input type="text" className="form-control d-none" name="id_shop" value={props.id_shop} />

                <div className="form-group mt-3">
                    <label htmlFor="inputPassword">Дата поездки</label>
                    <div className="col-auto">
                        <div className="input-group mb-2">
                            <input type="date" className="form-control w-100" onChange={(event) => setDatePoezdka(event.target.value)} />
                            <input type="text" className="d-none" name="date" value={datePoezdka} />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mt-3">
                            <label htmlFor="inputPassword">Километраж общий</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input id="kilometraz" onChange={(event) => generateProfit()} type="number" className="form-control w-100" name="kilometraz_bez_gruz"
                                           placeholder="100" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mt-3">
                            <label htmlFor="inputPassword">Километраж c грузом</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control w-100" name="kilometraz_gruz"
                                           placeholder="100" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mt-3">
                            <label htmlFor="inputPassword">Стоимость заправки (руб.)</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input onChange={(event) => generateProfit()} type="number" id="stoimost_zapravk" className="form-control w-100" name="zapravka_rub"
                                           placeholder="100" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mt-3">
                            <label htmlFor="inputPassword">Количество литров топлива</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control w-100" name="zapravka_litr"
                                           placeholder="100" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mt-3">
                            <label htmlFor="inputPassword">Пункт отправления</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <textarea className="form-control w-100" name="punkt_otpravleniya"
                                           placeholder="г. Белореченск, ул. Красная, дом 20" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mt-3">
                            <label htmlFor="inputPassword">Пункт пребывания</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <textarea className="form-control w-100" name="punkt_prebitiya"
                                              placeholder="г. Краснодар, ул. Северная, дом 375" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="inputPassword">Перевозимый груз</label>
                    <div className="col-auto">
                        <div className="input-group mb-2">
                            <textarea className="form-control w-100" name="gruz" placeholder="Ваз 21114 битая" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group mt-3">
                            <label htmlFor="inputPassword">Сумма полученная от клиента</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input onChange={(event) => generateProfit()} type="number" id="all_price" className="form-control w-100" placeholder="9000" name="all_price" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mt-3">
                            <label htmlFor="inputPassword">Плата водителю за км.</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input onChange={(event) => generateProfit()} type="number" id="summ_one_km" className="form-control w-100" placeholder="10" name="summ_one_km" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="inputPassword">Чистая прибыль (руб.)</label>
                    <div className="col-auto">
                        <div className="input-group mb-2">
                            <input type="number" id="profit" className="form-control w-100" placeholder="" disabled="true" name="pribil" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Добавить поездку" className="btn btn-success mt-3 w-100"/>
            </form>
        </Modal2>
    );
};

export default ModalEvakuatorNewPoezdka;
