import React, {useEffect, useState} from 'react';
import Modal2 from "../../../../components/Modal/Modal2";
import sendForm from "../../../../utility/SendForm";
import {toast} from "react-toastify";
import {closeModalWindow, formatNumberWithCommas, objectSize, parseRemontWork} from "../../../../utility/Utility";

const ModalEvakuatorEditRemontRabota = (props) => {

    const [dataRemont, setDateRemont] = useState(false);
    const [datePoezdka, setDatePoezdka] = useState("");
    const [remontWork, setRemontWork] = useState(false);

    useEffect(() => {
        if (props.remontRabotaData) {
            setDateRemont(props.remontRabotaData);
            setDatePoezdka(props.remontRabotaData.date);
            setRemontWork(parseRemontWork(props.remontRabotaData.description));
        }
    }, [props.remontRabotaData]);

    return (
        (props.remontRabotaData)
            ? <Modal2 size="modal-medium2" title="Редактирование ремонтных работ" id="ModalEditRemontRabot">
                <form onSubmit={(event) => sendForm(event, (data) => {
                    if(data['success']) {
                        props.setShopEvakuatorRemontRabot(data['remont_rabot']);
                        toast.success(data['success']);
                        closeModalWindow("ModalEditRemontRabot");
                    }
                }, true, true)} action="editRemontRabota" id="FormEditNewRemontRabota" method="POST"
                      className="needs-validation" noValidate>
                    <input type="submit" value="Добавить ремонтные работы" className="btn btn-success w-100"/>

                    <div className="alert alert-danger d-none mt-3">

                    </div>
                    <div className="alert alert-success mt-3 d-none">

                    </div>

                    <input type="text" className="form-control d-none" name="id_shop" value={props.id_shop} />
                    <input type="text" className="form-control d-none" name="id_remont_rabot" value={props.remontRabotaData.id} />

                    <div className="form-group mt-3">
                        <label htmlFor="inputPassword">Дата ремонтных работ</label>
                        <div className="col-auto">
                            <div className="input-group mb-2">
                                <input type="date" className="form-control w-100" defaultValue={datePoezdka} required onChange={(event) => setDatePoezdka(event.target.value)} />
                                <input type="text" className="d-none" name="date" value={datePoezdka} />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputPassword">Сокращенное описание работ</label>
                        <div className="col-auto">
                            <div className="input-group mb-2">
                                <input type="text" className="form-control w-100" defaultValue={dataRemont.short_description} name="short_description"
                                       placeholder="Замена тормозных колодок, замена масла" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="inputPassword">Полное описание работ и стоимости</label>
                        <div className="col-auto">
                            <div className="input-group mb-2">
                                <textarea rows="10" className="form-control w-100" defaultValue={dataRemont.description} name="description"
                                          onChange={(event) => {
                                              setRemontWork(parseRemontWork(event.target.value));
                                          }}
                                          placeholder="Замена масла 3500" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="mt-2">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Выполненная работа</th>
                            <th scope="col">Стоимость</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            (objectSize(remontWork))
                                ? remontWork.map((elem, i) => {
                                    return <tr>
                                        <td>{i+1}</td>
                                        <td>{elem.type}</td>
                                        <td>{formatNumberWithCommas(elem.sum)}</td>
                                    </tr>
                                })
                                : false
                        }
                        </tbody>
                    </table>

                    <div className="alert alert-danger d-none mt-3">

                    </div>
                    <div className="alert alert-success mt-3 d-none">

                    </div>
                    <input type="submit" value="Добавить ремонтные работы" className="btn btn-success mt-3 w-100"/>
                </form>
            </Modal2>
            : false
    );
};

export default ModalEvakuatorEditRemontRabota;
