import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AutentificationPage from "./pages/AutentificationPage";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import AccountTemplatePage from "./pages/AccountTemplatePage";
import PolicyAuth, {exitAccount} from "./utility/Policy";
import apiRequest from "./utility/ApiRequest";
import AccountMainPage from "./pages/AccountMainPage";
import AdminShopsPage from "./pages/administration/AdminShopsPage";
import AdminStatisticPage from "./pages/administration/AdminStatisticPage";
import AdminSettingsPage from "./pages/administration/AdminSettingsPage";
import AdminShopCardPage from "./pages/administration/AdminShopCardPage";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/style.css'
import DisasemblerWareHouse from "./pages/disassembler/DisasemblerWareHouse";
import SalesmanBoxoffice from "./pages/salesman/SalesmanBoxoffice";
import SalesmanHistorysales from "./pages/salesman/SalesmanHistorysales";
import '../src/js/main'
import AccountSettingsProfile from "./pages/AccountSettingsProfile";
import AccountTraningPage from "./pages/AccountTraningPage";
import AdminNotificationPage from "./pages/administration/AdminNotificationPage";

function App() {
    const [mainData, setMainData] = useState(false);

    useEffect(() => {
        if (PolicyAuth()){
            setMainData(false)
            apiRequest("getMainData?", "").then((data) => {
                console.log(data)
                if (data['success']){
                    setMainData(data);
                } else if (data['error']){
                    if(data['error'] === "e01")
                        exitAccount();
                }
            });

            const intervalId = setInterval(() => {
                apiRequest("updateDateLastAction?", "").then((data) => {

                });
            }, 60000);
            return () => clearInterval(intervalId);
        }
    }, []);

  return (
      <BrowserRouter>
          <Routes>
              {/*--------------Открытая часть сайта----------------*/}
              {!PolicyAuth() ? <Route path="*" element={<AutentificationPage />} /> : false}
              {!PolicyAuth() ? <Route exact="true" path="/forgot" element={<ForgotPassword />} /> : false}
              {!PolicyAuth() ? <Route exact="true" path="/ChangePassword" element={<ChangePassword />} /> : false}
              {/*--------------Открытая часть сайта----------------*/}




              {/*--------------Общие настройки аккаунта----------------*/}
              {PolicyAuth() ? <Route exact="true" path="/account/settings" element={<AccountTemplatePage mainData={mainData} content={<AccountSettingsProfile mainData={mainData} setMainData={setMainData} />} />} /> : false}
              {PolicyAuth() ? <Route exact="true" path="/account/traning" element={<AccountTemplatePage mainData={mainData} content={<AccountTraningPage mainData={mainData} setMainData={setMainData} />} />} /> : false}
              {PolicyAuth() ? <Route exact="true" path="*" element={<AccountTemplatePage mainData={mainData} content={<AccountMainPage role={mainData.role} />} />} /> : false}
              {/*--------------Общие настройки аккаунта----------------*/}




              {/*--------------Маршрутизация аккаунта администратора и Управляющийа----------------*/}
              {
                  mainData
                      ? PolicyAuth() && (mainData.role['is_admin'] || mainData.role['is_manager'])
                          ? <Route exact="true" path="/account/shops" element={<AccountTemplatePage mainData={mainData} content={<AdminShopsPage mainData={mainData} />} />} />
                          : false
                      : false
              }
              {
                  mainData
                      ? PolicyAuth() && (mainData.role['is_admin'] || mainData.role['is_manager'])
                          ? <Route exact="true" path="/account/shopCard" element={<AccountTemplatePage mainData={mainData} content={<AdminShopCardPage mainData={mainData} />} />} />
                          : false
                      : false
              }
              {
                  mainData
                      ? PolicyAuth() && (mainData.role['is_admin'] || mainData.role['is_manager'])
                          ? <Route exact="true" path="/account/notification" element={<AccountTemplatePage mainData={mainData} content={<AdminNotificationPage setMainData={setMainData} mainData={mainData} />} />} />
                          : false
                      : false
              }
              {
                  mainData
                      ? PolicyAuth() && (mainData.role['is_admin'] || mainData.role['is_manager'])
                          ? <Route exact="true" path="/account/analytics" element={<AccountTemplatePage mainData={mainData} content={<AdminStatisticPage />} />} />
                          : false
                      : false
              }
              {
                  mainData
                      ? PolicyAuth() && (mainData.role['is_admin'])
                          ? <Route exact="true" path="/account/sysConfig" element={<AccountTemplatePage mainData={mainData} content={<AdminSettingsPage mainData={mainData} setMainData={setMainData} />} />} />
                          : false
                      : false
              }
              {/*--------------Маршрутизация аккаунта администратора и Управляющийа----------------*/}




              {/*--------------Маршрутизация аккаунта разборщика----------------*/}

              {
                  mainData
                      ? PolicyAuth() && (mainData.role['is_disassembler'])
                          ? <Route exact="true" path="/account/warehouse" element={<AccountTemplatePage mainData={mainData} content={<DisasemblerWareHouse mainData={mainData} />} />} />
                          : false
                      : false
              }

              {/*--------------Маршрутизация аккаунта разборщика----------------*/}




              {/*--------------Маршрутизация аккаунта продавца----------------*/}

              {
                  mainData
                      ? PolicyAuth() && (mainData.role['is_salesman'])
                          ? <Route exact="true" path="/account/boxoffice" element={<AccountTemplatePage dontSetPaddingTitle="true" mainData={mainData} content={<SalesmanBoxoffice mainData={mainData} />} />} />
                          : false
                      : false
              }

              {
                  mainData
                      ? PolicyAuth() && (mainData.role['is_salesman'])
                          ? <Route exact="true" path="/account/historysales" element={<AccountTemplatePage mainData={mainData} content={<SalesmanHistorysales mainData={mainData} />} />} />
                          : false
                      : false
              }

              {/*--------------Маршрутизация аккаунта продавца----------------*/}



          </Routes>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
          />
      </BrowserRouter>
  );
}

export default App;
