import React, {useEffect} from 'react';
import Select from "react-select"
import {useState} from "react";
import ImgAds from "../../../components/ImgFormPreview/ImgAds";
import sendForm from "../../../utility/SendForm";
import apiRequest from "../../../utility/ApiRequest";
import $ from 'jquery';
import {closeModalWindow, objectSize} from "../../../utility/Utility";
import Modal2 from "../../../components/Modal/Modal2";
import {toast} from "react-toastify";

let indexLastImage = 0;
let indexLastImageDoc = 0;

const AdminModalEditCar = (props) => {

    const [displayImgAds, setdisplayImgAds] = useState([]);
    const [displayImgAdsDoc, setdisplayImgAdsDoc] = useState([]);
    const [sendAddNewCar, setSendAddNewCar] = useState(false);
    const [AvtoMarka, setAvtoMarka] = useState(0);
    const [issetImages, setIssetImages] = useState({});
    const [issetImagesDoc, setIssetImagesDoc] = useState({});
    const [dateCreate, setDateCreate] = useState("");
    const [dateAdd, setDateAdd] = useState("");

    useEffect(()=> {

        if (objectSize(props.editDataCar)) {
            setdisplayImgAds([]);
            setdisplayImgAdsDoc([]);
            setIssetImages(props.editDataCar.images);
            setIssetImagesDoc(props.editDataCar.images_doc);
            setAvtoMarka(props.editDataCar.marka['id']);
            setDateCreate(props.editDataCar.date_create);
            setDateAdd(props.editDataCar.date_add);
            $('#editCarSelect option[value="' + props.editDataCar.status + '"]').prop('selected', true);
        }

    }, [props.editDataCar]);

    const addImgAds = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImage++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAds([...displayImgAds, ...arrImg]);
        event.target.value = null;
    }
    const addImgAdsDoc = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImageDoc++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAdsDoc([...displayImgAdsDoc, ...arrImg]);
        event.target.value = null;
    }

    const deleteImgAds = (idImg) => {
        setdisplayImgAds(displayImgAds.filter(obj => {
            return obj['index'] !== idImg;
        }));
    }
    const deleteImgAdsDoc = (idImg) => {
        setdisplayImgAdsDoc(displayImgAdsDoc.filter(obj => {
            return obj['index'] !== idImg;
        }));
    }

    const deleteImageServer = (id_img, id_cars, id_shop, type) => {
        apiRequest("deleteCarImg?", "id_shop=" + id_shop + "&id_cars=" + id_cars + "&id_img=" + id_img + "&type=" + type).then((data) => {
            console.log(data);
            if (data['success']){
                setIssetImages(issetImages.filter(i => i.indexOf(id_img) < 0));
                setIssetImagesDoc(issetImagesDoc.filter(i => i.indexOf(id_img) < 0));
            } else
                alert(data['error']);
        });
    }

    const addNewCar = (event) => {

        event.preventDefault();

        if (!sendAddNewCar){
            let files = [];
            for (let i = 0; i < displayImgAds.length; i++)
                files.push(displayImgAds[i]['file']);

            for (let i = 0; i < displayImgAdsDoc.length; i++){
                displayImgAdsDoc[i]['file']['doc'] = "true";
                files.push(displayImgAdsDoc[i]['file']);
            }

            setSendAddNewCar(true);

            sendForm(event, (data) => {
                if (data['success']){
                    if (data['cars'])
                        props.setShopCars(data['cars']);
                    closeModalWindow("EditCarShop");
                    toast.success(data['success']);
                }
                setSendAddNewCar(false);
            }, true, false, true, files, () => {
                setSendAddNewCar(false);
            });
        }
    }

    return (
        <Modal2 title="Изменение данных автомобиля" id="EditCarShop">
            {
                (objectSize(props.editDataCar))
                    ? <form onSubmit={(event) => addNewCar(event)} action="editCarShop" id="formEditCarShop" method="POST"
                            className="needs-validation" noValidate key={"formEditCar_" + props.editDataCar.id}>
                        <input type="submit" value="Изменить данные автомобиля" className="btn btn-success mt-3"/>
                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <div className="alert alert-success mt-3 d-none">

                        </div>

                        <div className="form-group row mt-3">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Марка</label>
                            <div className="col-sm-10">
                                <Select
                                    options={props.sp_cars}
                                    placeholder="Марка автомобиля"
                                    isSearchable={true}
                                    onChange={(event) => setAvtoMarka(event.value)}
                                    defaultValue = {
                                        props.sp_cars.filter(option =>
                                            option.value === parseInt(props.editDataCar.marka['id']))
                                    }/>
                                <input name="marka" type="text" value={AvtoMarka} className="d-none" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                        <input type="text" className="d-none" defaultValue={props.id_shop} name="id_shop" />
                        <input type="text" className="d-none" name="id_car" defaultValue={props.editDataCar.id} />


                        <div className="form-group row mt-3">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Модель</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="model" placeholder="Camry" defaultValue={props.editDataCar.model} required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>

                        <div className="form-group row mt-3">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Доп. параметры</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="dopParametr" placeholder="Доп. параметры автомобиля" defaultValue={props.editDataCar.dop_parametr} />
                            </div>
                        </div>

                        <div className="form-group row mt-3">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Модель/Номер двигателя</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="dvigatel_number" placeholder="модель/номер двигателя" defaultValue={props.editDataCar.dvigatel_number} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">VIN номер</label>
                                <div className="form-group row mt-1">
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" name="vinnomer" placeholder="Z94CB41AACR123456" defaultValue={props.editDataCar.vinnomer} />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">Гос. номер</label>
                                <div className="form-group row mt-1">
                                    <div className="col-sm-10">
                                        <input type="text" className="form-control" name="gosnomer" placeholder="А637АА93" defaultValue={props.editDataCar.gosnomer} />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">Год выпуска</label>
                                <div className="form-group row mt-1">
                                    <div className="col-sm-auto">
                                        <input type="number" className="form-control" placeholder="" defaultValue={dateCreate} required onChange={(event) => setDateCreate(event.target.value)} />
                                        <input type="text" id="date_create_input123" className="form-control d-none" defaultValue={dateCreate} name="date_create" placeholder="" />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">Состояние</label>
                                <div className="form-group row mt-1">
                                    <div className="col-sm-10">
                                        <select id="editCarSelect" className="form-select" name="state" required>
                                            <option value="">Выберите из списка</option>
                                            <option value="Битая">Битая</option>
                                            <option value="Битая разворованная">Битая разворованная</option>
                                            <option value="Целая">Целая</option>
                                            <option value="Целая разворованная">Целая разворованная</option>
                                            <option value="Разворованная">Разворованная</option>
                                        </select>
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <label htmlFor="inputPassword" className="col-form-label">Дата покупки</label>
                                <div className="form-group row mt-1">
                                    <div className="col-sm-auto">
                                        <input type="date" className="form-control" required defaultValue={dateAdd} onChange={(event) => setDateAdd(event.target.value)} />
                                        <input type="text" className="form-control d-none" name="date" id={"editCarData_date_add45454"} value={dateAdd} placeholder="" />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-group row mt-1">
                                    <label htmlFor="inputPassword" className="col-form-label">Цена</label>
                                    <div className="col-auto">
                                        <div className="input-group mb-2">
                                            <input type="number" className="form-control" name="price"
                                                   placeholder="0" defaultValue={props.editDataCar.price} required />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">руб.</div>
                                            </div>
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group row mt-1">
                                    <label htmlFor="inputPassword" className="col-form-label">Стоимость доставки</label>
                                    <div className="col-auto">
                                        <div className="input-group mb-2">
                                            <input type="number" className="form-control" name="delivery_price"
                                                   placeholder="0" defaultValue={props.editDataCar.delivery_price} />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">руб.</div>
                                            </div>
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group row mt-1">
                                    <label htmlFor="inputPassword" className="col-form-label">Цена за информацию</label>
                                    <div className="col-auto">
                                        <div className="input-group mb-2">
                                            <input type="number" className="form-control" name="information_price"
                                                   placeholder="0" defaultValue={props.editDataCar.information_price} />
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">руб.</div>
                                            </div>
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group row mt-3">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Комментарии к покупке</label>
                            <div className="col-sm-10">
                                <textarea rows="5" name="comment" className="form-control" defaultValue={props.editDataCar.description}>

                                </textarea>
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>

                        <hr />
                        Фотографии
                        <div className="mt-3 w-100">
                            <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                                {
                                    (objectSize(props.editDataCar))
                                        ? (objectSize(issetImages))
                                            ? Object.keys(issetImages).map((image, key) => {
                                                return (
                                                        <ImgAds src={props.editDataCar.images[image]} id_shop={props.id_shop} id_cars={props.editDataCar.id} id_img={issetImages[image].split("/")[issetImages[image].split("/").length - 1]} key={key} deleteImageServer={deleteImageServer} deleteImgServer={true} delete_type="img" />
                                                    )
                                            })
                                            : false
                                        : false
                                }
                                {
                                    (displayImgAds.length)
                                        ? displayImgAds.map((image, key) => {
                                            return (
                                                <ImgAds src={image['url']} id_img={image.index} deleteImage={deleteImgAds} key={key} />
                                            )
                                        })
                                        : false
                                }
                                <label className="mt-2">
                                    <div className="imagebutton">
                                        <i className="bi bi-camera"></i>
                                    </div>
                                    <input type="file" multiple="multiple" onChange={(event) => addImgAds(event)} hidden />
                                </label>
                            </div>
                        </div>

                        Документы
                        <div className="mt-3 w-100">
                            <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                                {
                                    (objectSize(props.editDataCar))
                                        ? (objectSize(issetImagesDoc))
                                            ? Object.keys(issetImagesDoc).map((image, key) => {
                                                return (
                                                    <ImgAds src={props.editDataCar.images_doc[image]} id_shop={props.id_shop} id_cars={props.editDataCar.id} id_img={issetImagesDoc[image].split("/")[issetImagesDoc[image].split("/").length - 1]} key={key} deleteImageServer={deleteImageServer} deleteImgServer={true} delete_type="doc" />
                                                )
                                            })
                                            : false
                                        : false
                                }
                                {
                                    (displayImgAdsDoc.length)
                                        ? displayImgAdsDoc.map((image, key) => {
                                            return (
                                                <ImgAds src={image['url']} id_img={image.index} deleteImage={deleteImgAdsDoc} key={key} />
                                            )
                                        })
                                        : false
                                }
                                <label className="mt-2">
                                    <div className="imagebutton">
                                        <i className="bi bi-camera"></i>
                                    </div>
                                    <input type="file" multiple="multiple" onChange={(event) => addImgAdsDoc(event)} hidden />
                                </label>
                            </div>
                        </div>

                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <div className="alert alert-success mt-3 d-none">

                        </div>
                        <input type="submit" value="Изменить данные автомобиля" className="btn btn-success mt-3"/>
                    </form>
                    : <div className={"alert alert-danger"}>Извините, данные автомобиля не найдены.</div>
            }
        </Modal2>
    );
};

export default AdminModalEditCar;