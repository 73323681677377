import React, {useEffect, useState} from 'react';
import ApiRequest from "../../utility/ApiRequest";
import TableSales from "../../components/TableSales/TableSales";
import LoadingIcon from "../../components/LoadingIcon/LoadingIcon";
import sendForm from "../../utility/SendForm";
import {toast} from "react-toastify";
import Select from "react-select";

const SalesmanHistorysales = (props) => {

    const [loadsBy, setLoadsBy] = useState(false);
    const [sales, setSales] = useState(false);
    const [date_start, setDate_start] = useState("");
    const [date_end, setDate_end] = useState("");
    const [typePartID, setTypePartID] = useState("");

    const loadBy = (id_shop = "", date_start = "", date_end = "") => {
        setLoadsBy(false)

        ApiRequest("getSales?", "id_shop=" + id_shop + "&date_start=" + date_start + "&date_end=" + date_end).then((data) => {
           if (data['sales'])
               setSales(data['sales'])

            setLoadsBy(true);
        });
    }

    useEffect(() => {
        loadBy();
    }, []);


    return (
        <div>
            <h2>История продаж</h2>
            <hr />
            <div>
                <h6>Укажите период продаж</h6>
                <form action="getSales" id="getSalesForm" noValidate method="GET" className="form needs-validation"
                      onSubmit={(event) => sendForm(event, (data) => {
                          if (data['success']){
                              if (data['sales'])
                                  setSales(data['sales'])
                              toast.success(data['success']);
                          } else if (data['error'])
                              toast.error(data['error']);
                      }, false, )}>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="inputPassword" className="col-form-label">Дата начала периода</label>
                            <div className="form-group mt-1">
                                <div>
                                    <input type="date" className="form-control w-100" placeholder="" required onChange={(event) => setDate_start(event.target.value)} />
                                    <input type="text" className="form-control d-none" name="date_start" value={date_start} />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="inputPassword" className="col-form-label">Дата окончания периода</label>
                            <div className="form-group mt-1">
                                <div>
                                    <input type="date" className="form-control w-100" placeholder="" required onChange={(event) => setDate_end(event.target.value)} />
                                    <input type="text" className="form-control d-none" name="date_end" value={date_end} />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group mt-1">
                                <label htmlFor="inputPassword" className="col-form-label">Тип запчасти</label>
                                <Select
                                    options={props.mainData.sp_parts}
                                    placeholder="Тип запчасти"
                                    isSearchable={true}
                                    onChange={(event) => setTypePartID(event.value)}
                                />
                                <input type="text" className="form-control d-none" name="typePart" placeholder="" defaultValue={typePartID} />
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                    <button className="btn btn-success w-100 mt-2" type="submit">
                        Получить продажи
                    </button>
                </form>
            </div>
            <div className="mt-3"></div>
            {
                (loadsBy)
                    ? <TableSales id="tableSales1" sales={sales} />
                    : <LoadingIcon />
            }
        </div>
    );
};

export default SalesmanHistorysales;
