import React, {useEffect, useState} from 'react';
import sendForm, {formDefaultStatusAllFrom} from "../../../../utility/SendForm";
import {toast} from "react-toastify";
import Select from "react-select";
import {objectSize} from "../../../../utility/Utility";
import Modal2 from "../../../../components/Modal/Modal2";

const ModalSendPartTheOtherShop = (props) => {

    const id_modal = (props.id_modal) ? props.id_modal : "SendPartTheOtherShop";

    const closeModal = () => {
        props.setFocusPartID(0);
        formDefaultStatusAllFrom();
    }

    useEffect(() => {
        let myModal = document.getElementById(id_modal);
        myModal.addEventListener("hide.bs.modal", closeModal);
        return () => {
            myModal.removeEventListener("hide.bs.modal", closeModal);
        }
    }, []);

    const [sendShopID, setSendShopID] = useState("");

    return (
            (objectSize(props.editPartData))
                ?   <Modal2 title="Перемещение запчасти" id={(props.id_modal) ? props.id_modal : "SendPartTheOtherShop"}>
                    <h5>Переместить в магазин</h5>
                    <form style_mainModal={{height: "auto"}} onSubmit={(event) => sendForm(event, (data) => {
                        if (data['success']){

                            if (data['parts']['success'])
                                props.setCarParts(data['parts']['parts']);
                            else
                            if (data['parts']['error'])
                                props.setCarParts(false);

                            toast.success(data['success']);
                        } else {
                            toast.error(data['error']);
                        }
                    }, true, true)} action="sendPartOtherShop" id={(props.id_form) ? props.id_form : "sendPartOtherShop"} method="POST"
                          className="needs-validation" noValidate key={"sendPartOtherShop_" + props.editPartData.id}>

                        <input type="text" className="form-control d-none" name="id_shop" value={props.id_shop} />
                        <input type="text" className="form-control d-none" name="id_car" value={props.id_car} />
                        <input type="text" className="form-control d-none" name="id_part" value={props.editPartData.id} />

                        <div className="form-group row mt-3">
                            <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Выберите магазин</label>
                            <div className="col-sm-10">
                                <Select
                                    options={props.sp_shops}
                                    placeholder="Магазин"
                                    isSearchable={true}
                                    onChange={(event) => setSendShopID(event.value)}
                                />
                                <input type="text" className="form-control d-none" id="inp_id_shop_sendPartOtherShop" name="send_shop_id" value={sendShopID} required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <div className="alert alert-success mt-3 d-none">

                        </div>
                        <input type="submit" value="Переместить" className="btn btn-success mt-3"/>
                    </form>
                </Modal2>
                : false
    );
};

export default ModalSendPartTheOtherShop;