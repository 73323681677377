import React, {useEffect, useState} from 'react';
import SendForm from "../utility/SendForm";
import OpenPartTemplatePage from "./OpenPartTemplatePage";
import {useSearchParams} from "react-router-dom";
import GlobalParam from "../utility/AppGlobalParam";
import appGlobalParam from "../utility/AppGlobalParam";

const ChangePassword = () => {

    const [searchParams] = useSearchParams();
    const [token, setToken] = useState("");

    useEffect(() => {
        if (searchParams.get("change") !== null && searchParams.get("link") !== null) {
            setToken(searchParams.get("link"));
        } else {
            window.location.href = GlobalParam.domain;
        }
    }, [searchParams]);

    return (
        <OpenPartTemplatePage title="Смена пароля">
            <form action="changePassword" method="GET" id="formChangePassword" onSubmit={(event) => SendForm(event, (data) => {
                if (data['success'])
                    window.location.href = appGlobalParam.domain;
            }, true, false)}>
                <div className="form-group">
                    <label>Пароль</label>
                    <input type="password" className="form-control" placeholder="Введите пароль" name="password" />
                </div>
                <div className="form-group pt-2">
                    <label>Повторите пароль</label>
                    <input type="password" className="form-control" placeholder="Введите пароль" name="passwordRepeat" />
                </div>
                <input type="text" className="form-control d-none" name="user_access_token" defaultValue={token} />
                <div className="mt-3 alert alert-danger d-none"></div>
                <div className="mt-3 alert alert-success d-none"></div>
                <button type="submit" className="btn btn-success btn-flat mt-2">Сменить пароль</button>
            </form>
        </OpenPartTemplatePage>
    );
};

export default ChangePassword;