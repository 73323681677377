import React from 'react';
import {Mosaic} from "react-loading-indicators";

const LoadingIcon = () => {
    return (
        <div className="text-center pt-3">
            <Mosaic color="#333" size="small" text="" textColor="" />
        </div>
    );
};

export default LoadingIcon;
