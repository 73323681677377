import React from 'react';

const Modal2 = (props) => {
    return (
        <div>

            {
                props.btn_title
                    ? <button className={"btn " + props.btn_class} modal-open="true" modal-id={props.id}>
                        {props.btn_title}
                    </button>
                    : false
            }

            <div id={props.id} className={"modal2 " + props.size} tabIndex="-1" role="dialog" style={{overflow: (props.overflow_hidden) ? "hidden" : "auto"}}>
                <div className="modal-content2">
                    <div className="modal-header2 d-flex justify-content-between">
                        <h3>{props.title}</h3>
                        <span className="close close_hide_modal2">&times;</span>
                    </div>
                    <div className="modal-body2" style={props.style}>
                        {
                            props.children
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal2;
