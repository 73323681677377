import React from 'react';
import $ from "jquery";
import sendForm from "../../../utility/SendForm";
import Modal2 from "../../../components/Modal/Modal2";
import {closeModalWindow} from "../../../utility/Utility";
import {toast} from "react-toastify";

const AdminModalChangeRole = (props) => {
    $('#formEditRoledUserShop input:checkbox').prop('checked', false);
    props.userData.role.split(",").forEach((elem) => {
        $("#formEditRoledUserShop_" + props.id + ' input[value="' + elem + '"]').prop('checked', true);
    });

    const addNewUser = (event) => {
        event.preventDefault();

        if ($("#formEditRoledUserShop_" + props.id + " .form-check-input:checked").length > 0){
            $("#formEditRoledUserShop_" + props.id + " #selectDolznostSuccess").addClass("d-block");
            $("#formEditRoledUserShop_" + props.id + " #selectDolznostError").removeClass("d-block");
        } else {
            $("#formEditRoledUserShop_" + props.id + " #selectDolznostSuccess").removeClass("d-block");
            $("#formEditRoledUserShop_" + props.id + " #selectDolznostError").addClass("d-block");
        }

        sendForm(event, (data) => {
            if (data['success']){
                if (data['users'])
                    props.setShopUsers(data.users);
                toast.success(data['success']);
                closeModalWindow("editRoleUserShop_" + props.id);
            }
        }, true, true);
    }

    return (
        <Modal2 title={"Изменение должности для " + props.userData.fio} id={"editRoleUserShop_" + props.id}>
            <form onSubmit={(event) => addNewUser(event)} action="editRoleUserShop" id={"formEditRoledUserShop_" + props.id} method="GET"
                  className="needs-validation" noValidate>
                <input type="text" className="d-none" defaultValue={props.userData.id} name="id_user"/>
                <input type="text" className="d-none" defaultValue={props.id_shop} name="id_shop"/>
                <div className="form-check">
                    <input className="form-check-input edit-role-checkbox" type="checkbox" name="manager" value="Управляющий" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Управляющий
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="salesman" value="Продавец" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Продавец
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="disassembler" value="Разбощик" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Разборщик
                    </label>
                </div>
                <div id="selectDolznostSuccess" className="valid-feedback">
                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                </div>
                <div id="selectDolznostError" className="invalid-feedback">
                    Поле обязательно к заполнению
                </div>
                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Изменить должность" className="btn btn-success mt-3"/>
            </form>
        </Modal2>
    );
};

export default AdminModalChangeRole;