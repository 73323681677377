import React from 'react';
import Select from "react-select"
import {useState} from "react";
import ImgAds from "../../../components/ImgFormPreview/ImgAds";
import sendForm, {formDefaultStatusFromId} from "../../../utility/SendForm";
import Modal2 from "../../../components/Modal/Modal2";
import {closeModalWindow} from "../../../utility/Utility";
import {toast} from "react-toastify";

let indexLastImage = 0;
let indexLastImageDoc = 0;

const AdminModalAddNewCar = (props) => {

    const [displayImgAds, setdisplayImgAds] = useState([]);
    const [displayImgAdsDoc, setdisplayImgAdsDoc] = useState([]);
    const [adsAvtoMarka, setAdsAvtoMarka] = useState("");
    const [sendAddNewCar, setSendAddNewCar] = useState(false);
    const [dateshop, setDateShop] = useState("");
    const [dateCreateCar, setDateCreateCar] = useState("");

    const addImgAds = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImage++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAds([...displayImgAds, ...arrImg]);
        event.target.value = null;
    }
    const addImgAdsDoc = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImageDoc++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAdsDoc([...displayImgAdsDoc, ...arrImg]);
        event.target.value = null;
    }

    const deleteImgAds = (idImg) => {
        setdisplayImgAds(displayImgAds.filter(obj => {
            return obj['index'] !== idImg;
        }));
    }
    const deleteImgAdsDoc = (idImg) => {
        setdisplayImgAdsDoc(displayImgAdsDoc.filter(obj => {
            return obj['index'] !== idImg;
        }));
    }

    const addNewCar = (event) => {

        event.preventDefault();

        if (!sendAddNewCar){
            let files = [];
            for (let i = 0; i < displayImgAds.length; i++)
                files.push(displayImgAds[i]['file']);

            for (let i = 0; i < displayImgAdsDoc.length; i++){
                displayImgAdsDoc[i]['file']['doc'] = "true";
                files.push(displayImgAdsDoc[i]['file']);
            }

            setSendAddNewCar(true);

            sendForm(event, (data) => {
                if (data['success']){
                    if (data['cars'])
                        props.setShopCars(data['cars']);
                }

                setdisplayImgAds([]);
                setdisplayImgAdsDoc([]);
                setAdsAvtoMarka("");
                setSendAddNewCar(false);
                setDateShop("");
                setDateCreateCar("");
                indexLastImage = 0;
                indexLastImageDoc = 0;
                formDefaultStatusFromId("formAddNewCarShop");

                closeModalWindow("AddNewCarShop");
                toast.success(data['success']);
                setSendAddNewCar(false);
            }, true, true, true, files, () => {
                setSendAddNewCar(false);
            });
        }
    }

    return (
        <Modal2 title="Добавление нового автомобиля" id="AddNewCarShop">
            <form onSubmit={(event) => addNewCar(event)} action="addNewCarShop" id="formAddNewCarShop" method="POST"
                  className="needs-validation" noValidate>

                <div className="form-group row">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Марка</label>
                    <div className="col-sm-10">
                        <Select
                            options={props.sp_cars}
                            placeholder="Марка автомобиля"
                            isSearchable="true"
                            onChange={(event) => setAdsAvtoMarka(event.value)}
                        />
                        <input name="marka" type="text" defaultValue={adsAvtoMarka} className="d-none" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>
                <input type="text" className="d-none" defaultValue={props.id_shop} name="id_shop" />
                <div className="form-group row mt-3">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Модель</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" name="model" placeholder="Camry" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>
                <div className="form-group row mt-3">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Доп. параметры</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" name="dopParametr" placeholder="Доп. параметры автомобиля" />
                    </div>
                </div>

                <div className="form-group row mt-3">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Модель/Номер двигателя</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" name="dvigatel_number" placeholder="модель/номер двигателя" />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">VIN номер</label>
                        <div className="form-group row mt-1">
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="vinnomer" placeholder="Z94CB41AACR123456" />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">Гос. номер</label>
                        <div className="form-group row mt-1">
                            <div className="col-sm-10">
                                <input type="text" className="form-control" name="gosnomer" placeholder="А637АА93" />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">Год выпуска</label>
                        <div className="form-group row mt-1">
                            <div className="col-sm-auto">
                                <input type="number" className="form-control" placeholder="2014" required onChange={(event) => setDateCreateCar(event.target.value)} />
                                <input type="text" className="form-control d-none" name="date_create" defaultValue={dateCreateCar} placeholder="" />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">Состояние</label>
                        <div className="form-group row mt-1">
                            <div className="col-sm-10">
                                <select className="form-select" name="state" required>
                                    <option value="">Выберите из списка</option>
                                    <option value="Битая">Битая</option>
                                    <option value="Битая разворованная">Битая разворованная</option>
                                    <option value="Целая">Целая</option>
                                    <option value="Целая разворованная">Целая разворованная</option>
                                    <option value="Разворованная">Разворованная</option>
                                </select>
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">Дата покупки</label>
                        <div className="form-group row mt-1">
                            <div className="col-sm-auto">
                                <input type="date" className="form-control" placeholder="" required onChange={(event) => setDateShop(event.target.value)} />
                                <input type="text" className="form-control d-none" name="date" defaultValue={dateshop} placeholder="" />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group row mt-1">
                            <label htmlFor="inputPassword" className="col-form-label">Цена</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="price"
                                           placeholder="0" required />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">руб.</div>
                                    </div>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group row mt-1">
                            <label htmlFor="inputPassword" className="col-form-label">Стоимость доставки</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="delivery_price"
                                           placeholder="0" />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">руб.</div>
                                    </div>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group row mt-1">
                            <label htmlFor="inputPassword" className="col-form-label">Цена за информацию</label>
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="information_price"
                                           placeholder="0" />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">руб.</div>
                                    </div>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="is_vostanovlenie" value="1" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Автомобиль под востановление
                    </label>
                </div>

                <div className="form-group row mt-3">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Коммент. к покупке</label>
                    <div className="col-sm-10">
                        <textarea rows="5" name="comment" className="form-control">

                        </textarea>
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>

                <hr />
                Фотографии
                <div className="mt-3 w-100">
                    <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                        {
                            (displayImgAds.length)
                                ? displayImgAds.map((image, key) => {
                                    return (
                                        <ImgAds src={image['url']} id_img={image.index} deleteImage={deleteImgAds} key={key} />
                                    )
                                })
                                : false
                        }
                        <label className="mt-2">
                            <div className="imagebutton">
                                <i className="bi bi-camera"></i>
                            </div>
                            <input type="file" multiple="multiple" onChange={(event) => addImgAds(event)} hidden />
                        </label>
                    </div>
                </div>

                Документы
                <div className="mt-3 w-100">
                    <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                        {
                            (displayImgAdsDoc.length)
                                ? displayImgAdsDoc.map((image, key) => {
                                    return (
                                        <ImgAds src={image['url']} id_img={image.index} deleteImage={deleteImgAdsDoc} key={key} />
                                    )
                                })
                                : false
                        }
                        <label className="mt-2">
                            <div className="imagebutton">
                                <i className="bi bi-camera"></i>
                            </div>
                            <input type="file" multiple="multiple" onChange={(event) => addImgAdsDoc(event)} hidden />
                        </label>
                    </div>
                </div>


                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success d-none mt-3">

                </div>
                <input type="submit" value="Добавить автомобиль" className="btn btn-success mt-3"/>
            </form>
        </Modal2>
    );
};
export default AdminModalAddNewCar;