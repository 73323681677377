import React, {useState} from 'react';
import sendForm from "../../../utility/SendForm";
import Select from "react-select";
import apiRequest from "../../../utility/ApiRequest";
import {toast} from "react-toastify";
import Modal2 from "../../../components/Modal/Modal2";
import {closeModalWindow} from "../../../utility/Utility";

const AdminSettingsSPParts = (props) => {

    const [defaultListPartType, setDefaultListPartType] = useState(props.mainData.sp_parts);
    const [currentTypePartSetAdsCategory, setCurrentTypePartSetAdsCategory] = useState(false);
    const [adsCategory, setAdsCategory] = useState(false);

    const deleteTypePart = (id) => {
        apiRequest("deleteNewTypePart?", "id_type_part=" + id, "POST").then((data) => {
            if (data['success']){
                if (data['type_parts']){
                    props.setMainData({...props.mainData, "sp_parts": data['type_parts']});
                    setDefaultListPartType(data['type_parts']);
                }
                toast.success(data['success']);
            } else
                toast.error(data['error']);
        })
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-5">
                    <div>
                        <h5>Типы запчастей</h5>
                        <div className="row mb-3">
                            <div className="col-md-8">
                                <Select
                                    options={props.mainData.sp_parts}
                                    placeholder="Поиск типа запчасти"
                                    isSearchable={true}
                                    onChange={(event) => {
                                        const filteredData = props.mainData.sp_parts.filter(item => item.value === event.value);
                                        setDefaultListPartType(filteredData);
                                    }}
                                />
                            </div>
                            <div className="col-md-4">
                                <button className="btn tbn-sm btn-danger w-100" onClick={(event) => {
                                    setDefaultListPartType(props.mainData.sp_parts);
                                }}>Очистить фильтр</button>
                            </div>
                        </div>
                        <div style={{minHeight: "70vh", maxHeight: "70vh", overflowY: "auto"}}>
                        <table>
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Наименование</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(defaultListPartType).map((part, index) => {
                                    return <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                            <div className="dropdown dropstart">
                                                {defaultListPartType[part].label}
                                                <button className="btn btn-дшпре dropdown-toggle" type="button"
                                                        id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                    Действия
                                                </button>
                                                {
                                                    (defaultListPartType[part].categ)
                                                        ? <i className="bi bi-star-fill text-success"></i>
                                                        : false
                                                }
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <button className="dropdown-item" modal-open="true" modal-id="setAdsCategory" onClick={(event) => setCurrentTypePartSetAdsCategory(part)}>Назначить категорию обьявления</button>
                                                    <button className="dropdown-item" onClick={(event) => deleteTypePart(defaultListPartType[part].value)}>Удалить</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>})
                            }
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <h5>Добавление новых типов запчастей</h5>
                    <form action="addNewTypePart" id="addNewTypePart23" method="POST" className="needs-validation" noValidate onSubmit={(event) => sendForm(event, (data) => {
                        if (data['success']){
                            if (data['type_parts']){
                                props.setMainData({...props.mainData, "sp_parts": data['type_parts']});
                                setDefaultListPartType(data['type_parts']);
                            }
                        }
                    }, true)}>
                        <div className="form-group mt-2">
                            <textarea name="list_part" placeholder="Перечислите типы запчастей (каждая запчасть в новой строке)" required className="form-control" rows="1" onChange={(event) => {
                                var lines = event.target.value.split(/\r\n|\r|\n/);
                                var lineCount = lines.length;
                                event.target.setAttribute("rows", 1 + lineCount);
                            }}></textarea>
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>

                        <div className="mt-3 alert alert-danger d-none"></div>
                        <div className="mt-3 alert alert-success d-none"></div>
                        <input type="submit" className="btn btn-small btn-success mt-3" value="Добавить в справочник" />
                    </form>
                </div>
            </div>
            {
                (currentTypePartSetAdsCategory)
                    ? <Modal2 size="modal-medium2" id="setAdsCategory" title="Назначение категории обьявления">
                        <h5>{defaultListPartType[currentTypePartSetAdsCategory].label}</h5>
                        {
                            (defaultListPartType[currentTypePartSetAdsCategory].categ)
                                ? <div className="mt-2">
                                    Текущая категория обьявлений: <b>{defaultListPartType[currentTypePartSetAdsCategory].categ}</b>
                                </div>
                                : false
                        }
                        <form action="updateCategoryAds" id="updateCategoryAds" method="POST" className="needs-validation mt-2" noValidate onSubmit={(event) => sendForm(event, (data) => {
                            if (data['success']){
                                toast.success(data['success']);
                                closeModalWindow("setAdsCategory");
                                setDefaultListPartType(data['type_parts']);
                            }
                        }, true)}>
                            <Select
                                options={props.mainData.sp_category_ads}
                                placeholder="Марка"
                                isSearchable="true"
                                onChange={(event) => {
                                    setAdsCategory(event.value);
                                }}
                            />
                            <input type="text" value={adsCategory} className="d-none" name="adsCategory" />
                            <input type="text" value={defaultListPartType[currentTypePartSetAdsCategory].value} className="d-none" name="id_typePart" />

                            <div className="mt-3 alert alert-danger d-none"></div>
                            <div className="mt-3 alert alert-success d-none"></div>
                            <input type="submit" className="btn btn-small btn-success mt-3" value="Назначить" />
                        </form>
                    </Modal2>
                    : false
            }
        </div>
    );
};

export default AdminSettingsSPParts;
