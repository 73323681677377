import React, {useState} from 'react';
import {objectSize} from "../../utility/Utility";
import {toast} from "react-toastify";
import Modal2 from "../../components/Modal/Modal2";
import apiRequest from "../../utility/ApiRequest";

const AdminNotificationPage = (props) => {

    const [viewNotificationId, setViewNotificationId] = useState(false);

    const deleteReview = (id) => {
        apiRequest("deletePredlozhenieRevent?", "id_notification=" + id, "POST").then((data) => {
            if (data['success']){
                toast.success(data['success']);
                props.setMainData(data['mainData']);
            }

            if (data['error'])
                toast.error(data['error']);
        });
    }

    return (
        <div>
            <h2><i className="bi bi-bell-fill"></i> Оповещения</h2>
            <hr />
            {
                (objectSize(props.mainData.receive))
                    ? <div>
                        <table className="border-none">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Тема</th>
                                <th scope="col">Отправитель</th>
                                <th scope="col">Телефон</th>
                                <th scope="col">Адрес электронной почты</th>
                                <th scope="col">Дата</th>
                                <th scope="col">Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(props.mainData.receive).map((index, i) => {
                                    return <tr>
                                        <td>{i+1}</td>
                                        <td modal-open="true" modal-id="viewNotification" onClick={(event) => {
                                            setViewNotificationId(index);
                                        }} style={{cursor: "pointer"}} className="text-decoration-underline">{props.mainData.receive[index]['name']}</td>
                                        <td className="text-center">{props.mainData.receive[index]['fio_send']}</td>
                                        <td className="text-center">{props.mainData.receive[index]['phone_send']}</td>
                                        <td className="text-center">{props.mainData.receive[index]['mail_send']}</td>
                                        <td className="text-center">{props.mainData.receive[index]['date_send']}</td>
                                        <td className="text-center">
                                            <button className="btn btn-danger btn-sm" onClick={(event) => deleteReview(props.mainData.receive[index]['id'])}>
                                                Удалить
                                            </button>
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                        {
                            (viewNotificationId)
                                ? <Modal2 size="modal-medium2" id="viewNotification" title="Просмотр оповещения">
                                    <h3>{props.mainData.receive[viewNotificationId]['name']}</h3>
                                    <p><b>Отправитель:</b> {props.mainData.receive[viewNotificationId]['fio_send']}</p>
                                    <p><b>Номер телефона:</b> {props.mainData.receive[viewNotificationId]['phone_send']}</p>
                                    <p><b>Адрес электронной почты:</b> {props.mainData.receive[viewNotificationId]['mail_send']}</p>
                                    <p><b>Оповещение: </b> {props.mainData.receive[viewNotificationId]['message']}</p>
                                </Modal2>
                                : false
                        }
                    </div>
                    : <div className="alert alert-danger">Оповещений нет.</div>
            }
        </div>
    );
};

export default AdminNotificationPage;
