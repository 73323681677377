import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ModalEvakuatorNewPoezdka from "./ModalEvakuator/ModalEvakuatorNewPoezdka";
import ModalEvakuatorRemontRabota from "./ModalEvakuator/ModalEvakuatorRemontRabota";
import {convertDateFormat, formatNumberWithCommas, objectSize} from "../../../utility/Utility";
import apiRequest from "../../../utility/ApiRequest";
import {toast} from "react-toastify";
import ModalEvakuatorEditPoezdka from "./ModalEvakuator/ModalEvakuatorEditPoezdka";
import $ from 'jquery';
import ModalEvakuatorEditRemontRabota from "./ModalEvakuator/ModalEvakuatorEditRemontRabota";
import ModalEvakuatorViewRemontRabota from "./ModalEvakuator/ModalEvakuatorViewRemontRabota";

const AdminModalEvakuatorPage = (props) => {

    const [editPoezdkaData, setEditPoezdkaData] = useState(false);
    const [editRemontRabotaData, setEditRemontRabotaData] = useState(false);

    const deletePoezdka = (id_poezdka) => {
        apiRequest("deletePoezdka?", "id_shop=" + props.shopData.id + "&id_poezdka=" + id_poezdka, "POST").then((data) => {
            if (data['success']){
                toast.success(data['success']);
                props.setShopEvakuatorPerevozki(data['perevozki']);
            }

            if (data['error'])
                toast.error(data['error']);
        });
    }
    const deleteRemont = (id_remont) => {
        apiRequest("deleteRemontRabota?", "id_shop=" + props.shopData.id + "&id_remont_rabot=" + id_remont, "POST").then((data) => {
            if (data['success']){
                toast.success(data['success']);
                props.setShopEvakuatorRemontRabot(data['remont_rabot']);
            }

            if (data['error'])
                toast.error(data['error']);
        });
    }
    const setTO = () => {
        apiRequest("setTO?", "id_shop=" + props.shopData.id, "POST").then((data) => {
            if (data['success']){
                toast.success(data['success']);

            }

            if (data['error'])
                toast.error(data['error']);
        });
    }

    return (
        <div>
            {
                (objectSize(props.shopEvakuatorTO))
                    ? (parseFloat(props.shopEvakuatorTO.kilometraz) > 8000)
                        ? <div className="alert alert-warning" id="container_to_info">
                            <h4>Необходимо провести ТО</h4>
                            <ul className="list-group alert-warning">
                                <li className="list-group-item">Замена масла в двигателе</li>
                                <li className="list-group-item">Замена насла в коробке</li>
                                <li className="list-group-item">Замена воздушного фильтра</li>
                                <li className="list-group-item">Проверка уровень антифриза</li>
                                {
                                    (parseFloat(props.shopEvakuatorTO.count_to) >= 2)
                                        ? <>
                                            <li className="list-group-item">Замена масла в заднем мосту</li>
                                            <li className="list-group-item">Проверка тормозных колодок</li>
                                        </>
                                        : false
                                }
                            </ul>
                        </div>
                        : false
                    : false
            }
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <Link className="nav-link active" data-bs-toggle="tab" data-bs-target="#razdel_perevozki"><i  className="bi bi-signpost-split"></i> Перевозки</Link>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#razdel_remont"><i className="bi bi-gear-fill"></i> Ремонтные работы</Link>
                </li>
            </ul>
            <div className="tab-content p-2">
                <div id="razdel_perevozki" className="tab-pane active">
                    <button className="btn btn-success btn-sm" modal-open="true" modal-id="ModalAddNewPoezdka">+ добавить новою поездку</button>
                    {
                        objectSize(props.shopEvakuatorPerevozki)
                            ? <table className="mt-2">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Дата</th>
                                    <th scope="col">Километраж с грузом</th>
                                    <th scope="col">Километраж общий</th>
                                    <th scope="col">Заправка в литрах</th>
                                    <th scope="col">Заправка в рублях</th>
                                    <th scope="col">Пункт отправления</th>
                                    <th scope="col">Пункт прибытия</th>
                                    <th scope="col">Перевозимый груз</th>
                                    <th scope="col">Чистая прибыль</th>
                                    <th scope="col">Действия</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(props.shopEvakuatorPerevozki).map((index, i) => {
                                        return <tr>
                                            <td scope="col">{i+1}</td>
                                            <td>{convertDateFormat(props.shopEvakuatorPerevozki[index].date)}</td>
                                            <td className="text-center">{props.shopEvakuatorPerevozki[index].kilometraz_gruz} км.</td>
                                            <td className="text-center">{props.shopEvakuatorPerevozki[index].kilometraz_bez_gruz} км.</td>
                                            <td className="text-center">{props.shopEvakuatorPerevozki[index].zapravka_litr} лит.</td>
                                            <td className="text-center">{props.shopEvakuatorPerevozki[index].zapravka_rub} руб.</td>
                                            <td>{props.shopEvakuatorPerevozki[index].punkt_otpravleniya}</td>
                                            <td>{props.shopEvakuatorPerevozki[index].punkt_prebitiya}</td>
                                            <td>{props.shopEvakuatorPerevozki[index].gruz}</td>
                                            <td className="text-center">{formatNumberWithCommas(props.shopEvakuatorPerevozki[index].pribil)}</td>
                                            <td>
                                                <div className="dropdown dropstart">
                                                    <button className="btn btn-дшпре dropdown-toggle" type="button"
                                                            id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                        Действия
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <button className="dropdown-item" modal-open="true" modal-id="ModalEditPoezdka" onClick={(event) => setEditPoezdkaData(props.shopEvakuatorPerevozki[index])}>Редактировать</button>
                                                        <button className="dropdown-item" onClick={(event) => deletePoezdka(props.shopEvakuatorPerevozki[index].id)}>Удалить</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                            : <div className="alert alert-danger mt-2">Поездок нет.</div>
                    }
                </div>
                <div id="razdel_remont" className="tab-pane">
                    <button className="btn btn-success btn-sm" modal-open="true" modal-id="ModalAddRemontRabot">+ добавить ремонтные работы</button>
                    {
                        objectSize(props.shopEvakuatorRemontRabot)
                            ? <table className="mt-2">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Дата</th>
                                    <th scope="col">Описание работ</th>
                                    <th scope="col">Стоимость выполненных работ</th>
                                    <th scope="col">Действия</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(props.shopEvakuatorRemontRabot).map((index, i) => {
                                        return <tr>
                                            <td scope="col">{i+1}</td>
                                            <td>{convertDateFormat(props.shopEvakuatorRemontRabot[index].date)}</td>
                                            <td className="text-decoration-underline" style={{cursor: "pointer",minWidth: 200}} modal-open="true" modal-id="ModalViewRemontRabot" onClick={(event) => setEditRemontRabotaData(props.shopEvakuatorRemontRabot[index])}>{props.shopEvakuatorRemontRabot[index].short_description}</td>
                                            <td className="text-center">{formatNumberWithCommas(props.shopEvakuatorRemontRabot[index].price)}</td>
                                            <td>
                                                <div className="dropdown dropstart">
                                                    <button className="btn btn-дшпре dropdown-toggle" type="button"
                                                            id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                        Действия
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <button className="dropdown-item" modal-open="true" modal-id="ModalEditRemontRabot" onClick={(event) => setEditRemontRabotaData(props.shopEvakuatorRemontRabot[index])}>Редактировать</button>
                                                        <button className="dropdown-item" onClick={(event) => deleteRemont(props.shopEvakuatorRemontRabot[index].id)}>Удалить</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                            : <div className="alert alert-danger mt-2">Ремонтных работ нет.</div>
                    }
                </div>
            </div>
            <ModalEvakuatorNewPoezdka id_shop={props.shopData.id} setShopEvakuatorPerevozki={props.setShopEvakuatorPerevozki}/>
            <ModalEvakuatorEditPoezdka poezdkaData={editPoezdkaData} id_shop={props.shopData.id} setShopEvakuatorPerevozki={props.setShopEvakuatorPerevozki}/>
            <ModalEvakuatorEditRemontRabota remontRabotaData={editRemontRabotaData} id_shop={props.shopData.id} setShopEvakuatorRemontRabot={props.setShopEvakuatorRemontRabot} />
            <ModalEvakuatorViewRemontRabota remontRabotaData={editRemontRabotaData} id_shop={props.shopData.id} setShopEvakuatorRemontRabot={props.setShopEvakuatorRemontRabot} />
            <ModalEvakuatorRemontRabota id_shop={props.shopData.id} setShopEvakuatorRemontRabot={props.setShopEvakuatorRemontRabot} />
        </div>
    );
};

export default AdminModalEvakuatorPage;
