import React, {useEffect, useState} from 'react';
import {convertDateFormat, formatNumberWithCommas, objectSize} from "../../../../utility/Utility";
import Modal2 from "../../../../components/Modal/Modal2";
import sendForm, {formDefaultStatusAllFrom} from "../../../../utility/SendForm";
import {toast} from "react-toastify";
import apiRequest from "../../../../utility/ApiRequest";

const ModalUserZarabPlata = (props) => {

    const [listSalaries, setListCalaries] = useState(false);

    useEffect(() => {
        if (objectSize(props.dataUser))
            setListCalaries(props.dataUser.salaries);
    }, [props.dataUser]);

    const deleteZP = (id_zp) => {
        apiRequest("deleteZPUser?", "id_shop=" + props.id_shop + "&id_user=" + props.dataUser.id + "&id_zp=" + id_zp, "POST").then((data) => {
            if (data['success']){
                toast.success(data['success']);
                setListCalaries(data['salaries']);
            } else {
                toast.error(data['error']);
            }
        });
    }

    return (
        <Modal2 size="modal-medium2" id="setNewZPlata" title="Выдача заработной платы">
            {
                (objectSize(props.dataUser))
                    ? <div>
                        <h5>Выдача заработной платы</h5>
                        <form onSubmit={(event) => sendForm(event, (data) => {
                            if (data['success']){
                                toast.success(data['success']);
                                setListCalaries(data['salaries']);
                                formDefaultStatusAllFrom();
                            }
                        }, true, true)} action="AddNewZPUser" id="FormAddNewZP" method="POST"
                              className="needs-validation" noValidate>

                            <input type="text" className="form-control d-none" name="id_shop" value={props.id_shop} />
                            <input type="text" className="form-control d-none" name="id_user" value={props.dataUser.id} />

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-form-label">Сумма заработной платы</label>
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="summ_zp"
                                           placeholder="28000" required />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">руб.</div>
                                    </div>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-form-label">Сумма премии</label>
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="summ_premiya"
                                           placeholder="5000" />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">руб.</div>
                                    </div>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-form-label">За что выплатили премию ?</label>
                                <div className="input-group mb-2">
                                    <textarea name="opisanie_premii" rows="5" className="form-control"></textarea>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>

                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-3 d-none">

                            </div>
                            <input type="submit" value="Выдать заработную плату" className="btn btn-success mt-3"/>
                        </form>
                        {
                            (objectSize(listSalaries))
                                ? <div>
                                    <hr />
                                    <h5>Выданные заработные платы</h5>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Дата</th>
                                            <th scope="col">Сумма зарплаты</th>
                                            <th scope="col">Сумма премии</th>
                                            <th scope="col">За что премия</th>
                                            <th scope="col">Общая сумма зарплаты</th>
                                            <th scope="col">Действия</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            Object.keys(listSalaries).map((indexb, b) => {
                                                return <tr key={b}>
                                                    <th scope="row">{b+1}</th>
                                                    <td>{convertDateFormat(listSalaries[indexb].date)}</td>
                                                    <td>{formatNumberWithCommas(listSalaries[indexb].summ_zp)}</td>
                                                    <td>{formatNumberWithCommas(listSalaries[indexb].summ_premiya)}</td>
                                                    <td>{listSalaries[indexb].opisanie_premii}</td>
                                                    <td>{formatNumberWithCommas(parseFloat(listSalaries[indexb].summ_zp) + parseFloat(listSalaries[indexb].summ_premiya))}</td>
                                                    <td>
                                                        <button className="dropdown-item" onClick={(event) => deleteZP(listSalaries[indexb].id)}>Удалить</button>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                : false
                        }
                    </div>
                    : false
            }
        </Modal2>
    );
};

export default ModalUserZarabPlata;
