import React from 'react';
import {Link} from "react-router-dom";
import AdminSettingsSPParts from "./AdminSettingsPage/AdminSettingsSPParts";
import AdminSettingsSPPartSovmestimost from "./AdminSettingsPage/AdminSettingsSPPartSovmestimost";
import AdminSettingsSPFinance from "./AdminSettingsPage/AdminSettingsSPFinance";

const AdminSettingsPage = (props) => {

    return (
        <div>
            <h2><i className="bi bi-gear-fill"> </i>Настройки системы</h2>
            <hr />
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <Link className="nav-link active" data-bs-toggle="tab" data-bs-target="#part_list_conteiner"><i
                        className="bi bi-journal-medical"></i> Справочник запчастей</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#part_list_sovmestimost_conteiner"><i
                        className="bi bi-journal-medical"></i> Справочник совместимости запчастей</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#finance_list_conteiner"><i
                        className="bi bi-journal-medical"></i> Справочник финансовых операций</Link>
                </li>
            </ul>

            <div className="tab-content p-2">
                <div id="part_list_conteiner" className="tab-pane active">
                    <AdminSettingsSPParts mainData={props.mainData} setMainData={props.setMainData} />
                </div>
                <div id="part_list_sovmestimost_conteiner" className="tab-pane">
                    <AdminSettingsSPPartSovmestimost sp_parts={props.mainData.sp_parts} sp_cars={props.mainData.sp_cars} mainData={props.mainData} setMainData={props.setMainData} />
                </div>
                <div id="finance_list_conteiner" className="tab-pane">
                    <AdminSettingsSPFinance sp_finance={props.mainData.sp_finance} mainData={props.mainData} setMainData={props.setMainData} />
                </div>
            </div>
        </div>
    );
};

export default AdminSettingsPage;
