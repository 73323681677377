import React from 'react';
import PasswordInput from "../components/PaswordInput/PasswordInput";
import PhoneInput from "../components/PhoneInput/PhoneInput";
import sendForm from "../utility/SendForm";
import wordByIndex from "../utility/Utility";

const AccountSettingsProfile = (props) => {
    return (
        <div>
            <h2><i className="bi bi-person-square"></i> Настройки профиля</h2>
            <hr />
            <form onSubmit={(event) => sendForm(event,(data) => {

            }, true, true)} action="changeProfilePassword" id="FormChangeProfilePassword" method="POST" className="needs-validation" noValidate>
                <h5>Сменить пароль</h5>
                <div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom02" className="form-label">Старый пароль</label>
                        <PasswordInput name="preventPassword" type="password" className="form-control" placeholder="Введите старый пароль, не мение 8 символов" required />
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom03" className="form-label">Новый пароль</label>
                        <PasswordInput name="newPassword" type="password" className="form-control" placeholder="Введите новый пароль, не мение 8 символов" required />
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom03" className="form-label">Повторите новый пароль</label>
                        <PasswordInput name="repeatNewPassword" type="password" className="form-control" placeholder="Повторите новый пароль" required />
                    </div>
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Изменить пароль" className="btn btn-success mt-3" />
            </form>

            <form onSubmit={(event) => sendForm(event,(data) => {
                if (data['success']){
                    let newMainData = props.mainData;
                    newMainData['userData'] = data['userData'];
                    props.setMainData(newMainData);
                }
            }, true, false)} action="editDataProfile" id="formEditDataProfile" method="POST" className="needs-validation mt-2" noValidate>
                <h5>Изменить данные профиля</h5>
                <div className="mt-3">
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">Адрес электронной почты</label>
                        <input name="mail" defaultValue={props.mainData.userData.mail} type="text" id="validationCustom04" className="form-control" placeholder="Введите адрес электронной почты" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom05" className="form-label">Номер телефона</label>
                        <PhoneInput name="phone" defaultValue={props.mainData.userData.phone} id="addNewUserPhone" />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div>
                        <label htmlFor="validationCustom06" className="form-label">Фамилия</label>
                        <input name="lastName" defaultValue={wordByIndex(props.mainData.userData.fio, 1)} type="text" id="validationCustom06" className="form-control" placeholder="Введите фамилию" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom07" className="form-label">Имя</label>
                        <input name="Name" type="text" defaultValue={wordByIndex(props.mainData.userData.fio, 2)} id="validationCustom07" className="form-control" placeholder="Введите имя" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom08" className="form-label">Отчество</label>
                        <input name="middleName" type="text"  defaultValue={wordByIndex(props.mainData.userData.fio, 3)} id="validationCustom08" className="form-control" placeholder="Отчество" />
                    </div>
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Изменить данные" className="btn btn-success mt-3" />
            </form>
        </div>
    );
};

export default AccountSettingsProfile;
