import React from 'react';
import {Link} from "react-router-dom";
import SendForm from "../utility/SendForm";
import OpenPartTemplatePage from "./OpenPartTemplatePage";
import {goAccount} from "../utility/Policy";
import PasswordInput from "../components/PaswordInput/PasswordInput";

const AutentificationPage = () => {
    return (
        <OpenPartTemplatePage title="Авторизация">
            <form action="auth" method="GET" id="formAutentification" onSubmit={(event) => SendForm(event, (data) => {
                if (data['success']){
                    goAccount(data)
                }
            }, true, false)}>
                <div className="form-group">
                    <label>Логин</label>
                    <input type="text" className="form-control" placeholder="Введите логин" name="login" />
                </div>
                <div className="form-group pt-2">
                    <label>Пароль</label>
                    <PasswordInput placeholder="Введите пароль" name="password" />
                </div>
                <div className="mt-3 alert alert-danger d-none"></div>
                <div className="mt-3 alert alert-success d-none"></div>
                <button type="submit" className="btn btn-success mt-2">Войти</button>
                <div className="checkbox text-center pt-2">
                    <Link className="text-dark" to="/forgot">Забыли пароль ?</Link>
                </div>
            </form>
        </OpenPartTemplatePage>
    );
};

export default AutentificationPage;
