import React from 'react';

const ImgAds = (props) => {
    return (
        <div className="imgAds d-flex flex-row mr-2 mt-2" id={"container_ads_image_id_" + props.id_img} id_img={props.id_img}>
            <img src={props.src} id={"ads_image_id_" + props.id_img} title="" alt="" width="100" height="100" className="mr-2 rounded d-inline"  />
            <div className="d-flex flex-column">
                <h5>
                    {
                        props.deleteImgServer
                            ? <i onClick={(event) => {
                                props.deleteImageServer(props.id_img, (props.id_part) ? props.id_part : props.id_cars, props.id_shop, props.delete_type);
                            }} style={{cursor: "pointer"}} className="bi bi-trash-fill"></i>
                            : <i onClick={(event) => props.deleteImage(props.id_img)} style={{cursor: "pointer"}} className="bi bi-trash-fill"></i>
                    }
                </h5>
            </div>
        </div>
    );
};

export default ImgAds;
