import React from 'react';
import SendForm from "../../../utility/SendForm";
import $ from "jquery";
import Modal2 from "../../../components/Modal/Modal2";

const AdminModalDeleteUserShop = (props) => {
    return (
        <Modal2 title="Удаление сотрудника" id={"DeleteUserShop_" + props.id}>
            <form onSubmit={(event) => SendForm(event, (data) => {
                if (data['success']){
                    if (data['users'])
                        props.setShopUsers(data.users);
                    $('.btn-close').trigger('click');
                }
            }, true, true)} action="deleteUserShop" id={"formDeleteUserShop_" + props.id} method="GET"
                  className="needs-validation" noValidate>
                <input type="text" className="d-none" defaultValue={props.userData.id} name="id_user"/>
                <input type="text" className="d-none" defaultValue={props.id_shop} name="id_shop"/>
                <p>Вы действительно хотите удалить сотрудника {props.userData.fio} ?</p>
                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Удалить" className="btn btn-danger mt-3"/>
            </form>
        </Modal2>
    );
};

export default AdminModalDeleteUserShop;