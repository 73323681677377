import React, {useState} from 'react';
import Select from "react-select";
import sendForm from "../../../utility/SendForm";

const AdminSettingsSPFinance = (props) => {

    const [defaultListPartType, setDefaultListPartType] = useState(props.mainData.sp_finance);

    return (
        <div>
            <div className="row">
                <div className="col-md-5">
                    <div>
                        <h5>Типы финансовых операций</h5>
                        <div className="row mb-3">
                            <div className="col-md-8">
                                <Select
                                    options={props.mainData.sp_finance}
                                    placeholder="Поиск типа запчасти"
                                    isSearchable={true}
                                    onChange={(event) => {
                                        const filteredData = props.mainData.sp_finance.filter(item => item.value === event.value);
                                        setDefaultListPartType(filteredData);
                                    }}
                                />
                            </div>
                            <div className="col-md-4">
                                <button className="btn tbn-sm btn-danger w-100" onClick={(event) => {
                                    setDefaultListPartType(props.mainData.sp_finance);
                                }}>Очистить фильтр</button>
                            </div>
                        </div>
                        <div style={{minHeight: "70vh", maxHeight: "70vh", overflowY: "auto"}}>
                            <table>
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Наименование</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(defaultListPartType).map((part, index) => {
                                        return <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{defaultListPartType[part].label}</td>
                                        </tr>})
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <h5>Добавление новых типов финансовых операций</h5>
                    <form action="addNewTypeFinance" id="addNewTypeFinance" method="POST" className="needs-validation" noValidate onSubmit={(event) => sendForm(event, (data) => {
                        if (data['success']){
                            if (data['type_finances']){
                                props.setMainData({...props.mainData, "sp_finance": data['type_finances']});
                                setDefaultListPartType(data['type_finances']);
                            }
                        }
                    }, true)}>
                        <div className="form-group mt-2">
                            <textarea name="list_finance" placeholder="Перечислите типы финансовых операций (каждый тип в новой строке)" required className="form-control" rows="1" onChange={(event) => {
                                var lines = event.target.value.split(/\r\n|\r|\n/);
                                var lineCount = lines.length;
                                event.target.setAttribute("rows", 1 + lineCount);
                            }}></textarea>
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>

                        <div className="mt-3 alert alert-danger d-none"></div>
                        <div className="mt-3 alert alert-success d-none"></div>
                        <input type="submit" className="btn btn-small btn-success mt-3" value="Добавить в справочник" />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminSettingsSPFinance;
