import React, {useEffect} from 'react';
import IMask from 'imask';
import appGlobalParam from "../../utility/AppGlobalParam";

const PhoneInput = (props) => {
    useEffect(() => {
        const element = document.getElementById(props.id);
        const maskOptions = {
            mask: appGlobalParam.phoneInputMask
        };
        const mask = IMask(element, maskOptions);
    }, []);

    return (
        <input type="text" defaultValue={props.defaultValue} className="form-control" name={props.name}
               placeholder={appGlobalParam.phoneInputMask} id={props.id} required />
    );
};

export default PhoneInput;