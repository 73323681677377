import React, {useEffect} from 'react';
import {AddressSuggestions} from "react-dadata";
import sendForm from "../../../utility/SendForm";
import $ from "jquery";
import {Link} from "react-router-dom";
import PasswordInput from "../../../components/PaswordInput/PasswordInput";

const AdminSettingsShopPage = (props) => {

    const SendFormEditDataShop = (event) => {

        let files = document.getElementById('imageShop').files;

        sendForm(event, (data) => {
            console.log(data);
            if (data['success']){
                props.setShopData(data['dataShop']);
            }
        }, true, false, true, files);

    }

    useEffect(() => {
        $ ("#addressInput").attr("value", props.shopData.adress);
    }, []);

    const setGeolocation = (event) => {
        $("#addressInput").attr("value", event.value);
    }

    return (
        <div>
            <form action="editDataShop" id="formEditDataShop2" method="POST" className="needs-validation" noValidate onSubmit={(event) => SendFormEditDataShop(event)} key="193784365">

                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link className="nav-link active" data-bs-toggle="tab" data-bs-target="#main_settings">Основные настройки</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#ads_settings">Настройки публикации обьявлений</Link>
                    </li>
                </ul>
                <div className="tab-content p-2">
                    <div id="main_settings" className="tab-pane active">
                        <div className="form-group">
                            <input type="text" defaultValue={props.shopData.name} required id="inputName" name="name" className="form-control" placeholder="Название магазина" />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        <input type="text" name="id_shop" defaultValue={props.shopData.id} className="d-none" />
                        <div className="form-group mt-2">
                            <AddressSuggestions defaultQuery={props.shopData.adress} inputProps={{placeholder: "Адрес магазина", className: "form-control"}} onChange={(event) => setGeolocation(event)} token="6cc4b26d810fcf2537bcd2ba90f37b926acf6558" />
                            <input type="text" required className="d-none" name="address" id="addressInput" />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                        <div className="form-group mt-2">
                            <textarea name="description" placeholder="Описание" className="form-control" rows="4" defaultValue={props.shopData.description}></textarea>
                        </div>
                        <div className="form-group mt-2">
                            <div>
                                <img className="img-thumbnail" src={props.shopData.img_300} alt="" />
                            </div>
                            <div className="mt-2">
                                <input type="file" accept="image/png, image/gif, image/jpeg" id="imageShop" name="imageShop" />

                            </div>
                        </div>
                    </div>
                    <div id="ads_settings" className="tab-pane">
                        <div className="form-group">
                            <input type="text" name="login_profil_ads" className="form-control" placeholder="Логин с сайта kuban-avto.com" defaultValue={props.shopData.login_kuban_avto} />
                        </div>
                        <div className="form-group pt-2">
                            <input type="text" name="password_profil_ads" className="form-control" placeholder="Пароль с сайта kuban-avto.com" defaultValue={props.shopData.password_kuban_avto} />
                        </div>
                        <div className="form-group mt-2">
                            <textarea name="text_msg_ads" defaultValue={props.shopData.ads_msg} placeholder="Описание текста для каждого обьявления (подставляется под каждое обьявлением автоматически)" className="form-control" rows="5"></textarea>
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <div className="mt-3 alert alert-danger d-none"></div>
                    <div className="mt-3 alert alert-success d-none"></div>
                    <input type="submit" className="btn btn-small btn-success" value="Сохранить данные" />
                </div>
            </form>
        </div>
    );
};

export default AdminSettingsShopPage;