import $ from 'jquery';

$(document).ready(function(){
   $(document).on('click', '[modal-open="true"]', function(event) {
      var modal = document.getElementById($(this).attr("modal-id"));
      modal.style.display = "block";
      $(modal).trigger('shown.bs.modal');
   });

   $(document).on('click', '.close_hide_modal2', function(event) {
      var modal = document.getElementById($(this).closest(".modal2").attr("id"));
      modal.style.display = "none";
      $(modal).trigger('hide.bs.modal');
   });
});