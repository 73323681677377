import React from 'react';
import Modal2 from "../../../../components/Modal/Modal2";
import apiRequest from "../../../../utility/ApiRequest";
import {toast} from "react-toastify";
import {closeModalWindow, objectSize} from "../../../../utility/Utility";
import sendForm from "../../../../utility/SendForm";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";

const ModalPublicPartSite = (props) => {

    return (
        (objectSize(props.editPartData))
            ? <Modal2 size="modal-medium2" id="publicPartSite" title="Публикация обьявления на сайте">
                <form action="publishPartSite" id="publicPartSiteForm" method="POST" className="needs-validation" noValidate onSubmit={(event) => sendForm(event, (data) => {
                    if (data['success']){
                        toast.success(data['success']);
                        closeModalWindow("publicPartSite");

                        let updatedArr = props.parts.map(item =>
                            item.id === data['partData']['id'] ? data['partData'] : item
                        );

                        props.setParts(updatedArr);
                    }
                }, true)}>
                    <div className="mt-2">
                        <label htmlFor="validationCustom05" className="form-label">Номер телефона</label>
                        <PhoneInput name="phone" id="addNewUserPhone" />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="form-group mt-2">
                        <textarea name="description" placeholder="Текст обьявления" className="form-control" rows="5"></textarea>
                    </div>
                    <input type="text" className="d-none" name="id_part" value={props.editPartData.id} />
                    <div className="mt-2">
                        <div className="mt-3 alert alert-danger d-none"></div>
                        <div className="mt-3 alert alert-success d-none"></div>
                        <input type="submit" className="btn btn-small btn-success" value="Опубликовать обьявление" />
                    </div>
                </form>
            </Modal2>
            : false
    );
};

export default ModalPublicPartSite;
