import React, {useEffect, useState} from 'react';
import {convertDateFormat, formatNumberWithCommas, objectSize} from "../../utility/Utility";
import SallesModalViewSales from "../../pages/salesman/Modals/SallesModalViewSales";

const TableSales = (props) => {

    const [copyListSales, setCopyListSales] = useState(false);
    const [currentSalesCardId, setCurrentSalesCardId] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Количество элементов на странице
    const [allSummSales, setAllSummSales] = useState(0);
    const [countNalichType, setCountNalichType] = useState(0);
    const [countBeznalichType, setCountBeznalichType] = useState(0);
    const [countCombinirType, setCountCombinirType] = useState(0);

    let count_page = Math.ceil(Object.keys(props.sales).length / itemsPerPage);
    let page = []
    if (Object.keys(props.sales).length > itemsPerPage)
        for (let i = 0; i < count_page; i++)
            page.push(<li className="page-item"><a className="page-link" onClick={(event) => paginate(i+1)} href="#">{i+1}</a></li>)

    // Функция для изменения текущей страницы
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        let currentItems = props.sales.slice(indexOfFirstItem, indexOfLastItem);
        setCopyListSales(currentItems);
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        let indexOfLastItem = currentPage * itemsPerPage;
        let indexOfFirstItem = indexOfLastItem - itemsPerPage;
        let currentItems = props.sales.slice(indexOfFirstItem, indexOfLastItem);
        setCopyListSales(currentItems);
    }, [props.sales]);

    useEffect(() => {
        let summ = 0;
        let countNalich = 0;
        let countBeznalich = 0;
        let countCombinir = 0;

        Object.keys(copyListSales).map((index, i) => {
            summ += parseFloat(copyListSales[index].all_summ);

            (copyListSales[index].type_pay === 1)
                ? countNalich += 1
                : (copyListSales[index].type_pay === 2)
                    ? countBeznalich += 1
                    : countCombinir += 1
        });

        setAllSummSales(summ);
        setCountNalichType(countNalich);
        setCountBeznalichType(countBeznalich);
        setCountCombinirType(countCombinir);

    }, [copyListSales]);

    return (
        <div id={props.id}>
            {
                (objectSize(copyListSales))
                    ? <div>
                        <div className="alert alert-info row">
                            <div className="col-md-1">
                                <b>Продаж: </b>
                                <br /><span className="badge bg-primary text-white">{Object.keys(copyListSales).length}</span>
                            </div>
                            <div  className="col-md-2">
                                <b>На сумму:
                                <br /></b> <span className="badge bg-primary text-white">{formatNumberWithCommas(allSummSales)}</span>
                            </div>
                            <div  className="col-md-2">
                                <b>Наличным способом: </b>
                                <br /><span className="badge bg-primary text-white">{countNalichType}</span>
                            </div>
                            <div  className="col-md-2">
                                <b>Безналичным способом: </b>
                                <br /><span className="badge bg-primary text-white">{countBeznalichType}</span>
                            </div>
                            <div  className="col-md-3">
                                <b>Комбинированным способом: </b>
                                <br /><span className="badge bg-primary text-white">{countCombinirType}</span>
                            </div>
                        </div>
                        <table className="border-none">
                            <thead>
                            <tr>
                                <th scope="col">Дата продажи</th>
                                <th scope="col">Номер чека</th>
                                {
                                    (props.type === "shop")
                                        ? <th scope="col">Продавец</th>
                                        : false
                                }
                                <th scope="col">Количество запчастей</th>
                                <th scope="col">Сумма продажи (с учетом скидки)</th>
                                <th scope="col">Скидка</th>
                                <th scope="col">Тип оплаты</th>
                                <th scope="col">Количество возвратов</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(copyListSales).map((index, i) => {
                                    let style = (copyListSales[index].count_return_part === objectSize(copyListSales[index].partFromSales)) ? "bg-danger-subtle" : (copyListSales[index].count_return_part > 0) ? "bg-secondary-subtle" : "bg-success-subtle";
                                    return <tr className={style} key={i}>
                                        <td>{convertDateFormat(copyListSales[index].datetime_seles)}</td>

                                        <td scope="row" className="text-decoration-underline text-center" style={{cursor: "pointer"}} onClick={(event) => {
                                            setCurrentSalesCardId(index);
                                        }} modal-open="true" modal-id="modalviewSaleDetail">Чек № {copyListSales[index].id}</td>

                                        {
                                            (props.type === "shop")
                                                ? <td>{copyListSales[index].salesman_info['fio']}</td>
                                                : false
                                        }

                                        <td className="text-center">{objectSize(copyListSales[index].partFromSales)} ед.</td>
                                        <td className="text-center">{formatNumberWithCommas(copyListSales[index].all_summ_by)}</td>
                                        <td className="text-center">{
                                            (copyListSales[index].discount)
                                                 ?   (copyListSales[index].discount.indexOf("%"))
                                                        ? <div>
                                                            <b>{copyListSales[index].discount}</b>
                                                            <br /><span>Сумма скидки: {formatNumberWithCommas(copyListSales[index].summ_discount)}</span>
                                                        </div>
                                                        : <span>Сумма скидки: {formatNumberWithCommas(copyListSales[index].summ_discount)}</span>
                                                :   <span>{formatNumberWithCommas(0)}</span>
                                        }</td>
                                        <td className="text-center">{
                                            (copyListSales[index].type_pay === 1)
                                                ? <b>Наличный</b>
                                                : (copyListSales[index].type_pay === 2)
                                                    ? <b>Безналичный</b>
                                                    : <div>
                                                        <b>Комбинированный</b>
                                                        <br/><span>Наличным способом: {formatNumberWithCommas(copyListSales[index].nalich_summ)}</span>
                                                        <br/><span>Безналичным способом: {formatNumberWithCommas(copyListSales[index].beznalich_summ)}</span>
                                                    </div>
                                        }</td>
                                        <td className="text-center">
                                            {copyListSales[index].count_return_part} ед.
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                        <div className="mt-2">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    {
                                        page
                                    }
                                </ul>
                            </nav>
                        </div>
                    <SallesModalViewSales sale={copyListSales[currentSalesCardId]} />
                    </div>
                    : <p className="alert alert-danger">Продаж нет.</p>
            }
        </div>
    );
};

export default TableSales;
