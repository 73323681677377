import React from 'react';
import wordByIndex, {closeModalWindow} from "../../../utility/Utility";
import SendForm from "../../../utility/SendForm";
import PhoneInput from "../../../components/PhoneInput/PhoneInput";
import Modal2 from "../../../components/Modal/Modal2";
import {toast} from "react-toastify";

const AdminModalChangeDataUserShop = (props) => {
    return (
        <Modal2 title="Изменение данных сотрудника" id={"EditDataUserShop_" + props.id}>
            <form onSubmit={(event) => SendForm(event, (data) => {
                if (data['success']){
                    if (data['users'])
                        props.setShopUsers(data.users);
                    toast.success(data['success']);
                    closeModalWindow("EditDataUserShop_" + props.id);
                }
            }, true, false)} action="editDataUserShop" id={"formEditDataUserShop" + props.id} method="GET" className="needs-validation" noValidate>
                <div>
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">Адрес электронной почты</label>
                        <input defaultValue={props.userData.mail} name="mail" type="text" className="form-control" placeholder="Введите адрес электронной почты" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <input type="text" className="d-none" defaultValue={props.userData.id} name="id_user" />
                    <input type="text" className="d-none" defaultValue={props.id_shop} name="id_shop" />
                    <div className="mt-2">
                        <label htmlFor="validationCustom05" className="form-label">Номер телефона</label>
                        <PhoneInput defaultValue={props.userData.phone} name="phone" id={"changeUserDataPhone_" + props.userData.id} />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div>
                        <label htmlFor="validationCustom06" className="form-label">Фамилия</label>
                        <input defaultValue={wordByIndex(props.userData.fio, 1)} name="lastName" type="text" className="form-control" placeholder="Введите фамилию" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom07" className="form-label">Имя</label>
                        <input defaultValue={wordByIndex(props.userData.fio, 2)} name="Name" type="text" className="form-control" placeholder="Введите имя" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom08" className="form-label">Отчество</label>
                        <input defaultValue={wordByIndex(props.userData.fio, 3)} name="middleName" type="text" className="form-control" placeholder="Отчество" />
                    </div>
                </div>
                <div id="selectDolznostSuccess" className="valid-feedback">
                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                </div>
                <div id="selectDolznostError" className="invalid-feedback">
                    Поле обязательно к заполнению
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Изменить данные" className="btn btn-success mt-3" />
            </form>
        </Modal2>
    );
};

export default AdminModalChangeDataUserShop;