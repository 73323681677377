import React, {useEffect, useState} from 'react';
import apiRequest from "../../../utility/ApiRequest";
import LoadingIcon from "../../../components/LoadingIcon/LoadingIcon";
import {toast} from "react-toastify";
import {CallPrint, objectSize} from "../../../utility/Utility";
import ModalRemontPart from "./ModalPart/ModalRemontPart";
import ModalEditPart from "./ModalPart/ModalEditPart";
import ModalSendPartTheOtherShop from "./ModalPart/ModalSendPartTheOtherShop";
import ModalPrintBarcode from "./ModalPart/ModalPrintBarcode";
import ModalBooking from "./ModalPart/ModalBooking";
import ModalAddNewPart from "./ModalPart/ModalAddNewPart";
import ModalAddNewPartFromFile from "./ModalPart/ModalAddNewPartFromFile";
import TablePart from "../../../components/TablePart/TablePart";
import Modal2 from "../../../components/Modal/Modal2";
import ModalPublicPartSite from "./ModalPart/ModalPublicPartSite";

const AdminModalCarPart = (props) => {

    const [carParts, setCarParts] = useState(false);
    const [loadPart, setLoadParts] = useState(false);
    const [focusPartID, setFocusPartID] = useState(0);

    const deletePartServer = (id_shop, id_car, id_part) => {
        apiRequest("deleteCarPart?", "id_shop=" + id_shop + "&id_car=" + id_car + "&id_part=" + id_part).then((data) => {
           if (data['success']){

               if (data['parts']['success'])
                   setCarParts(data['parts']['parts']);
               else
                   if (data['parts']['error'])
                       setCarParts(false);
               toast.success(data['success']);

               return true;
           } else {
               toast.error(data['error']);
           }
        });
    }
    const deleteBookingPartServer = (id_shop, id_car, id_part, id_booking) => {
        apiRequest("deleteBookingPart?", "id_shop=" + id_shop + "&id_car=" + id_car + "&id_part=" + id_part + "&id_booking=" + id_booking, "POST").then((data) => {
            if (data['success']){

                if (data['parts']['success'])
                    setCarParts(data['parts']['parts']);
                else
                if (data['parts']['error'])
                    setCarParts(false);
                toast.success(data['success']);

                return true;
            } else {
                toast.error(data['error']);
            }
        });
    }
    const deleteRemontPartServer = (id_shop, id_car, id_part, id_remont) => {
        apiRequest("deleteRemontPart?", "id_shop=" + id_shop + "&id_car=" + id_car + "&id_part=" + id_part + "&id_remont=" + id_remont, "POST").then((data) => {
            if (data['success']){

                if (data['parts']['success'])
                    setCarParts(data['parts']['parts']);
                else
                if (data['parts']['error'])
                    setCarParts(false);
                toast.success(data['success']);
                return true;
            } else {
                toast.error(data['error']);
            }
        });
    }
    const setPartRemontRabota = (part_id) => {
        apiRequest("setPartRemontRabota?", "type_get_part=cars&id_part=" + part_id, "POST").then((data) => {
            if (data['success']){
                if (data['parts']['success'])
                    setCarParts(data['parts']['parts']);
                toast.success(data['success']);
                return true;
            } else {
                toast.error(data['error']);
            }
        });
    }

    useEffect(() => {
        if (objectSize(props.carData)) {
            setLoadParts(false);
            setCarParts(false);
            apiRequest("getAllPartCar?", "id_shop=" + props.id_shop + "&id_car=" + props.carData.id).then((data) => {
                if (data['success']) {
                    setCarParts(data['parts']);
                    setFocusPartID(0);
                }
                setLoadParts(true)
            });
        }
    }, [props.carData]);

    return (
        <Modal2 size="modal-fullscreen" title="Просмотр запчастей" id="viewPartsCar" overflow_hidden="true">
            {
                (objectSize(props.carData))
                        ? <div className="row">
                            <div className="col-md-2">
                                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                        {
                                            (props.carData.images)
                                                ? props.carData.images.map((image, key) => {
                                                    if (key === 0)
                                                        return (
                                                            <div className="carousel-item active" key={key}>
                                                                <img className="d-block w-100" src={image} alt="" />
                                                            </div>
                                                        )
                                                    else
                                                        return (
                                                            <div className="carousel-item" key={key}>
                                                                <img className="d-block w-100" src={image} alt="" />
                                                            </div>
                                                        )
                                                })
                                                : false
                                        }
                                    </div>
                                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button"
                                       data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Назад</span>
                                    </a>
                                    <a className="carousel-control-next" href="#carouselExampleControls" role="button"
                                       data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="sr-only">Вперед</span>
                                    </a>
                                </div>
                                <div className="card mt-2">
                                    <div className="card-header bg-success text-white">
                                        Информация
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">{props.carData.marka['name']} {props.carData.model}, {props.carData.date_create} / {props.carData.dop_parametr}</li>
                                        <li className="list-group-item">Vin номер: {props.carData.vinnomer}</li>
                                        <li className="list-group-item">Гос номер: {props.carData.gosnomer}</li>
                                        <li className="list-group-item">Дата покупки: {props.carData.date_add}</li>
                                        <li className="list-group-item">Цена покупки: {props.carData.price} руб.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="d-flex flex-column w-100">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <button className="btn btn-sm btn-primary w-100" modal-open="true" modal-id="addNewPartModal">+ Добавить запчасть</button>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="dropdown w-100">
                                                <button className="btn btn-success btn-sm dropdown-toggle w-100" type="button"
                                                        id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                    Действия
                                                </button>
                                                <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                                                    <button className="dropdown-item" modal-open="true" modal-id="addNewPartFromFileModal">Пакетное добавление запчастей</button>
                                                    <button className="dropdown-item" onClick={(event) => CallPrint("all_barcode_container")}>Печать всех штрихкодов</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ModalAddNewPart sp_parts={props.sp_parts} id_car={props.carData.id} id_shop={props.id_shop} setCarParts={setCarParts} setLoadParts={setLoadParts} />
                                    <ModalAddNewPartFromFile id_car={props.carData.id} id_shop={props.id_shop} setCarParts={setCarParts} sp_cars={props.sp_cars} sp_parts={props.sp_parts} />
                                </div>
                                <hr />
                                {
                                    (objectSize(carParts))
                                        ? <div style={{maxHeight: "80vh", minHeight: "80vh", overflowY: "auto",overflowX: "hidden"}}>
                                            <TablePart sp_parts={props.sp_parts} sp_cars={props.sp_cars} partCardId="PartCard2" setPartRemontRabota={setPartRemontRabota} id_shop={props.id_shop} typeModal="" shopParts={carParts} setFocusPartID={setFocusPartID} deletePartServer={deletePartServer} />

                                            <ModalRemontPart deleteRemontPartServer={deleteRemontPartServer} setFocusPartID={setFocusPartID} setCarParts={setCarParts} id_shop={props.id_shop} id_car={props.carData.id} editPartData={carParts[focusPartID]} />
                                            <ModalEditPart sp_cars={props.sp_cars} setFocusPartID={setFocusPartID} sp_parts={props.sp_parts} setCarParts={setCarParts} id_shop={props.id_shop} id_car={props.carData.id} editPartData={carParts[focusPartID]} />
                                            <ModalSendPartTheOtherShop sp_shops={props.sp_shops} setFocusPartID={setFocusPartID} sp_parts={props.sp_parts} setCarParts={setCarParts} id_shop={props.id_shop} id_car={props.carData.id} editPartData={carParts[focusPartID]} />
                                            <ModalBooking deleteBookingPartServer={deleteBookingPartServer} setFocusPartID={setFocusPartID} setCarParts={setCarParts} id_shop={props.id_shop} id_car={props.carData.id} editPartData={carParts[focusPartID]} />
                                            <ModalPrintBarcode editPartData={carParts[focusPartID]} />
                                            <ModalPublicPartSite parts={carParts} setParts={setCarParts} id_shop={props.id_shop} editPartData={carParts[focusPartID]} />

                                            <div id="all_barcode_container" className="d-flex d-none p-2">
                                                <h1>Штрих коды запчастей от {props.carData.marka['name']} {props.carData.model}, {props.carData.date_create}</h1>
                                                <div className="mt-3"></div>
                                                {
                                                    Object.keys(carParts).map((index, i) => {
                                                        return <div className="mt-3" key={i}>
                                                            <img src={carParts[index].barcode_img} alt="" />
                                                            <br /> {carParts[index].val_type_part}
                                                            <hr />
                                                        </div>;
                                                    })
                                                }
                                            </div>

                                        </div>
                                        : (!loadPart)
                                                ? <LoadingIcon />
                                                : <p className="alert alert-danger">У автомобиля нет запчастей</p>
                                }
                            </div>
                        </div>
                    : <div className={"alert alert-danger"}>Извините, данные автомобиля не найдены.</div>
            }


        </Modal2>
    );
};

export default AdminModalCarPart;