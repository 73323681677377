import React, {useEffect, useState} from 'react';
import {closeModalWindow, formatNumberWithCommas, objectSize} from "../../utility/Utility";
import PartCard from "../PartCard/PartCard";
import Select from "react-select";
import apiRequest from "../../utility/ApiRequest";
import {exitAccount} from "../../utility/Policy";
import {toast} from "react-toastify";

const TablePart = (props) => {

    const [currentPartCardId, setCurrentPartCardId] = useState(false);
    const [copyPartList, setCopyPartList] = useState(props.shopParts);

    const [pMarka, setPMarka] = useState("");
    const [pModel, setPModel] = useState("");
    const [pTypePart, setPTypePart] = useState("");
    const [pStatusPart, setPStatusPart] = useState("");
    const [pBooking, setPBooking] = useState("");
    const [pDateBy, setPDateBy] = useState("");
    const [pSort, setPSort] = useState("");

    const setFocusPartID = (id) => {
        Object.keys(props.shopParts).map((index, i) => {
            if (props.shopParts[index].id === id)
                props.setFocusPartID(i);
        });
    }

    useEffect(() => {
        setCopyPartList(props.shopParts);
        setCurrentPartCardId(false);
        const filteredData = props.shopParts.filter(item => {
            const markaFilter = pMarka !== "" ? parseInt(item.id_marka) === parseInt(pMarka) : true;
            const modelFilter = pModel !== "" ? item.model.includes(pModel) : true;
            const typePartFiltel = pTypePart !== "" ? item.id_type === pTypePart : true;
            const statusPartFilter = pStatusPart !== "" ? parseInt(item.status_part) === parseInt(pStatusPart) : true;
            const bookingFilter = pBooking !== "" ? parseInt(item.count_booking) === parseInt(pBooking) : true;
            const dateBy = pDateBy !== "" ? item.date_shop.includes(pDateBy) : true;

            let sort = true;
            if (pSort === "1")
                sort = (parseInt(item.id_car) === 0 && parseInt(item.cost_price) === 0) ? true : false;
            else if (pSort === "2")
                sort = (parseInt(item.id_car) === 0 && parseInt(item.cost_price) > 0) ? true : false;
            // Возвращаем true только если все активные фильтры соответствуют элементу
            return markaFilter && modelFilter && typePartFiltel && statusPartFilter && bookingFilter && dateBy && sort;
        });
        setCopyPartList(filteredData);
    }, [props.shopParts, pMarka, pModel, pTypePart, pStatusPart, pBooking, pDateBy, pSort]);

    const setNotActivAds = (part_id) => {
        apiRequest("setNotActivAds?", "part_id=" + part_id, "POST").then((data) => {
            if (data['success']){
                toast.success(data['success']);

                let updatedArr = copyPartList.map(item =>
                    item.id === data['partData']['id'] ? data['partData'] : item
                );

                setCopyPartList(updatedArr);
            } else
                toast.error(data['error']);
        });
    }

    return (
        <div key={props.partCardId + "343434"}>
            <div className="row mb-3">
                    {
                        (props.typeFilterParam === "all")
                            ? <div className="col-md-2"><Select
                                options={props.sp_cars}
                                placeholder="Марка"
                                isSearchable="true"
                                onChange={(event) => {
                                    setPMarka(event.value);
                                }}
                            /></div>
                            : false
                    }
                    {
                        (props.typeFilterParam === "all")
                            ? <div className="col-md-2"><input type="text" className="form-control" onChange={(event) => {
                                    setPModel(event.target.value);
                                }} name="model" placeholder="Модель" required />
                            </div>
                            : false
                    }

                <div className={(props.typeFilterParam === "all") ? "col-md-2" : "col-md-5"}>
                    <Select
                        options={props.sp_parts}
                        placeholder="Тип запчасти"
                        isSearchable={true}
                        onChange={(event) => {
                            setPTypePart(event.value);
                        }}
                    />
                </div>
                <div className="col-md-1">
                    <input type="text" className="form-control" placeholder="Год покуп." required onChange={(event) => {
                        if (event.target.value !== "")
                            setPDateBy(event.target.value);
                    }} />
                </div>
                <div className={(props.typeFilterParam === "all") ? "col-md-1" : "col-md-2"}>
                    <select name="status_part" className="form-select" onChange={(event) => {
                        if(event.target.value !== "")
                            setPStatusPart(parseInt(event.target.value));
                    }} required="true">
                        <option value="">Состояние</option>
                        <option value="1">Б/У</option>
                        <option value="3">Б/У c дефектом</option>
                        <option value="2">Новое</option>
                    </select>
                </div>
                <div className="col-md-1">
                    <select name="status_part2" onChange={(event) => {
                        if(event.target.value !== "")
                            setPBooking(event.target.value)
                    }} className="form-select" required="true">
                        <option value="">Бронь</option>
                        <option value="1">Да</option>
                        <option value="0">Нет</option>
                    </select>
                </div>
                {
                    (props.typeFilterParam === "all")
                        ? <div className="col-md-2">
                            <select name="status_part2" onChange={(event) => {
                                if (event.target.value !== "")
                                    setPSort(event.target.value)
                            }} className="form-select" required="true">
                                <option value="">Сортировка</option>
                                <option value="1">Не учтеное</option>
                                <option value="2">На продажу</option>
                            </select>
                        </div>
                        :
                        false
                }
                <div className="col-md-1">
                    <button className="btn btn-danger w-100 h-100" style={{marginLeft: 5}} onClick={(event) => {
                        setPMarka("");
                        setPModel("");
                        setPTypePart("");
                        setPStatusPart("");
                        setPBooking("");
                        setPDateBy("");
                        setCopyPartList(props.shopParts);
                        setCurrentPartCardId(false);
                    }}><i className="bi bi-funnel-fill"></i></button>
                </div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col"></th>
                        <th scope="col">Автомобиль</th>
                        <th scope="col">Тип запчасти</th>
                        <th scope="col">Кол. на складе</th>
                        <th scope="col">Бронь</th>
                        <th scope="col">Сост.</th>
                        <th scope="col">Цена закупки</th>
                        <th scope="col">Себест.</th>
                        <th scope="col">Наценка</th>
                        <th scope="col">Цена продажи</th>
                        <th scope="col">Действия</th>
                    </tr>
                </thead>
                <tbody>
                {
                    (objectSize(copyPartList))
                        ? Object.keys(copyPartList).map((index, i) => {
                            return <tr key={i+1}>
                                <th scope="row">{i+1}</th>
                                <td>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        {
                                            (copyPartList[index].count)
                                                ? <button className="btn btn-sm btn-success mt-1" data-toggle="tooltip" data-placement="top" title="Есть в наличии"></button>
                                                : <button className="btn btn-sm btn-danger  mt-1" data-toggle="tooltip" data-placement="top" title="Нет в наличии"></button>
                                        }

                                        {
                                            (copyPartList[index].is_sends)
                                                ? <button className="btn btn-sm btn-warning  mt-1" data-toggle="tooltip" data-placement="top" title="Отправлен запрос на перемещение"></button>
                                                : false
                                        }

                                        {
                                            (objectSize(copyPartList[index].booking))
                                                ? <button className="btn btn-sm btn-primary  mt-1" data-toggle="tooltip" data-placement="top" title="Есть бронь на запчасть"></button>
                                                : false
                                        }

                                        {
                                            (copyPartList[index].is_remont)
                                                ? <button className="btn btn-sm btn-secondary mt-1" data-toggle="tooltip" data-placement="top" title="В ремонте"></button>
                                                : false
                                        }
                                    </div>
                                </td>
                                <td>{copyPartList[index].marka_name}, {copyPartList[index].model}</td>
                                <td className="text-decoration-underline" style={{cursor: "pointer",minWidth: 200}} modal-open="true" modal-id={props.partCardId} data-toggle="tooltip" data-placement="top" title={copyPartList[index].val_type_part}
                                onClick={(event) => {
                                    setCurrentPartCardId(index);
                                }}>
                                    {copyPartList[index].val_type_part}
                                    {
                                        (copyPartList[index].dop_param_parts)
                                            ? " / " + copyPartList[index].dop_param_parts
                                            : false
                                    }
                                </td>
                                <td>{copyPartList[index].count} {copyPartList[index].edinica_type}</td>
                                <td>{copyPartList[index].count_booking} {copyPartList[index].edinica_type}</td>
                                <td>{
                                    (copyPartList[index].status_part === 1)
                                        ? "Б/У"
                                        : (copyPartList[index].status_part === 2)
                                            ? "Новое"
                                            : "Б/У c дефектом"
                                }</td>
                                <td>{formatNumberWithCommas(copyPartList[index].cost_price)}</td>
                                <td>{formatNumberWithCommas(copyPartList[index].sebestoimost)}</td>
                                <td>{formatNumberWithCommas(copyPartList[index].price)}</td>
                                <td>{formatNumberWithCommas(copyPartList[index].price_by)}</td>
                                <td>
                                    {
                                        (copyPartList[index].is_sends === 0)
                                            ? <div>
                                                <div className="dropdown dropstart">
                                                    <button className="btn btn-дшпре dropdown-toggle" type="button"
                                                            id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                        Действия
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        {
                                                            (copyPartList[index].ads_id)
                                                                ? <button className="dropdown-item" onClick={(event) => setNotActivAds(copyPartList[index].id)}>Снять с публикации</button>
                                                                : <button className="dropdown-item" modal-open="true" modal-id="publicPartSite" onClick={(event) => setFocusPartID(copyPartList[index].id)}>Опубликовать на сайте</button>

                                                        }
                                                        <button className="dropdown-item" modal-open="true" modal-id={"remontPartModal" + props.typeModal} onClick={(event) => setFocusPartID(copyPartList[index].id)}>Ремонтные работы</button>
                                                        <button className="dropdown-item" modal-open="true" modal-id={"SendPartTheOtherShop" + props.typeModal} onClick={(event) => setFocusPartID(copyPartList[index].id)}>Переместить в магазин</button>
                                                        <button className="dropdown-item" modal-open="true" modal-id={"ModalBooking" + props.typeModal} onClick={(event) => setFocusPartID(copyPartList[index].id)}>Бронирование</button>
                                                        <button className="dropdown-item" modal-open="true" modal-id={"printBarcode" + props.typeModal} onClick={(event) => setFocusPartID(copyPartList[index].id)}>Печать штрихкода</button>
                                                        {
                                                            (copyPartList[index].is_remont)
                                                                ? false
                                                                : <button className="dropdown-item" onClick={(event) => props.setPartRemontRabota(copyPartList[index].id)}>Отправить на ремонт</button>
                                                        }
                                                        <button className="dropdown-item" modal-open="true" modal-id={"editPartModal" + props.typeModal} onClick={(event) => setFocusPartID(copyPartList[index].id)}>Редактировать</button>
                                                        {
                                                            (props.partCardId === "PartCard2")
                                                                ? <button className="dropdown-item" onClick={(event) => props.deletePartServer(props.id_shop, copyPartList[index].id_car, copyPartList[index].id)}>Удалить</button>
                                                                : <button className="dropdown-item" onClick={(event) => props.deletePartServer(props.id_shop, "", copyPartList[index].id)}>Удалить</button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            : <div>
                                                <div className="dropdown dropstart">
                                                    <button className="btn btn-дшпре dropdown-toggle" type="button"
                                                            id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                        Отправлен
                                                    </button>
                                                    <div className="dropdown-menu" style={{minWidth: 300}} aria-labelledby="dropdownMenuButton">
                                                        <b>Магазин:</b> {copyPartList[index].send_shop_info.name}
                                                        <br /><b>Адрес:</b> {copyPartList[index].send_shop_info.adress}
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </td>
                            </tr>
                        })
                        : false
                }
                </tbody>
            </table>
            {
                (currentPartCardId !== false)
                    ? <PartCard dataPart={copyPartList[currentPartCardId]} id={props.partCardId} />
                    : false
            }
        </div>
    );
};

export default TablePart;