import React, {useEffect} from 'react';
import sendForm, {formDefaultStatusAllFrom} from "../../../../utility/SendForm";
import {convertDateFormat, formatNumberWithCommas, getRandomNumber, objectSize} from "../../../../utility/Utility";
import {toast} from "react-toastify";
import PhoneInput from "../../../../components/PhoneInput/PhoneInput";
import Modal2 from "../../../../components/Modal/Modal2";

const ModalBooking = (props) => {

    const id_modal = (props.id_modal) ? props.id_modal : "editPartModal";

    const closeModal = () => {
        props.setFocusPartID(0);
        formDefaultStatusAllFrom();
    }

    useEffect(() => {
        let myModal = document.getElementById(id_modal);
        myModal.addEventListener("hide.bs.modal", closeModal);
        return () => {
            myModal.removeEventListener("hide.bs.modal", closeModal);
        }
    }, [props.editPartData]);

    return (
        <Modal2 size="modal-large2" title="Бронирование запчасти" id={(props.id_modal) ? props.id_modal : "ModalBooking"}>
            {
                (objectSize(props.editPartData))
                    ? <div>
                        {
                            (objectSize(props.editPartData.booking))
                                ? <div>
                                    <h5>Бронь на запчасть</h5>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">ФИО</th>
                                            <th scope="col">Номер Телефона</th>
                                            <th scope="col">Количество</th>
                                            <th scope="col">Предоплата</th>
                                            <th scope="col">Дата</th>
                                            <th scope="col">Действия</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            Object.keys(props.editPartData.booking).map((indexb, b) => {
                                                return <tr key={b}>
                                                    <th scope="row">{b+1}</th>
                                                    <td>{props.editPartData.booking[indexb].fio}</td>
                                                    <td>{props.editPartData.booking[indexb].phone}</td>
                                                    <td>{props.editPartData.booking[indexb].count} {props.editPartData.booking[indexb].edinica_type}</td>
                                                    <td>{formatNumberWithCommas(props.editPartData.booking[indexb].predoplata)}</td>
                                                    <td>{convertDateFormat(props.editPartData.booking[indexb].date)}</td>
                                                    <td>
                                                        <button className="dropdown-item" onClick={(event) => props.deleteBookingPartServer(props.id_shop, props.id_car, props.editPartData.id, props.editPartData.booking[indexb].id)}>Удалить</button>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                : false
                        }
                        <h5>Добавление брони</h5>
                        <form onSubmit={(event) => sendForm(event, (data) => {
                            if (data['success']){
                                    if (data['parts']['success'])
                                        props.setCarParts(data['parts']['parts']);
                                    else
                                    if (data['parts']['error'])
                                        props.setCarParts(false);

                                toast.success(data['success']);

                                formDefaultStatusAllFrom();
                            } else {
                                toast.error(data['error']);
                            }
                        }, true, true)} action="AddBookingPart" id={(props.id_form) ? props.id_form : "bookingPartCar"} method="POST"
                              className="needs-validation" noValidate key={"bookingPartCar" + props.editPartData.id}>

                            <input type="text" className="form-control d-none" name="id_shop" value={props.id_shop} />
                            <input type="text" className="form-control d-none" name="id_car" value={props.id_car} />
                            <input type="text" className="form-control d-none" name="id_part" value={props.editPartData.id} />

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Количество</label>
                                <div className="col-auto">
                                    <div className="input-group mb-2">
                                        <input type="number" className="form-control" name="count"
                                               placeholder="0" required />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">{props.editPartData.edinica_type}</div>
                                        </div>
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">ФИО</label>
                                <div className="col-auto">
                                    <div className="input-group mb-2">
                                        <input type="text" className="form-control" name="fio"
                                               placeholder="Иванов Иван Иванович" required />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Номер телефона</label>
                                <div className="col-auto">
                                    <div className="input-group mb-2">
                                        <PhoneInput name="phone" id={"addBookinPart_" + getRandomNumber(1000, 10000)} />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Предоплата</label>
                                <div className="col-auto">
                                    <div className="input-group mb-2">
                                        <input type="number" className="form-control" name="predoplat"
                                               placeholder="0"/>
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">руб.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-3 d-none">

                            </div>
                            <input type="submit" value="Добавить бронь" className="btn btn-success mt-3"/>
                        </form>
                    </div>
                    : false
            }
        </Modal2>
    );
};

export default ModalBooking;
