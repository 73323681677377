import React, {useState} from 'react';
import sendForm, {formDefaultStatus} from "../../../../utility/SendForm";
import Select from "react-select";
import ImgAds from "../../../../components/ImgFormPreview/ImgAds";
import {toast} from "react-toastify";
import Modal2 from "../../../../components/Modal/Modal2";
import {closeModalWindow} from "../../../../utility/Utility";
import $ from 'jquery';

let indexLastImage = 0;

const ModalAddNewPart = (props) => {

    const [typePartID, setTypePartID] = useState();
    const [adsAvtoMarka, setAdsAvtoMarka] = useState();
    const [displayImgAds, setdisplayImgAds] = useState([]);
    const [sendAddNewPart, setSendAddNewPart] = useState(false);
    const [dateshop, setDateShop] = useState("");

    const addImgAds = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImage++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAds([...arrImg]);
        event.target.value = null;
    }
    const deleteImgAds = (idImg) => {
        setdisplayImgAds(displayImgAds.filter(obj => {
            return obj['index'] !== idImg;
        }));
    }
    const addNewPart = (event) => {

        event.preventDefault();

        if (!sendAddNewPart){
            let files = [];
            for (let i = 0; i < displayImgAds.length; i++)
                files.push(displayImgAds[i]['file']);

            setSendAddNewPart(true);

            sendForm(event, (data) => {
                if (data['success']){
                    setdisplayImgAds([]);

                    if (data['parts']['success'])
                        props.setCarParts(data['parts']['parts']);

                    closeModalWindow((props.id_modal) ? props.id_modal : "addNewPartModal");
                    toast.success(data['success']);

                    if (props.setLoadParts)
                        props.setLoadParts(true);
                }
                setSendAddNewPart(false);
            }, true, true, true, files, () => {
                setSendAddNewPart(false);
            });
        }
    }

    return (
        <Modal2 size="modal-medium2" title="Добавление запчасти" id={(props.id_modal) ? props.id_modal : "addNewPartModal"}>
            <form onReset={(event) => formDefaultStatus(event)} onSubmit={(event) => addNewPart(event)} action="addNewPartCar" id={(props.id_form) ? props.id_form : "addNewPartCar_"} method="POST"
                  className="form needs-validation" noValidate>
                {
                    (props.showModelMarkaAvto)
                        ? <div>
                            <div className="form-group row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Марка автомобиля</label>
                                <div className="col-sm-10">
                                    <Select
                                        options={props.sp_cars}
                                        placeholder="Марка автомобиля"
                                        isSearchable="true"
                                        onChange={(event) => setAdsAvtoMarka(event.value)}
                                    />
                                    <input name="marka" type="text" defaultValue={adsAvtoMarka} className="d-none" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Модель</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="model" placeholder="" required />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                        : false
                }

                <div className="form-group row mt-3">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Тип</label>
                    <div className="col-sm-10">
                        <Select
                            options={props.sp_parts}
                            placeholder="Тип запчасти"
                            isSearchable={true}
                            onChange={(event) => setTypePartID(event.value)}
                        />
                        <input type="text" className="form-control d-none" name="typePart" placeholder="" defaultValue={typePartID} required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                        <input type="text" className="form-control mt-1" name="typePartOther" placeholder="Доп. параметры запчасти" />
                    </div>
                </div>

                <input type="text" className="form-control d-none" name="id_shop" defaultValue={props.id_shop} />
                <input type="text" className="form-control d-none" name="id_car" defaultValue={props.id_car} />

                <div className="row">
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">Закупочная цена</label>
                        <div className="form-group row mt-1">
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="cost_price"
                                           placeholder="" required />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">руб.</div>
                                    </div>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">Стоимость доставки</label>
                        <div className="form-group row mt-1">
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="delivery_price"
                                           placeholder="" />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">руб.</div>
                                    </div>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">Наценка</label>
                        <div className="form-group row mt-1">
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="price"
                                           placeholder="" required />
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">руб.</div>
                                    </div>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">Количество</label>
                        <div className="form-group row mt-1">
                            <div className="w-100">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="count"
                                           placeholder="" required />
                                    <div className="input-group-prepend p-0">
                                        <div className="input-group-text  p-0">
                                            <select name="edinica_name" className="form-select" required="true">
                                                <option value="1">шт.</option>
                                                <option value="2">пар.</option>
                                                <option value="3">комп.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group row mt-1">
                            <label htmlFor="inputPassword" className="col-form-label">Состояние</label>
                            <div className="w-100">
                                <div className="input-group mb-2">
                                    <select name="status_part" className="form-select" required="true" onChange={(event) => {
                                        if (event.target.value === "3")
                                            $("#comment_input" + props.id_modal).attr('required', 'true');
                                        else {
                                            $("#comment_input" + props.id_modal).removeAttr('required');
                                            $("#comment_input" + props.id_modal).removeClass('is-invalid');
                                        }
                                    }}>
                                        <option value=""></option>
                                        <option value="1">Б/У</option>
                                        <option value="3">Б/У c дефектом</option>
                                        <option value="2">Новое</option>
                                    </select>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        {
                            (props.showModelMarkaAvto)
                                ? <div>
                                    <label htmlFor="inputPassword" className="col-form-label">Дата закупки</label>
                                    <div className="form-group row mt-1">
                                        <div className="col-sm-auto">
                                            <input type="date" className="form-control" placeholder="" required onChange={(event) => setDateShop(event.target.value)} />
                                            <input type="text" className="form-control d-none" name="date_shop" defaultValue={dateshop} placeholder="" />
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : false
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">№ стелажа</label>
                        <div className="form-group row mt-1">
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="row"
                                           placeholder="" />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">№ полки</label>
                        <div className="form-group row mt-1">
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="shelf"
                                           placeholder="" />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <label htmlFor="inputPassword" className="col-form-label">Место</label>
                        <div className="form-group row mt-1">
                            <div className="col-auto">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control" name="place"
                                           placeholder="" />
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="is_remont" value="1" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Запчасть находится на ремонте
                    </label>
                </div>

                <div className="form-group row mt-3">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Комментарии</label>
                    <div className="col-sm-10">
                        <textarea rows="5" name="comment" id={"comment_input" + props.id_modal} className="form-control">

                        </textarea>
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>

                <hr />

                <div className="mt-3 w-100">
                    <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                        {
                            (displayImgAds.length)
                                ? displayImgAds.map((image, key) => {
                                    return (
                                        <ImgAds src={image['url']} id_img={image.index} deleteImage={deleteImgAds} key={key} />
                                    )
                                })
                                : false
                        }
                        <label className="mt-2">
                            <div className="imagebutton">
                                <i className="bi bi-camera"></i>
                            </div>
                            <input type="file" multiple="true" onChange={(event) => addImgAds(event)} hidden />
                        </label>
                    </div>
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Добавить запчасть" className="btn btn-success mt-3"/>
            </form>
        </Modal2>
    );
};

export default ModalAddNewPart;
