import React from 'react';
import AdminModalShopStatistic from "./AdminShopsCardPage/AdminModalShopStatistic";

const AdminStatisticPage = (props) => {
    return (
        <div>
            <h2><i className="bi bi-graph-up-arrow"></i> Общая статистика по всем магазинам</h2>
            <hr />
            <AdminModalShopStatistic setShop={false} shopData={props.mainData} />
        </div>
    );
};

export default AdminStatisticPage;
