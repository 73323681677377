import React, {useEffect, useState} from 'react';
import {closeModalWindow, formatNumberWithCommas, objectSize, parseRemontWork} from "../../../../utility/Utility";
import Modal2 from "../../../../components/Modal/Modal2";
import sendForm from "../../../../utility/SendForm";
import {toast} from "react-toastify";

const ModalEvakuatorViewRemontRabota = (props) => {
    const [remontWork, setRemontWork] = useState(false);

    useEffect(() => {
        if (props.remontRabotaData) {
            setRemontWork(parseRemontWork(props.remontRabotaData.description));
        }
    }, [props.remontRabotaData]);

    return (
        (props.remontRabotaData)
            ? <Modal2 size="modal-medium2" title="Редактирование ремонтных работ" id="ModalViewRemontRabot">
                <table className="mt-2">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Выполненная работа</th>
                        <th scope="col">Стоимость</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (objectSize(remontWork))
                            ? remontWork.map((elem, i) => {
                                return <tr>
                                    <td>{i+1}</td>
                                    <td>{elem.type}</td>
                                    <td>{formatNumberWithCommas(elem.sum)}</td>
                                </tr>
                            })
                            : false
                    }
                    </tbody>
                </table>
            </Modal2>
            : false
    );
};

export default ModalEvakuatorViewRemontRabota;
