import React, {useState} from 'react';
import sendForm from "../../../utility/SendForm";
import {formatNumberWithCommas, objectSize} from "../../../utility/Utility";
import { Chart } from 'react-google-charts';
import {toast} from "react-toastify";
import $ from 'jquery';
import LoadingIcon from "../../../components/LoadingIcon/LoadingIcon";

const AdminModalShopStatistic = (props) => {

    const [statistica, setStatistica] = useState(false);
    const [loadingStatistica, setLoadingStatistica] = useState("Выберите период и нажмите кнопку.");

    return (
        <div>
            <form onSubmit={(event) => sendForm(event, (data) => {
                console.log(data);
                if (data['success']){
                    setLoadingStatistica("");
                    setStatistica(data['statistica']);
                    toast.success(data['success']);
                }

                if (data['error'])
                    toast.error(data['error']);
            }, false, false, false, [], ()=>{}, () => {
                setLoadingStatistica("Пожалуйста, подождите, идет генерация.");
                setStatistica(false);
            })} action="getStatistic" id="formGetStatistic" method="POST"
                  className="needs-validation" noValidate>

                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            {
                                (props.setShop)
                                    ? <input type="text" className="form-control d-none" name="id_shop" value={props.shopData.id} />
                                    : false
                            }
                            <select name="period" className="form-select" required="true" onChange={(event) => {
                                $(".dop_param").addClass("d-none");
                                if (event.target.value === "year")
                                    $("#st_fo_inp_year").removeClass("d-none");
                                if (event.target.value === "month")
                                    $("#st_fo_inp_month").removeClass("d-none");
                                if (event.target.value === "kvartal")
                                    $("#st_fo_inp_kvartal").removeClass("d-none");
                            }}>
                                <option value="">Выберите период</option>
                                <option value="day">День</option>
                                <option value="month">Месяц</option>
                                <option value="kvartal">Квартал</option>
                                <option value="year">Год</option>
                            </select>
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <input type="number" id="st_fo_inp_year" className="form-control d-none dop_param" name="year" placeholder="Введите год" />
                        <select id="st_fo_inp_kvartal" className="form-select d-none dop_param" name="number_kvartal">
                            <option value="">Выберите квартал</option>
                            <option value="1">Первый</option>
                            <option value="2">Второй</option>
                            <option value="3">Третий</option>
                            <option value="4">Четвертый</option>
                        </select>
                        <select id="st_fo_inp_month" className="form-select d-none dop_param" name="month">
                            <option value="">Выберите месяц</option>
                            <option value="1">Январь</option>
                            <option value="2">Февраль</option>
                            <option value="3">Март</option>
                            <option value="4">Апрель</option>
                            <option value="5">Май</option>
                            <option value="6">Июнь</option>
                            <option value="7">Июль</option>
                            <option value="8">Август</option>
                            <option value="9">Сентябрь</option>
                            <option value="10">Октябрь</option>
                            <option value="11">Ноябрь</option>
                            <option value="12">Декабрь</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <button className="btn btn-success w-100">Получить статистику</button>
                    </div>

                    <div className="alert alert-danger d-none mt-3">

                    </div>
                    <div className="alert alert-success mt-3 d-none">

                    </div>
                </div>
            </form>

            <div className="mt-2">
                {
                    (objectSize(statistica))
                        ? <div>
                            {
                                Object.keys(statistica).map((index, i) => {
                                    let elem = statistica[index];
                                    return <div key={i}>
                                        <h4>{elem.name}</h4>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <h5 className="mt-2">Общая информация</h5>
                                                <ul className="list-group">
                                                    <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                        <span>Затраты за период</span>
                                                        <span
                                                            className="badge bg-light text-dark rounded-pill">{formatNumberWithCommas(elem.all_expenses)}</span>
                                                    </li>
                                                    <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                        <span>Доход за период</span>
                                                        <span
                                                            className="badge bg-light text-dark rounded-pill">{formatNumberWithCommas(elem.all_dohod)}</span>
                                                    </li>
                                                    <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                        <span>Чистая прибыль за период</span>
                                                        <span
                                                            className="badge bg-light text-dark rounded-pill">{formatNumberWithCommas(elem.all_profit)}</span>
                                                    </li>
                                                    <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                        <span>Количество продаж</span>
                                                        <span
                                                            className="badge bg-light text-dark rounded-pill">{elem.count_sales_part}</span>
                                                    </li>
                                                    <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                        <span>Количество купленных автомобилей</span>
                                                        <span
                                                            className="badge bg-light text-dark rounded-pill">{elem.count_by_avto}</span>
                                                    </li>
                                                    <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                        <span>Количество автомобилей которые окупились</span>
                                                        <span
                                                            className="badge bg-light text-dark rounded-pill">{elem.payback_cars}</span>
                                                    </li>
                                                    <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                        <span>Количество автомобилей которые не окупились</span>
                                                        <span
                                                            className="badge bg-light text-dark rounded-pill">{parseInt(elem.count_by_avto) - parseInt(elem.payback_cars)}</span>
                                                    </li>
                                                </ul>


                                                {
                                                    (objectSize(elem.profit_for_type))
                                                        ? <div>
                                                            <h5 className="mt-2">Прибыль по категориям</h5>
                                                            <table className="">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Название</th>
                                                                    <th scope="col">Кол. операций</th>
                                                                    <th scope="col">Прибыль</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    Object.keys(elem.profit_for_type).map((p_index, p_i) => {
                                                                        return <tr>
                                                                            <td scope="row">{p_i + 1}</td>
                                                                            <td scope="row">{elem.profit_for_type[p_index].name}</td>
                                                                            <td scope="row">{elem.profit_for_type[p_index].count}</td>
                                                                            <td scope="row">{formatNumberWithCommas(elem.profit_for_type[p_index].summ)}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        : false
                                                }

                                                {
                                                    (objectSize(elem.expenses_for_type))
                                                        ? <div>
                                                            <h5 className="mt-2">Затраты по категориям</h5>
                                                            <table className="">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Название</th>
                                                                    <th scope="col">Кол. операций</th>
                                                                    <th scope="col">Затраты</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    Object.keys(elem.expenses_for_type).map((p_index, p_i) => {
                                                                        return <tr>
                                                                            <td scope="row">{p_i + 1}</td>
                                                                            <td scope="row">{elem.expenses_for_type[p_index].name}</td>
                                                                            <td scope="row">{elem.expenses_for_type[p_index].count}</td>
                                                                            <td scope="row">{formatNumberWithCommas(elem.expenses_for_type[p_index].summ)}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        : false
                                                }

                                                {
                                                    (objectSize(elem.info_avakuator))
                                                        ? <div>
                                                            <h5 className="mt-2">Статистика эвакуаторов</h5>
                                                            <ul className="list-group">
                                                                <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                                    <span>Общий километраж</span>
                                                                    <span
                                                                        className="badge bg-light text-dark rounded-pill">{elem.info_avakuator.all_kilometraz} км.</span>
                                                                </li>
                                                                <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                                    <span>Чистая прибыль за период</span>
                                                                    <span
                                                                        className="badge bg-light text-dark rounded-pill">{formatNumberWithCommas(parseFloat(elem.info_avakuator.profit))}</span>
                                                                </li>
                                                                <li className="list-group-item stat-item d-flex justify-content-between align-items-center">
                                                                    <span>Затрат на ремонтные работы за период</span>
                                                                    <span
                                                                        className="badge bg-light text-dark rounded-pill">{
                                                                        (elem.info_avakuator.expenses)
                                                                            ? formatNumberWithCommas(parseFloat(elem.info_avakuator.expenses))
                                                                            : "0 руб."
                                                                    }</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        : false
                                                }

                                                {
                                                    (objectSize(elem.count_sales_salesman))
                                                        ? <div>
                                                            <h5 className="mt-2">Статистика продавцов</h5>
                                                            <table className="">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">ФИО</th>
                                                                    <th scope="col">Кол. продаж</th>
                                                                    <th scope="col">На сумму</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    Object.keys(elem.count_sales_salesman).map((p_index, p_i) => {
                                                                        return <tr>
                                                                            <td scope="row">{p_i + 1}</td>
                                                                            <td scope="row">{elem.count_sales_salesman[p_index].name}</td>
                                                                            <td scope="row">{elem.count_sales_salesman[p_index].count}</td>
                                                                            <td scope="row">{formatNumberWithCommas(elem.count_sales_salesman[p_index].all_summ)}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        : false
                                                }

                                                {
                                                    (objectSize(elem.count_disasembler_add_part))
                                                        ? <div>
                                                            <h5 className="mt-2">Статистика добавления запчастей</h5>
                                                            <table className="">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">ФИО</th>
                                                                    <th scope="col">Количество добавленных запчастей</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    Object.keys(elem.count_disasembler_add_part).map((p_index, p_i) => {
                                                                        return <tr>
                                                                            <td scope="row">{p_i + 1}</td>
                                                                            <td scope="row">{elem.count_disasembler_add_part[p_index].name}</td>
                                                                            <td scope="row">{elem.count_disasembler_add_part[p_index].count}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        : false
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                {
                                                    (elem.all_expenses && elem.all_dohod)
                                                        ? <Chart chartType="Bar"
                                                                 options={{
                                                                     chart: {
                                                                         title: "Доли затрат и прибыли из общего бюджета",
                                                                         subtitle: "Отображены данные по долям затрат и прибыли за представленный период",
                                                                     },
                                                                 }}
                                                                 loader={<LoadingIcon />}
                                                                 data={[
                                                                     ['Тип', 'Сумма'],
                                                                     ['Затраты', elem.all_expenses],
                                                                     ['Прибыль', elem.all_dohod],
                                                                 ]}
                                                                 width={'700px'}
                                                                 height={'320px'} />
                                                        : false
                                                }

                                                {
                                                    (objectSize(elem.profitBarData) > 1)
                                                        ? <Chart chartType="Bar"
                                                                 options={{
                                                                     chart: {
                                                                         title: "Доход по типу операций",
                                                                         subtitle: "Отображены данные о доходах по типу апераций за представленный период",
                                                                     }
                                                                 }}
                                                                 loader={<LoadingIcon />}
                                                                 data={elem.profitBarData}
                                                                 width={'700px'}
                                                                 height={'320px'} />
                                                        : false
                                                }

                                                {
                                                    (objectSize(elem.expensesBarData) > 1)
                                                        ? <Chart chartType="Bar"
                                                                 options={{
                                                                     chart: {
                                                                         title: "Расходы по типу операций",
                                                                         subtitle: "Отображены данные о затратах по типу апераций за представленный период",
                                                                     }
                                                                 }}
                                                                 loader={<LoadingIcon />}
                                                                 data={elem.expensesBarData}
                                                                 width={'700px'}
                                                                 height={'320px'} />
                                                        : false
                                                }

                                                {
                                                    (objectSize(elem.count_sales_part_the_day) > 1)
                                                        ? <Chart chartType="Bar"
                                                                 options={{
                                                                     chart: {
                                                                         title: "Доход по дням",
                                                                         subtitle: "Отображены данные о доходе по дням за заданный период",
                                                                     }
                                                                 }}
                                                                 loader={<LoadingIcon />}
                                                                 data={elem.count_sales_part_the_day}
                                                                 width={'700px'}
                                                                 height={'320px'} />
                                                        : false
                                                }

                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                })
                            }
                        </div>
                        : (loadingStatistica !== "")
                            ? <div className="alert alert-info">{loadingStatistica}</div>
                            : false
                }
            </div>
        </div>
    );
};

export default AdminModalShopStatistic;
