import React, {useEffect, useState} from 'react';
import {CallPrint, formatNumberWithCommas, objectSize} from "../../../utility/Utility";
import apiRequest from "../../../utility/ApiRequest";
import {toast} from "react-toastify";
import ModalRemontPart from "./ModalPart/ModalRemontPart";
import ModalEditPart from "./ModalPart/ModalEditPart";
import ModalSendPartTheOtherShop from "./ModalPart/ModalSendPartTheOtherShop";
import ModalBooking from "./ModalPart/ModalBooking";
import ModalPrintBarcode from "./ModalPart/ModalPrintBarcode";
import ModalAddNewPart from "./ModalPart/ModalAddNewPart";
import ModalAddNewPartFromFile from "./ModalPart/ModalAddNewPartFromFile";
import TablePart from "../../../components/TablePart/TablePart";
import ModalPublicPartSite from "./ModalPart/ModalPublicPartSite";

const AdminTableAllPartShop = (props) => {

    const [focusPartID, setFocusPartID] = useState(0);
    const [countPart, setCountPart] = useState(0);
    const [allSummPart, setAllSummPart] = useState(0);

    const deletePartServer = (id_shop, id_car, id_part) => {
        apiRequest("deleteCarPart?", "id_shop=" + id_shop + "&id_car=" + id_car + "&id_part=" + id_part).then((data) => {
            if (data['success']){
                if (data['parts']['success']){
                    props.setShopParts(data['parts']['parts']);
                    setFocusPartID(0);
                }
                toast.success(data['success']);
                return true;
            } else {
                toast.error(data['error']);
            }
        });
    }
    const deleteBookingPartServer = (id_shop, id_car, id_part, id_booking) => {
        apiRequest("deleteBookingPart?", "id_shop=" + id_shop + "&id_car=" + id_car + "&id_part=" + id_part + "&id_booking=" + id_booking, "POST").then((data) => {
            if (data['success']){

                if (data['parts']['success'])
                    props.setShopParts(data['parts']['parts']);

                toast.success(data['success']);

                return true;
            } else {
                toast.error(data['error']);
            }
        });
    }
    const deleteRemontPartServer = (id_shop, id_car, id_part, id_remont) => {
        apiRequest("deleteRemontPart?", "id_shop=" + id_shop + "&id_car=" + id_car + "&id_part=" + id_part + "&id_remont=" + id_remont, "POST").then((data) => {
            if (data['success']){

                if (data['parts']['success'])
                    props.setShopParts(data['parts']['parts']);

                toast.success(data['success']);
                return true;
            } else {
                toast.error(data['error']);
            }
        });
    }
    const setPartRemontRabota = (part_id) => {
        apiRequest("setPartRemontRabota?", "type_get_part=all&id_part=" + part_id, "POST").then((data) => {
            if (data['success']){

                if (data['parts']['success'])
                    props.setShopParts(data['parts']['parts']);

                toast.success(data['success']);
                return true;
            } else {
                toast.error(data['error']);
            }
        });
    }

    useEffect(() => {
        if (objectSize(props.shopParts) > 0){
            let allSummPart = 0;

            Object.keys(props.shopParts).map((index, i) => {
               allSummPart += parseFloat(props.shopParts[index].price_by);
            });

            setCountPart(objectSize(props.shopParts));
            setAllSummPart(allSummPart);
        }
    }, [props.shopParts]);

    return (
        <div key="2534320940329-093240923">
            <div className="row">
                <div className="col-md-2">
                    <button className="btn btn-primary w-100 btn-sm" modal-open="true" modal-id="addNewPartModal2">+ Добавить запчасть</button>
                </div>
                <div className="col-md-10">
                    <div className="dropdown w-100">
                        <button className="btn btn-success btn-sm dropdown-toggle w-100" type="button"
                                id="dropdownMenuButton" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                            Действия
                        </button>
                        <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                            <button className="dropdown-item" modal-open="true" modal-id="addNewPartFromFileModal2">Пакетное добавление запчастей</button>
                            <button className="dropdown-item" onClick={(event) => CallPrint("all_barcode_container2")}>Печать всех штрихкодов</button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAddNewPart showModelMarkaAvto="true" id_modal="addNewPartModal2" id_form="addNewPartCar_2" sp_parts={props.mainData.sp_parts} sp_cars={props.mainData.sp_cars} id_car="" id_shop={props.shopData.id} setCarParts={props.setShopParts} />
            <ModalAddNewPartFromFile id_modal="addNewPartFromFileModal2" id_form="addPacketAddPartCar2" id_car="" id_shop={props.shopData.id} setCarParts={props.setShopParts} sp_cars={props.mainData.sp_cars} sp_parts={props.mainData.sp_parts} style={{minHeight: 700,}} />
            <hr />
            <div className="alert alert-info">
                <b>Количество запчастей на складе: </b>{countPart}
                <br /><b>На общую сумму: </b>{formatNumberWithCommas(allSummPart)}
            </div>
            {
                (props.shopParts.length)
                    ? <div>
                        <TablePart typeFilterParam="all" sp_parts={props.mainData.sp_parts} sp_cars={props.mainData.sp_cars} partCardId="PartCard" setPartRemontRabota={setPartRemontRabota} id_shop={props.shopData.id} typeModal="2" shopParts={props.shopParts} setFocusPartID={setFocusPartID} deletePartServer={deletePartServer} />

                        <ModalRemontPart id_modal="remontPartModal2" id_form="addExpensesPart2" deleteRemontPartServer={deleteRemontPartServer} setFocusPartID={setFocusPartID} setCarParts={props.setShopParts} id_shop={props.shopData.id} id_car="" editPartData={props.shopParts[focusPartID]} expenses_arr={props.shopParts[focusPartID].expenses_arr} />
                        <ModalEditPart sp_cars={props.mainData.sp_cars} id_modal="editPartModal2" id_form="editDataPartCar2" setFocusPartID={setFocusPartID} sp_parts={props.mainData.sp_parts} setCarParts={props.setShopParts} id_shop={props.shopData.id} id_car="" editPartData={props.shopParts[focusPartID]} />
                        <ModalSendPartTheOtherShop id_modal="SendPartTheOtherShop2" id_form="sendPartOtherShop2" sp_shops={props.mainData.sp_shops} setFocusPartID={setFocusPartID} sp_cars={props.mainData.sp_cars} setCarParts={props.setShopParts} id_shop={props.shopData.id} id_car="" editPartData={props.shopParts[focusPartID]} />
                        <ModalBooking id_modal="ModalBooking2" id_form="bookingPartCar2" deleteBookingPartServer={deleteBookingPartServer} setFocusPartID={setFocusPartID} setCarParts={props.setShopParts} id_shop={props.shopData.id} id_car="" editPartData={props.shopParts[focusPartID]} />
                        <ModalPrintBarcode id_modal="printBarcode2" editPartData={props.shopParts[focusPartID]} />
                        <ModalPublicPartSite parts={props.shopParts} setParts={props.setShopParts} id_shop={props.shopData.id} editPartData={props.shopParts[focusPartID]} />

                        <div id="all_barcode_container2" className="d-flex d-none p-2">
                            <h1>Штрих коды всех имеющихся запчастей</h1>
                            <div className="mt-3"></div>
                            {
                                Object.keys(props.shopParts).map((index, i) => {
                                    return <div className="mt-3">
                                        <img src={props.shopParts[index].barcode_img} alt="" />
                                        <br /> {props.shopParts[index].val_type_part}
                                        <hr />
                                    </div>;
                                })
                            }
                        </div>
                    </div>
                    : <div className="alert alert-danger mt-2">
                        На складе пусто.
                    </div>
            }
        </div>
    );
};

export default AdminTableAllPartShop;
