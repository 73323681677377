import React, {useState} from 'react';
import PasswordInput from "../../../components/PaswordInput/PasswordInput";
import $ from 'jquery'
import sendForm from "../../../utility/SendForm";
import PhoneInput from "../../../components/PhoneInput/PhoneInput";
import Modal2 from "../../../components/Modal/Modal2";
import {closeModalWindow} from "../../../utility/Utility";

const AdminModalAddNewUser = (props) => {

    const [sendAddNewUser, setSendAddNewUser] = useState(false);

    const addNewUser = (event) => {
        event.preventDefault();

        if (!sendAddNewUser){
            if ($("input[type='checkbox']:checked").length > 0){
                $("#selectDolznostSuccess").addClass("d-block");
                $("#selectDolznostError").removeClass("d-block");
            } else {
                $("#selectDolznostSuccess").removeClass("d-block");
                $("#selectDolznostError").addClass("d-block");
            }
            setSendAddNewUser(true);
            sendForm(event, (data) => {
                if (data['success']){
                    if (data['users'])
                        props.setShopUsers(data.users);
                    closeModalWindow("addNewUserShop");
                }
                setSendAddNewUser(false);
            }, true, true, false, [], () => {
                setSendAddNewUser(false);
            });
        }
    }

    return (
        <Modal2 style_mainModal={{height: "auto"}} title="Добавление нового сотрудника" id="AddNewUserShop">
            <form onSubmit={(event) => addNewUser(event)} action="addNewUserShop" id="AddNewPersonalForm" method="GET" className="needs-validation" noValidate>
                <div>
                    <div className="">
                        <label htmlFor="validationCustom01" className="form-label">Логин</label>
                        <input name="login" id="validationCustom01" type="text" className="form-control" placeholder="Введите логин" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom02" className="form-label">Пароль</label>
                        <PasswordInput name="password" type="password" id="validationCustom02" className="form-control" placeholder="Введите пароль, не мение 8 символов" required />
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom03" className="form-label">Повторите пароль</label>
                        <PasswordInput name="repeatPassword" type="password" id="validationCustom03" className="form-control" placeholder="Повторите пароль" required />
                    </div>
                </div>

                <div className="mt-3">
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">Адрес электронной почты</label>
                        <input name="mail" type="text" id="validationCustom04" className="form-control" placeholder="Введите адрес электронной почты" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom05" className="form-label">Номер телефона</label>
                        <PhoneInput name="phone" id="addNewUserPhone" />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>
                <input name="id_shop" className="d-none" type="text" defaultValue={props.id_shop} />
                <div className="mt-3">
                    <div>
                        <label htmlFor="validationCustom06" className="form-label">Фамилия</label>
                        <input name="lastName" type="text" id="validationCustom06" className="form-control" placeholder="Введите фамилию" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom07" className="form-label">Имя</label>
                        <input name="Name" type="text" id="validationCustom07" className="form-control" placeholder="Введите имя" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom08" className="form-label">Отчество</label>
                        <input name="middleName" type="text"id="validationCustom08" className="form-control" placeholder="Отчество" />
                    </div>
                </div>
                <div className="form-check mt-2">
                    <input className="form-check-input" type="checkbox" name="manager" value="1" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Управляющий
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="salesman" value="1" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Продавец
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" name="disassembler" value="1" />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        Разборщик
                    </label>
                </div>
                <div id="selectDolznostSuccess" className="valid-feedback">
                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                </div>
                <div id="selectDolznostError" className="invalid-feedback">
                    Поле обязательно к заполнению
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Добавить сотрудника" className="btn btn-success mt-3" />
            </form>
        </Modal2>
    );
};

export default AdminModalAddNewUser;