import React, {useEffect, useState} from 'react';
import sendForm from "../../../utility/SendForm";
import ModalAdminDopFinance from "./AdminDopFinance/ModalAdminDopFinance";
import ApiRequest from "../../../utility/ApiRequest";
import LoadingIcon from "../../../components/LoadingIcon/LoadingIcon";
import {convertDateFormat, formatNumberWithCommas, objectSize} from "../../../utility/Utility";
import {toast} from "react-toastify";
import Modal2 from "../../../components/Modal/Modal2";
import ModalAdminDopFinanceEdit from "./AdminDopFinance/ModalAdminDopFinanceEdit";

const AdminDopFinance = (props) => {

    const [date_start, setDate_start] = useState("");
    const [date_end, setDate_end] = useState("");
    const [finanseList, setFinanceList] = useState(false);
    const [isLoadFinanceList, setIsLoadFinanceList] = useState(false);
    const [currentCheckImage, setCurrentCheckImage] = useState("");
    const [editDopFinanceOperationId, setEditDopFinanceOperationId] = useState(false);

    const loadDopFinance = (id_shop = "", date_start = "", date_end = "") => {
        setIsLoadFinanceList(false)

        ApiRequest("getDopFinance?", "id_shop=" + id_shop + "&date_start=" + date_start + "&date_end=" + date_end).then((data) => {
            if (data['dopFinance'])
                setFinanceList(data['dopFinance']);

            setIsLoadFinanceList(true);
        });
    }

    const deleteDopFinance = (id_shop, idDopFinance) => {
        ApiRequest("deleteDopFinance?", "id_shop=" + id_shop + "&idDopFinance=" + idDopFinance).then((data) => {
            if (data['success']){
                toast.success(data['success']);
                setFinanceList(finanseList.filter(i => i.id !== idDopFinance));
            }

            if (data['error']){
                toast.error(data['error']);
            }
        });
    }

    useEffect(() => {
        loadDopFinance(props.shopData.id);
    }, [props.getDopFinance]);

    return (
        <div>
            <button className="btn btn-primary w-100 mt-2" modal-open="true" modal-id="addNewDopFinanceOperation">+ Добавить новую финансовую операцию</button>
            <ModalAdminDopFinance setFinanceList={setFinanceList} shopData={props.shopData} mainData={props.mainData} />

            <form action="getDopFinance" id="getFinanceOperation" noValidate method="GET" className="form needs-validation"
                  onSubmit={(event) => sendForm(event, (data) => {
                      setIsLoadFinanceList(false);
                      if (data['dopFinance'])
                              setFinanceList(data['dopFinance']);
                      setIsLoadFinanceList(true);
                  }, false, )}>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="inputPassword" className="col-form-label">Дата начала периода</label>
                        <div className="form-group mt-1">
                            <div>
                                <input type="date" className="form-control w-100" placeholder="" required onChange={(event) => setDate_start(event.target.value)} />
                                <input type="text" className="form-control d-none" name="date_start" value={date_start} />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                    <input type="text" className="form-control d-none" name="id_shop" value={props.shopData.id} />
                    <div className="col-md-6">
                        <label htmlFor="inputPassword" className="col-form-label">Дата окончания периода</label>
                        <div className="form-group mt-1">
                            <div>
                                <input type="date" className="form-control w-100" placeholder="" required onChange={(event) => setDate_end(event.target.value)} />
                                <input type="text" className="form-control d-none" name="date_end" value={date_end} />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button className="btn btn-success w-100 mt-2" type="submit">
                    Получить операции
                </button>
            </form>
            <div className="mt-2">
                {
                    (isLoadFinanceList)
                        ? (objectSize(finanseList))
                            ? <div>
                                <table className="border-none">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Дата</th>
                                        <th scope="col">Наименование</th>
                                        <th scope="col">Исполнитель</th>
                                        <th scope="col">Тип операции</th>
                                        <th scope="col">Сумма</th>
                                        <th scope="col">Чек</th>
                                        <th scope="col">Действия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        Object.keys(finanseList).map((index, i) => {
                                            let style = (finanseList[index].type === "0") ? "bg-danger-subtle" : "bg-success-subtle";
                                            return <tr className={style} key={i}>
                                                <td className="text-center" scope="row">{i+1}</td>
                                                <td scope="row">{convertDateFormat(finanseList[index].date)}</td>
                                                <td scope="row">{finanseList[index].name}</td>
                                                <td scope="row">{finanseList[index].user_info.fio}</td>
                                                <td className="text-center" scope="row">{
                                                    (finanseList[index].type === "0")
                                                        ? "Затрата"
                                                        : "Прибыль"
                                                }</td>
                                                <td className="text-center" scope="row">
                                                    {
                                                        (finanseList[index].type === "1")
                                                            ? <b className="text-success">{formatNumberWithCommas(finanseList[index].amount)}</b>
                                                            : <b className="text-danger">{formatNumberWithCommas(finanseList[index].amount)}</b>
                                                    }
                                                </td>
                                                <td className="text-center" scope="row">
                                                    {
                                                        (finanseList[index].image)
                                                            ? <b className="text-success">Загружен</b>
                                                            : <b className="text-danger">Не загружен</b>
                                                    }
                                                </td>
                                                <td className="text-center" scope="row">
                                                    {
                                                        (finanseList[index].image || parseInt(finanseList[index].object) <= 0)
                                                            ? <div className="dropdown dropstart">
                                                                <button className="btn btn-дшпре dropdown-toggle" type="button"
                                                                        id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                                        aria-haspopup="true" aria-expanded="false">
                                                                    Действия
                                                                </button>
                                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    {
                                                                        (finanseList[index].image)
                                                                            ? <button className="dropdown-item" modal-open="true" modal-id="viewCheckDopFinance" onClick={(event) => setCurrentCheckImage(finanseList[index].image)}>Посмотреть чек</button>
                                                                            : false
                                                                    }
                                                                    {/*<button className="dropdown-item" modal-open="true" modal-id="editDopFinance" onClick={(event) => setEditDopFinanceOperationId(i)}>Редактировать</button>*/}
                                                                    {
                                                                        (parseInt(finanseList[index].object) <= 0)
                                                                            ? <button className="dropdown-item" onClick={(event) => deleteDopFinance(props.shopData.id, finanseList[index].id)}>Удалить</button>
                                                                            : false
                                                                    }
                                                                </div>
                                                            </div>
                                                            : false
                                                    }
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                                <Modal2 title="Просмотр чека" id="viewCheckDopFinance">
                                    <img src={currentCheckImage} width="100%" alt="" />
                                </Modal2>
                                <ModalAdminDopFinanceEdit setFinanceList={setFinanceList} finanseList={finanseList}  dataFinance={finanseList[editDopFinanceOperationId]} shopData={props.shopData} />
                            </div>
                            : <p className="alert alert-danger">Финансовых операций нет.</p>
                        : <LoadingIcon />
                }
            </div>
        </div>
    );
};

export default AdminDopFinance;
