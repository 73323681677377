import { useLocation } from 'react-router-dom'
import $ from "jquery";

const wordByIndex = (str, index) => {
    return str.split(" ")[index - 1];
}

export default wordByIndex;

export const isNumeric = (num) => {
    return !isNaN(num)
}

export const slicetext = (text, lenght) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(text, 'text/html');
    text = doc.body.innerText
    return text.substring(0, lenght) + "...";
}

export const HeaderView = () => {
    const location = useLocation();
    return location.pathname;
}

export const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, 'text/html');

    return html.body.innerHTML;
}

export const objectSize = (obj) => {
    let size = 0, key;

    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }

    return size;
}

export const CallPrint = (strid) => {
    var prtContent = document.getElementById(strid);
    var WinPrint = window.open('','','left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0');
    WinPrint.document.write('<div id="print" class="contentpane">');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.write('</div>');
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
}

export const formatNumberWithCommas = (number) => {
    // Преобразование числа в строку и разделение на разряды через запятую
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',') + " р.";
}

export const currentDate = () => {
    const currentDate = new Date();

    // Определение локали (русская)
    const locale = 'ru-RU';

    // Определение опций форматирования даты и времени
    const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };

    return new Intl.DateTimeFormat(locale, options).format(currentDate);
}

export const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
}

export const copyTextToClipboard = (elementId) => {
    // Получаем элемент по его id
    var element = document.getElementById(elementId);

    // Проверяем, существует ли такой элемент
    if (element) {
        var textToCopy = element.textContent || element.innerText;

        // Создаем временный элемент textarea для копирования текста
        var textarea = document.createElement("textarea");
        textarea.value = textToCopy;

        // Добавляем textarea в DOM
        document.body.appendChild(textarea);

        // Выделяем текст в textarea
        textarea.select();

        // Копируем выделенный текст в буфер обмена
        document.execCommand("copy");

        // Удаляем временный textarea из DOM
        document.body.removeChild(textarea);
    } else {
        alert("Элемент с указанным id не найден.");
    }
}

export const closeModalWindow = (elementid) => {
    var modal = document.getElementById(elementid);
    modal.style.display = "none";
    $(modal).trigger('hide.bs.modal');
}

export const showToogle = (selector) => {
    if ($(selector).hasClass("d-none"))
        $(selector).removeClass("d-none");
    else
        $(selector).addClass("d-none");
}

export const formatTimeElapsed = (dateTimeString) => {
    var currentDate = new Date();
    var previousDate = new Date(dateTimeString);

    var elapsedMilliseconds = currentDate - previousDate;
    var elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    var elapsedMinutes = Math.floor(elapsedSeconds / 60);
    var elapsedHours = Math.floor(elapsedMinutes / 60);
    var elapsedDays = Math.floor(elapsedHours / 24);

    if (elapsedDays >= 7) {
        // Если прошло больше недели, возвращаем дату и время
        return previousDate.toLocaleString();
    } else if (elapsedMinutes < 5) {
        // Если прошло менее 5 минут, возвращаем "только что"
        return 'только что';
    } else {
        // Форматируем разницу во времени
        var remainingHours = elapsedHours % 24;
        var remainingMinutes = elapsedMinutes % 60;

        var formattedTime = '';
        if (elapsedDays > 0) {
            formattedTime += elapsedDays + ' дн. ';
        }
        if (elapsedHours > 0) {
            formattedTime += remainingHours + ' ч. ';
        }
        if (elapsedMinutes > 0) {
            formattedTime += remainingMinutes + ' мин. ';
        }
        formattedTime += 'назад';

        return formattedTime;
    }
}

export const isValidYear = (year) => {
    // Проверяем, является ли введенное значение числом и находится ли в диапазоне от 1900 до текущего года
    const currentYear = new Date().getFullYear();
    return !isNaN(year) && parseInt(year) >= 1900 && parseInt(year) <= currentYear;
}

export const convertDateFormat = (inputDate) => {
    // Разбиваем дату на части (день, месяц, год) и время, если оно присутствует
    const [datePart, timePart] = inputDate.split(' ');

    // Разбиваем дату на отдельные части
    const [year, month, day] = datePart.split('-');

    // Формируем дату в нужном формате
    const formattedDate = `${day}-${month}-${year}`;

    // Если время указано, добавляем его к дате
    if (timePart) {
        return `${formattedDate} ${timePart}`;
    } else {
        return formattedDate;
    }
}

export const isEmpty = (value) => {
    // Проверяем, является ли значение null, undefined или пустой строкой
    return (value === null || value === undefined || value === "" || value === false);
}

export const parseRemontWork = (text) => {
    // Разделить текст на строки по символу переноса строки
    const lines = text.split('\n');

    // Создать пустой массив для хранения объектов
    const result = [];

    // Пройти по каждой строке
    lines.forEach(line => {
        // Разделить строку по пробелу
        const parts = line.split(' ');

        // Получить тип работы (первое слово)
        const type = parts.slice(0, -1).join(' ');

        // Получить сумму (последнее слово)
        const sum = parseInt(parts[parts.length - 1]);

        // Проверить, что сумма является числом
        if (!isNaN(sum)) {
            // Создать объект и добавить его в массив результата
            result.push({ type, sum });
        }
    });

    return result;
}