import React from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import {Link, NavLink} from 'react-router-dom';
import logo from '../../images/logo-kuban_avto.png'
import {exitAccount} from "../../utility/Policy";
import {objectSize} from "../../utility/Utility";

const Sidebar = (props) => {

    return (
        <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
            <CDBSidebar textColor="#fff" backgroundColor="#212529">
                <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
                    <div className="container" style={{ display: 'flex', alignItems: 'center' }}>
                        <Link to="/">
                            <img src={logo} alt="" width="80%" />
                        </Link>
                    </div>
                </CDBSidebarHeader>

                <CDBSidebarContent className="sidebar-content">
                    <CDBSidebarMenu>
                        {
                            props.role.is_admin
                                ? <NavLink exact="true" to="/account/shops">
                                    <CDBSidebarMenuItem icon="columns">Магазины</CDBSidebarMenuItem>
                                </NavLink>
                                : <NavLink></NavLink>
                        }
                        {
                            props.role.is_admin
                                ? <NavLink exact="true" to="/account/analytics">
                                    <CDBSidebarMenuItem icon="chart-line">Статистика</CDBSidebarMenuItem>
                                </NavLink>
                                : <NavLink></NavLink>
                        }
                        {
                            props.role.is_admin
                                ? <NavLink exact="true" to="/account/sysConfig">
                                    <CDBSidebarMenuItem icon="gear">Настройки системы</CDBSidebarMenuItem>
                                </NavLink>
                                : <NavLink></NavLink>
                        }


                        {
                            props.role.is_manager
                                ? <NavLink exact="true" to="/account/shops">
                                    <CDBSidebarMenuItem icon="columns">Магазин</CDBSidebarMenuItem>
                                </NavLink>
                                : <NavLink></NavLink>
                        }


                        {
                            props.role.is_salesman
                                ? <NavLink exact="true" to="/account/boxoffice">
                                    <CDBSidebarMenuItem icon="receipt">Касса</CDBSidebarMenuItem>
                                </NavLink>
                                : <NavLink></NavLink>
                        }
                        {
                            props.role.is_salesman
                                ? <NavLink exact="true" to="/account/historysales">
                                    <CDBSidebarMenuItem icon="chart-line">История продаж</CDBSidebarMenuItem>
                                </NavLink>
                                : <NavLink></NavLink>
                        }


                        {
                            props.role.is_disassembler
                                ? <NavLink exact="true" to="/account/warehouse">
                                    <CDBSidebarMenuItem icon="archive">Склад</CDBSidebarMenuItem>
                                </NavLink>
                                : <NavLink></NavLink>
                        }

                        <NavLink exact="true" to="/account/settings">
                            <CDBSidebarMenuItem icon="user">Настройки профиля</CDBSidebarMenuItem>
                        </NavLink>

                        {
                            (props.role.is_manager || props.role.is_admin)
                                ? <NavLink exact="true" to="/account/notification">
                                    <CDBSidebarMenuItem icon="bell">
                                        Оповещения {
                                        (objectSize(props.receive))
                                            ? <span className="badge bg-success">{
                                                Object.keys(props.receive).length
                                            }</span>
                                            : false
                                    }
                                    </CDBSidebarMenuItem>
                                </NavLink>
                                : <NavLink></NavLink>
                        }

                        <NavLink exact="true" to="/account/traning">
                            <CDBSidebarMenuItem icon="book">Обучение</CDBSidebarMenuItem>
                        </NavLink>
                        {
                            (props.role.is_manager || props.role.is_salesman || props.role.is_disassembler)
                                ? <NavLink exact="true">
                                    <CDBSidebarMenuItem icon="pen">
                                        <div modal-open="true" modal-id="ModalSendMessage">Предложение или отзыв</div>
                                    </CDBSidebarMenuItem>
                                </NavLink>
                                : <NavLink></NavLink>
                        }
                    </CDBSidebarMenu>
                </CDBSidebarContent>

                <CDBSidebarFooter>
                    <CDBSidebarMenu>
                        <NavLink exact="true" to="#">
                            <CDBSidebarMenuItem icon="arrow-left">
                                <span onClick={(event) => exitAccount()}>Выйти</span>
                            </CDBSidebarMenuItem>
                        </NavLink>
                    </CDBSidebarMenu>
                </CDBSidebarFooter>
            </CDBSidebar>
        </div>
    );
};

export default Sidebar;
