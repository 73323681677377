import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import AdminTableAllPartShop from "../administration/AdminShopsCardPage/AdminTableAllPartShop";
import AdminTableCarsPage from "../administration/AdminShopsCardPage/AdminTableCarsPage";
import appGlobalParam from "../../utility/AppGlobalParam";
import apiRequest from "../../utility/ApiRequest";
import {toast} from "react-toastify";

const DisasemblerWareHouse = (props) => {

    const [shopData, setShopData] = useState(false);
    const [shopCars, setShopCars] = useState(false);
    const [shopParts, setShopParts] = useState(false);

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!props.mainData.id_shops)
            document.location.href = appGlobalParam.accountMainUrl;

        apiRequest("getMainDataShop?", "shop_id=" + props.mainData.id_shops).then((data) => {
            if (data['success']){
                setShopData(data.data);
                setShopCars(data.cars)
                setShopParts(data.parts['parts']);
            } else
            if (data['error'])
                toast.error(data['error']);
        });
    }, [searchParams]);

    return (
        <div>
            <h2>Склад</h2>
            <hr />
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <Link className="nav-link" data-bs-toggle="tab" data-bs-target="#sclad"><i className="bi bi-server"></i> Склад</Link>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link" data-bs-toggle="tab" aria-expanded="false" data-bs-target="#cars"><i className="bi bi-car-front-fill"></i> Машины</Link>
                </li>
            </ul>
            <div className="tab-content p-2">
                <div id="sclad" className="tab-pane active">
                    <AdminTableAllPartShop shopParts={shopParts} setShopParts={setShopParts} shopData={shopData} mainData={props.mainData} />
                </div>
                <div id="cars" className="tab-pane">
                    <AdminTableCarsPage notCreateCar="true" setShopCars={setShopCars} shopData={shopData} shopCars={shopCars} mainData={props.mainData} />
                </div>
            </div>
        </div>
    );
};

export default DisasemblerWareHouse;
