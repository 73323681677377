import React, {useEffect, useState} from 'react';
import sendForm, {formDefaultStatusAllFrom} from "../../../../utility/SendForm";
import {toast} from "react-toastify";
import {convertDateFormat, formatNumberWithCommas, objectSize} from "../../../../utility/Utility";
import Modal2 from "../../../../components/Modal/Modal2";

const ModalRemontPart = (props) => {

    const id_modal = (props.id_modal) ? props.id_modal : "remontPartModal";
    const [dateAdd, setDateAdd] = useState("")

    const closeModal = () => {
        props.setFocusPartID(0);
        formDefaultStatusAllFrom();
    }

    useEffect(() => {
        let myModal = document.getElementById(id_modal);
        myModal.addEventListener("hide.bs.modal", closeModal);
        return () => {
            myModal.removeEventListener("hide.bs.modal", closeModal);
        }
    }, []);

    return (
        <Modal2 size="modal-large2" title="Ремонтные работы" id={(props.id_modal) ? props.id_modal : "remontPartModal"}>
            {
                (objectSize(props.editPartData))
                    ? <div>
                        {
                            (objectSize(props.editPartData.expenses_arr))
                                ? <div>

                                    <h5>Ремонтных работы</h5>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Наименование</th>
                                            <th scope="col">Цена</th>
                                            <th scope="col">Комментарии</th>
                                            <th scope="col">Дата</th>
                                            <th scope="col">Действия</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            Object.keys(props.editPartData.expenses_arr).map((indexb, b) => {
                                                return <tr key={b}>
                                                    <th scope="row">{b+1}</th>
                                                    <td>{props.editPartData.expenses_arr[indexb].name}</td>
                                                    <td>{formatNumberWithCommas(props.editPartData.expenses_arr[indexb].price_display)}</td>
                                                    <td>{props.editPartData.expenses_arr[indexb].comment}</td>
                                                    <td>{convertDateFormat(props.editPartData.expenses_arr[indexb].date_add)}</td>
                                                    <td>
                                                        <button className="dropdown-item" onClick={(event) => props.deleteRemontPartServer(props.id_shop, props.id_car,props.editPartData.id, props.editPartData.expenses_arr[indexb].id)}>Удалить</button>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                : false
                        }
                        <h5>Добавление ремонтных работ</h5>
                        <form onSubmit={(event) => sendForm(event, (data) => {
                            if (data['success']){

                                if (data['parts']['success'])
                                    props.setCarParts(data['parts']['parts']);
                                else
                                if (data['parts']['error'])
                                    props.setCarParts(false);

                                toast.success(data['success']);
                            } else {
                                toast.error(data['error']);
                            }
                        }, true, true)} action="addExpensesPart" id={(props.id_form) ? props.id_form : "addExpensesPart"} method="POST"
                              className="needs-validation" noValidate key={"addExpensesPart_" + props.id_part}>

                            <input type="text" className="form-control d-none" name="id_shop" value={props.id_shop} />
                            <input type="text" className="form-control d-none" name="id_car" value={props.id_car} />
                            <input type="text" className="form-control d-none" name="id_part" value={props.editPartData.id} />

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Дата</label>
                                <div className="col-sm-10">
                                    <div className="input-group mb-2">
                                        <input type="date" className="form-control" defaultValue={dateAdd} onChange={(event) => setDateAdd(event.target.value)} />
                                        <input type="text" className="form-control d-none" name="date_add" id={"editCarData_date_add45454"} value={dateAdd} placeholder="" />
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Наименование</label>
                                <div className="col-sm-10">
                                    <div className="input-group mb-2">
                                        <textarea rows="7" name="name_work" className="form-control" placeholder="Замена подшибников" required>

                                        </textarea>
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Цена</label>
                                <div className="col-auto">
                                    <div className="input-group mb-2">
                                        <input type="number" className="form-control" name="price"
                                               placeholder="0" required />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">руб.</div>
                                        </div>
                                        <div className="valid-feedback">
                                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                        </div>
                                        <div className="invalid-feedback">
                                            Поле обязательно к заполнению
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row mt-3">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Комментарии</label>
                                <div className="col-sm-10">
                                    <textarea rows="5" name="comment" className="form-control">

                                    </textarea>
                                    <div className="valid-feedback">
                                        <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                    </div>
                                    <div className="invalid-feedback">
                                        Поле обязательно к заполнению
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div className="alert alert-danger d-none mt-3">

                            </div>
                            <div className="alert alert-success mt-3 d-none">

                            </div>
                            <input type="submit" value="Добавить" className="btn btn-success mt-3"/>
                        </form>
                    </div>
                    : false
            }
        </Modal2>
    );
};

export default ModalRemontPart;