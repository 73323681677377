import React from 'react';
import logo from '../../images/logo-kuban_avto.png'
import {Link} from "react-router-dom";
import {exitAccount} from "../../utility/Policy";
import $ from "jquery";
import {objectSize} from "../../utility/Utility";

const NavBar = (props) => {

    const closeNavigation = () => {
        $('#ToogleBtn').trigger('click');
    }

    return (
        <nav className="navbar navbar-dark bg-dark" style={{height: 80, zIndex: 100}}>
            <div className="container-fluid" style={{padding: 0}}>
                <div className="p-2">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" width="200" />
                    </Link>
                </div>
                <div className="p-2">
                    <button className="navbar-toggler" id="ToogleBtn" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="collapse navbar-collapse bg-dark p-3" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        {
                            props.role.is_admin
                                ? <li className="nav-item">
                                    <Link className="nav-link text-white" aria-current="page" to="/account/shops" onClick={(event) => closeNavigation()}>Магазины</Link>
                                </li>
                                : false
                        }
                        {
                            props.role.is_admin
                                ? <li className="nav-item">
                                    <Link className="nav-link text-white" aria-current="page" to="/account/analytics" onClick={(event) => closeNavigation()}>Статистика</Link>
                                </li>
                                : false
                        }
                        {
                            props.role.is_admin
                                ? <li className="nav-item">
                                    <Link className="nav-link text-white" aria-current="page" to="/account/sysConfig" onClick={(event) => closeNavigation()}>Настройки системы</Link>
                                </li>
                                : false
                        }
                        {
                            props.role.is_manager
                                ? <li className="nav-item">
                                    <Link className="nav-link text-white" aria-current="page" to="/account/shop" onClick={(event) => closeNavigation()}>Магазин</Link>
                                </li>
                                : false
                        }
                        {
                            props.role.is_salesman
                                ? <li className="nav-item">
                                    <Link className="nav-link text-white" aria-current="page" to="/account/boxoffice" onClick={(event) => closeNavigation()}>Касса</Link>
                                </li>
                                : false
                        }
                        {
                            props.role.is_salesman
                                ? <li className="nav-item">
                                    <Link className="nav-link text-white" aria-current="page" to="/account/historysales" onClick={(event) => closeNavigation()}>История продаж</Link>
                                </li>
                                : false
                        }
                        {
                            props.role.is_disassembler
                                ? <li className="nav-item">
                                    <Link className="nav-link text-white" aria-current="page" to="/account/warehouse" onClick={(event) => closeNavigation()}>Склад</Link>
                                </li>
                                : false
                        }

                        <li className="nav-item">
                            <Link className="nav-link text-white" aria-current="page" to="/account/settings" onClick={(event) => closeNavigation()}>Настройки профиля</Link>
                        </li>
                        {
                            props.role.is_manager || props.role.is_admin
                                ? <li className="nav-item">
                                    <Link className="nav-link text-white" aria-current="page" to="/account/notification" onClick={(event) => closeNavigation()}>Оповещения {
                                        (objectSize(props.receive))
                                            ? <span className="badge bg-success">{
                                                Object.keys(props.receive).length
                                            }</span>
                                            : false
                                    }</Link>
                                </li>
                                : false
                        }
                        <li className="nav-item">
                            <Link className="nav-link text-white" aria-current="page" to="/account/traning" onClick={(event) => closeNavigation()}>Обучение</Link>
                        </li>
                        {
                            (props.role.is_manager || props.role.is_disassembler || props.role.is_salesman)
                                ? <li className="nav-item">
                                    <Link className="nav-link text-white" modal-open="true" modal-id="ModalSendMessage" onClick={(event) => closeNavigation()}>Предложения или отзыв</Link>
                                </li>
                                : false
                        }
                        <li className="nav-item">
                            <Link onClick={(event) => exitAccount()} className="nav-link text-white" aria-current="page" to="/">Выйти</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
