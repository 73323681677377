import React, {useState} from 'react';
import Modal2 from "../../../components/Modal/Modal2";
import {formatNumberWithCommas, objectSize} from "../../../utility/Utility";
import PartCard from "../../../components/PartCard/PartCard";

const SallesModalViewSales = (props) => {

    const [currentPartCardId, setCurrentPartCardId] = useState(false);

    return (
        <Modal2 size="modal-large2" title="Товары в чеке" id="modalviewSaleDetail" key="modalviewSaleDetail">
            {
                (objectSize(props.sale))
                    ? <div>
                        {
                            (objectSize(props.sale.partFromSales))
                                ? <div>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Штрихкод</th>
                                            <th scope="col">Наименование</th>
                                            <th scope="col">Цена продажи (без учета скидки)</th>
                                            <th scope="col">Цена продажи (c учета скидки)</th>
                                            <th scope="col">Чистая прибыль</th>
                                            <th scope="col">Статус</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            Object.keys(props.sale.partFromSales).map((index, i) => {
                                                return <tr className={
                                                    (props.sale.partFromSales[index].is_return === 0)
                                                        ? "bg-success-subtle"
                                                        : "bg-danger-subtle"
                                                } key={i}>
                                                    <th scope="row">{i+1}</th>
                                                    <td className="text-decoration-underline" style={{cursor: "pointer"}} onClick={(event) => {
                                                        setCurrentPartCardId(index);
                                                    }} modal-open="true" modal-id="partCard3">{props.sale.partFromSales[index].part_data[0].barcode}</td>
                                                    <td>{props.sale.partFromSales[index].part_data[0].val_type_part}</td>
                                                    <td className="text-center">
                                                        {formatNumberWithCommas(props.sale.partFromSales[index].part_price)}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatNumberWithCommas(props.sale.partFromSales[index].profit_the_discount)}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatNumberWithCommas(props.sale.partFromSales[index].profit)}
                                                    </td>
                                                    <td>{
                                                        (props.sale.partFromSales[index].is_return === 0)
                                                            ? <b>Продан</b>
                                                            : <div>
                                                                <b>Возврат</b>
                                                                <br /><span>Дата возврата: {props.sale.partFromSales[index].return_date}</span>
                                                            </div>
                                                    }</td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                    {
                                        (currentPartCardId && props.sale.partFromSales[currentPartCardId].part_data[0])
                                            ? <PartCard id="partCard3" dataPart={props.sale.partFromSales[currentPartCardId].part_data[0]} />
                                            : false
                                    }
                                </div>
                                : <p className="alert alert-danger">Запчастей нет.</p>
                        }
                    </div>
                    : false
            }
        </Modal2>
    );
};

export default SallesModalViewSales;
