import React, {useEffect, useState} from 'react';
import Select from "react-select";
import {isEmpty, objectSize} from "../../../utility/Utility";
import $ from 'jquery';
import SendForm from "../../../utility/SendForm";
import apiRequest from "../../../utility/ApiRequest";
import {toast} from "react-toastify";

const AdminSettingsSPPartSovmestimost = (props) => {

    const [adsAvtoMarka, setAdsAvtoMarka] = useState(false);
    const [adsAvtoMarkaAddSovmest, setAdsAvtoMarkaAddSovmest] = useState(false);
    const [typePartID, setTypePartID] = useState(false);
    const [markaAvto, setMarkaAvto] = useState("");
    const [currentSovmestimosti, setCurrentSovmestimosti] = useState(false);

    useEffect(() => {
        if (!isEmpty(adsAvtoMarka) && !isEmpty(markaAvto) && !isEmpty(typePartID)){
            $("#btn_getSovmestimostPartFromCarForm").trigger("click");
        }
    }, [typePartID]);

    const deleteSovmestimostFromServer = (sovmest_id_marka, sovmest_model) => {
        apiRequest("deleteSovmestimost?", "id_marka=" + adsAvtoMarka + "&model=" + markaAvto + "&id_part_type=" + typePartID + "&sovmest_id_marka=" + sovmest_id_marka + "&sovmest_model=" + sovmest_model, "POST").then((data) => {
            console.log(data);
            if (data['success']){
                if (data['sovmestimosti']){
                    setCurrentSovmestimosti(data['sovmestimosti']);
                }
            } else if (data['error'])
                toast.error(data['error']);
        });
    }

    return (
        <div>
            <h5>Совместимость запчастей</h5>
            <form action="getSovmestimostPartFromCar" id="getSovmestimostPartFromCarForm" method="POST" className="form needs-validation" noValidate
            onSubmit={(event) => SendForm(event, (data) => {
                if (data['success']){
                    if (data['sovmestimosti']){
                        setCurrentSovmestimosti(data['sovmestimosti']);
                    }
                }
            }, true, false)}>
                <input type="submit" value="Получить список совместимостей" className="d-none" id="btn_getSovmestimostPartFromCarForm" />
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <Select
                                options={props.sp_cars}
                                placeholder="Марка автомобиля"
                                isSearchable="true"
                                onChange={(event) => setAdsAvtoMarka(event.value)}
                            />
                            <input name="marka" type="text" value={adsAvtoMarka} className="d-none" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <input type="text" className="form-control" name="model" placeholder="Модель автомобиля" required
                                   onChange={(event) => setMarkaAvto(event.target.value)} />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <Select
                                options={props.sp_parts}
                                placeholder="Тип запчасти"
                                isSearchable={true}
                                onChange={(event) => setTypePartID(event.value)}
                            />
                            <input type="text" className="form-control d-none" name="typePart" placeholder="" value={typePartID} required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
            </form>
            <hr />
                <div className="row">
                    <div className="col-md-6">
                        {
                            (objectSize(currentSovmestimosti))
                                ? <div>
                                    <h5>Данная запчасть подходит для следующих автомобилей</h5>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Наименование</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            Object.keys(currentSovmestimosti).map((avto, index) => {
                                                return <tr key={index}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{currentSovmestimosti[avto].sovmest_id_marka}, {currentSovmestimosti[avto].sovmest_model} <i className="bi bi-x-square-fill text-danger" style={{cursor: "pointer"}} onClick={(event) => deleteSovmestimostFromServer(currentSovmestimosti[avto].sovmest_id_marka_system, currentSovmestimosti[avto].sovmest_model)}></i></td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                : <p className="alert alert-danger w-100">Совместимостей нет.</p>
                        }
                    </div>
                    <div className="col-md-6">
                        {
                            (!isEmpty(adsAvtoMarka) && !isEmpty(markaAvto) && !isEmpty(typePartID))
                                ? <div>
                                    <form action="setSovmestimostPartFromCar" id="setSovmestimostPartFromCarForm" method="POST" className="form needs-validation" noValidate
                                          onSubmit={(event) => SendForm(event, (data) => {
                                              if (data['success']){
                                                  if (data['sovmestimosti']){
                                                      setCurrentSovmestimosti(data['sovmestimosti']);
                                                  }
                                              }
                                          }, true, true)}>
                                        <h5>Добавление совместимости для выбранной запчасти</h5>
                                        <div className="form-group mt-3">
                                            <Select
                                                options={props.sp_cars}
                                                placeholder="Марка автомобиля"
                                                isSearchable="true"
                                                onChange={(event) => setAdsAvtoMarkaAddSovmest(event.value)}
                                            />
                                            <input name="sovmest_id_marka" type="text" value={adsAvtoMarkaAddSovmest} className="d-none" required />
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                        <div className="form-group  mt-3">
                                            <input type="text" className="form-control" name="sovmest_model" placeholder="Модель автомобиля" required />
                                            <div className="valid-feedback">
                                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                            </div>
                                            <div className="invalid-feedback">
                                                Поле обязательно к заполнению
                                            </div>
                                        </div>
                                        <input type="text" className="form-control d-none" value={adsAvtoMarka} name="id_marka" placeholder="Модель автомобиля" required />
                                        <input type="text" className="form-control d-none" value={markaAvto} name="model" placeholder="Модель автомобиля" required />
                                        <input type="text" className="form-control d-none" value={typePartID} name="id_part_type" placeholder="Модель автомобиля" required />

                                        <div className="alert alert-danger d-none mt-3">

                                        </div>
                                        <div className="alert alert-success mt-3 d-none">

                                        </div>
                                        <button className="btn btn-success mt-3" type="submit">Добавить совместимость</button>

                                    </form>
                                </div>
                                : false
                        }
                </div>
            </div>
        </div>
    );
};

export default AdminSettingsSPPartSovmestimost;
