import React, {useState} from 'react';
import AdminModalChangeDataUserShop from "./AdminModalChangeDataUserShop";
import AdminModalChangePassword from "./AdminModalChangePassword";
import AdminModalChangeRole from "./AdminModalChangeRole";
import AdminModalDeleteUserShop from "./AdminModalDeleteUserShop";
import {convertDateFormat, formatTimeElapsed} from "../../../utility/Utility";
import ModalUserZarabPlata from "./ModalUser/ModalUserZarabPlata";

const AdminTableUserShop = (props) => {

    const [currentUserId, setCurrentUserId] = useState(false);

    return (
        <div>
            <button className="btn btn-sm btn-primary" modal-open="true" modal-id="AddNewUserShop">+ Добавить сотрудника</button>
            <hr />

            <table>
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">ФИО</th>
                    <th scope="col">Должность</th>
                    <th scope="col">Логин</th>
                    <th scope="col">Адрес электронной почты</th>
                    <th scope="col">Телефон</th>
                    <th scope="col">Дата последнего входа</th>
                    <th scope="col">Дата последнего действия</th>
                    <th scope="col">Действия</th>
                </tr>
                </thead>
                <tbody>
                {
                    Object.keys(props.shopUsers).map((index, i) => {
                        return <tr key={i+1}>
                            <th scope="row">{i+1}</th>
                            <td>{props.shopUsers[index].fio}</td>
                            <td>{props.shopUsers[index].role}</td>
                            <td>{props.shopUsers[index].login}</td>
                            <td>{props.shopUsers[index].mail}</td>
                            <td>{props.shopUsers[index].phone}</td>
                            <td>{convertDateFormat(props.shopUsers[index].date_last_enter)}</td>
                            <td>{formatTimeElapsed(props.shopUsers[index].date_last_action)}</td>
                            <td>
                                <div className="dropdown dropstart">
                                    <button className="btn btn-small dropdown-toggle" type="button"
                                            id="dropdownMenuButton" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                        Действия
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <button className="dropdown-item" modal-open="true" modal-id="setNewZPlata" onClick={(event) => setCurrentUserId(i)}>Выдать заработную плату</button>
                                        <button className="dropdown-item" modal-open="true" modal-id={"EditDataUserShop_" + i}>Изменить данные</button>
                                        <button className="dropdown-item" modal-open="true" modal-id={"EditPasswordUserShop_" + i}>Изменить пароль</button>
                                        <button className="dropdown-item" modal-open="true" modal-id={"editRoleUserShop_" + i}>Изменить долножсть</button>
                                        <button className="dropdown-item" modal-open="true" modal-id={"DeleteUserShop_" + i}>Удалить</button>
                                    </div>
                                </div>
                                <AdminModalChangeDataUserShop id={i} id_shop={props.shopData.id} setShopUsers={props.setShopUsers} userData={props.shopUsers[index]} />
                                <AdminModalChangePassword id={i} id_shop={props.shopData.id} userData={props.shopUsers[index]} />
                                <AdminModalChangeRole id={i} id_shop={props.shopData.id} setShopUsers={props.setShopUsers} userData={props.shopUsers[index]} />
                                <AdminModalDeleteUserShop id={i} id_shop={props.shopData.id} setShopUsers={props.setShopUsers} userData={props.shopUsers[index]} />
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
            <ModalUserZarabPlata id_shop={props.shopData.id} dataUser={props.shopUsers[currentUserId]} />
        </div>
    );
};

export default AdminTableUserShop;