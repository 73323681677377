import React, {useEffect, useState} from 'react';
import Modal2 from "../../../../components/Modal/Modal2";
import sendForm, {formDefaultStatusAllFrom} from "../../../../utility/SendForm";
import {toast} from "react-toastify";
import {closeModalWindow, objectSize} from "../../../../utility/Utility";
import $ from "jquery";
import apiRequest from "../../../../utility/ApiRequest";

let indexLastImage = 0;

const ModalAdminDopFinanceEdit = (props) => {

    const [displayImgAds, setdisplayImgAds] = useState([]);
    const [sendAddNewPart, setSendAddNewPart] = useState(false);
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [date, setDate] = useState("");

    const addImgAds = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImage++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAds([...arrImg]);
        event.target.value = null;
    }

    const editOperation = (event) => {

        event.preventDefault();

        if (!sendAddNewPart){
            let files = [];
            for (let i = 0; i < displayImgAds.length; i++)
                files.push(displayImgAds[i]['file']);

            setSendAddNewPart(true);

            sendForm(event, (data) => {
                if (data['success']){
                    toast.success(data['success']);
                    indexLastImage = 0;
                    setdisplayImgAds([]);
                    closeModalWindow("addNewDopFinanceOperation")
                }
                setSendAddNewPart(false);
            }, true, true, true, files, () => {
                setSendAddNewPart(false);
            });
        }
    }

    const closeModal = () => {
        formDefaultStatusAllFrom();
        indexLastImage = 0;
        setdisplayImgAds(false);
    }

    useEffect(() => {
        if (objectSize(props.dataFinance)){
            $('#type_operation_form_edit_operation option[value="' + props.dataFinance.type + '"]').prop('selected', true);
            setName(props.dataFinance.name);
            setAmount(props.dataFinance.amount);
            setDate(props.dataFinance.date);

            if (props.dataFinance.image){
                indexLastImage = 0;
                setdisplayImgAds(false);
                $("#dopFinanceEditImage").attr("src", props.dataFinance.image);
            }
        }
        const id_modal = "editDopFinance";
        let myModal = document.getElementById(id_modal);
        myModal.addEventListener("hide.bs.modal", closeModal);
        return () => {
            myModal.removeEventListener("hide.bs.modal", closeModal);
        }
    }, [props.dataFinance]);

    const deleteImageServer = (event, id_shop, id_dopFinance) => {
        event.preventDefault();
        apiRequest("deleteDopFinanceImg?", "id_shop=" + id_shop + "&id_dopFinance=" + id_dopFinance).then((data) => {
            console.log(data);
            if (data['success']){
                toast.success(data['success']);

                let newList = props.finanseList;
                let index = newList.findIndex(item => item.id === id_dopFinance);
                // Если элемент найден, очищаем свойство "image"
                if (index !== -1) {
                    newList[index].image = '';
                    props.setFinanceList(newList);
                }
                closeModalWindow("editDopFinance");
            } else
                toast.error(data['error']);
        });
    }

    return (
        <Modal2 title="Редактирование финансовой операции" id="editDopFinance">
            {
                (objectSize(props.dataFinance))
                    ? <form action="editFinanceOperation" id="editFinanceOperationForm" method="POST"
                            noValidate className="form needs-validation" onSubmit={(event) => editOperation(event)} key="form_3948234765">
                        <div>
                            <div className="mt-2">
                                <label htmlFor="validationCustom01" className="form-label">Наименование операции</label>
                                <input name="name_operation" id="s1f1" type="text" className="form-control" defaultValue={name} placeholder="Введите наименование операции" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <input type="text" className="form-control d-none" name="id_shop" defaultValue={props.shopData.id} />
                            <div className="mt-2">
                                <label htmlFor="validationCustom01" className="form-label">Сумма</label>
                                <input name="amount_operation" id="s1f2" type="number" defaultValue={amount} className="form-control" placeholder="Введите сумму" required />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="inputPassword" className="form-label">Дата</label>
                                <input type="date" className="form-control" id="s1f3" placeholder="" defaultValue={date} required onChange={(event) => setDate(event.target.value)} />
                                <input type="text" className="form-control d-none" name="date_operation" defaultValue={date} placeholder="" />
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                            <div className="mt-2">
                                <label htmlFor="inputPassword" className="form-label">Тип операции</label>
                                <select id="type_operation_form_edit_operation" name="type_operation" className="form-select" required="true">
                                    <option value="">Выберите тип</option>
                                    <option value="1">Прибыль</option>
                                    <option value="0">Затрата</option>
                                </select>
                                <div className="valid-feedback">
                                    <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                                </div>
                                <div className="invalid-feedback">
                                    Поле обязательно к заполнению
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div className="w-100">
                            <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                                {
                                    (!displayImgAds.length)
                                        ? <div>
                                            <img src={props.dataFinance.image} id="dopFinanceEditImage" width="100%" alt="" />
                                            <button className="btn btn-danger btn-sm w-100" onclick={(event) => deleteImageServer(event, props.shopData.id, props.dataFinance.id)}>Удалить чек</button>
                                        </div>
                                        : (displayImgAds.length)
                                            ? displayImgAds.map((image, key) => {
                                                return (
                                                    <img src={image['url']} id="dopFinanceEditImage" width="100%" alt="" />
                                                )
                                            })
                                            : false
                                }
                                <label>
                                    <div className="imagebutton mt-2">
                                        <i className="bi bi-camera"></i>
                                    </div>
                                    <input type="file" onChange={(event) => addImgAds(event)} hidden />
                                </label>
                            </div>
                        </div>

                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <div className="alert alert-success mt-3 d-none">

                        </div>
                        <button className="btn btn-success w-100 mt-2" type="submit">
                            Добавить операцию
                        </button>
                    </form>
                    : false
            }
        </Modal2>
    );
};

export default ModalAdminDopFinanceEdit;
