import React, {useState} from 'react';
import {toast} from "react-toastify";
import {closeModalWindow} from "../../../../utility/Utility";
import Modal2 from "../../../../components/Modal/Modal2";
import ImgAds from "../../../../components/ImgFormPreview/ImgAds";
import sendForm from "../../../../utility/SendForm";
import Select from "react-select";

let indexLastImage = 0;

const ModalAdminDopFinance = (props) => {

    const [dateshop, setDateShop] = useState("");
    const [name_operation, setNameOperation] = useState("");
    const [displayImgAds, setdisplayImgAds] = useState([]);
    const [sendAddNewPart, setSendAddNewPart] = useState(false);

    const addImgAds = (event) => {
        let arrImg = [];
        for(let i = 0; i < event.target.files.length; i++) {
            indexLastImage++;
            event.target.files[i]['rotate'] = 0;
            arrImg.push({"file": event.target.files[i], "url" : URL.createObjectURL(event.target.files[i]), "index": indexLastImage});
        }
        setdisplayImgAds([...arrImg]);
        event.target.value = null;
    }
    const deleteImgAds = (idImg) => {
        setdisplayImgAds(displayImgAds.filter(obj => {
            return obj['index'] !== idImg;
        }));
    }

    const addNewOperation = (event) => {

        event.preventDefault();

        if (!sendAddNewPart){
            let files = [];
            for (let i = 0; i < displayImgAds.length; i++)
                files.push(displayImgAds[i]['file']);

            setSendAddNewPart(true);

            sendForm(event, (data) => {
                if (data['success']){
                    props.setFinanceList(data['dopFinance']);
                    toast.success(data['success']);
                    indexLastImage = 0;
                    setdisplayImgAds([]);
                    closeModalWindow("addNewDopFinanceOperation")
                }
                setSendAddNewPart(false);
            }, true, true, true, files, () => {
                setSendAddNewPart(false);
            });
        }
    }

    return (
        <Modal2 title="Добавление финансовой операции" id="addNewDopFinanceOperation">
            <form action="setNewFinanceOperation" id="setNewFinanceOperationForm" method="POST"
                  noValidate className="form needs-validation" onSubmit={(event) => addNewOperation(event)}>
                <div>
                    <div className="mt-2">
                        <Select
                            options={props.mainData.sp_finance}
                            placeholder="Поиск типа запчасти"
                            isSearchable={true}
                            onChange={(event) => setNameOperation(event.value)}
                        />
                        <input name="name_operation" id="validationCustom01" type="text" className="d-none" value={name_operation} placeholder="Введите наименование операции" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <input type="text" className="form-control d-none" name="id_shop" defaultValue={props.shopData.id} />
                    <div className="mt-2">
                        <label htmlFor="validationCustom01" className="form-label">Сумма</label>
                        <input name="amount_operation" id="validationCustom01" type="number" className="form-control" placeholder="Введите сумму" required />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="inputPassword" className="form-label">Дата</label>
                        <input type="date" className="form-control" placeholder="" required onChange={(event) => setDateShop(event.target.value)} />
                        <input type="text" className="form-control d-none" name="date_operation" defaultValue={dateshop} placeholder="" />
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                    <div className="mt-2">
                        <label htmlFor="inputPassword" className="form-label">Тип операции</label>
                        <select name="type_operation" className="form-select" required="true">
                            <option value="">Выберите тип</option>
                            <option value="1">Прибыль</option>
                            <option value="0">Затрата</option>
                        </select>
                        <div className="valid-feedback">
                            <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                        </div>
                        <div className="invalid-feedback">
                            Поле обязательно к заполнению
                        </div>
                    </div>
                </div>

                <hr />

                <div className="w-100">
                    <div className="d-flex flex-row d-inline flex-wrap" id="AdsImgContainer">
                        {
                            (displayImgAds.length)
                                ? displayImgAds.map((image, key) => {
                                    return (
                                        <ImgAds src={image['url']} id_img={image.index} deleteImage={deleteImgAds} key={key} />
                                    )
                                })
                                : false
                        }
                        <label>
                            <div className="imagebutton">
                                <i className="bi bi-camera"></i>
                            </div>
                            <input type="file" onChange={(event) => addImgAds(event)} hidden />
                        </label>
                    </div>
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <button className="btn btn-success w-100 mt-2" type="submit">
                    Добавить операцию
                </button>
            </form>
        </Modal2>
    );
};

export default ModalAdminDopFinance;
