import React, {useEffect, useState} from 'react';
import Modal2 from "../components/Modal/Modal2";
import 'react-quill/dist/quill.snow.css';
import apiRequest from "../utility/ApiRequest";
import $ from 'jquery';
import AdminModalTreningAddPost from "./administration/AdminModalTrening/AdminModalTreningAddPost";
import AdminModalTreningEditPost from "./administration/AdminModalTrening/AdminModalTreningEditPost";
import {objectSize, slicetext} from "../utility/Utility";
import {toast} from "react-toastify";
import LoadingIcon from "../components/LoadingIcon/LoadingIcon";

const AccountTraningPage = (props) => {
    const [posts, setPosts] = useState(false);
    const [editPostId, setEditReadPostId] = useState(false);
    const [isLoadingPosts, setIsLoadingPosts] = useState(false);
    useEffect(() => {
        apiRequest("getPosts?").then((data) => {
            if (data['success']){
                setPosts(data['posts']);
            }
            setIsLoadingPosts(true);
        });
    }, []);

    const deletePost = (id) => {
        apiRequest("deletePosts?", "post_id=" + id, "POST").then((data) => {
            if (data['success']){
                toast.success(data['success']);
                setPosts(data['posts']['posts']);
            }

            if (data['error'])
                toast.error(data['error']);
        });
    }

    return (
        <div>
            <h2><i className="bi bi-journal-text"></i> Обучение</h2>
            <hr />
            {
                (props.mainData.role.is_admin)
                    ? <button modal-open="true" modal-id="addNewPost" className="btn btn-sm btn-success">+ добавить статью</button>
                    : false
            }
            <AdminModalTreningAddPost setPosts={setPosts} />
            <AdminModalTreningEditPost setPosts={setPosts} postData={posts[editPostId]} />
            <div className="mt-2">
                <h5>Статьи</h5>
                <div className="row mt-2">
                    {
                        (isLoadingPosts)
                            ? (objectSize(posts))
                                ? Object.keys(posts).map((index, i) => {
                                      return <div className="card col-md-4" style={{width: "18rem"}}>
                                          <div className="card-body">
                                              <h5 className="card-title">{
                                                  posts[index]['name']
                                              }</h5>
                                              <p className="card-text">{
                                                  slicetext(posts[index]['text_lesson'], 80)
                                              }</p>
                                              <button className="btn btn-sm btn-success" modal-open="true" modal-id="readPost" onClick={(event) => {
                                                  $("#name_post").html(posts[index]['name']);
                                                  $("#container_post").html(posts[index]['text_lesson']);
                                              }}>Читать статью</button>
                                              {
                                                  (props.mainData.role.is_admin)
                                                      ? <div>
                                                          <div className="dropdown dropstart">
                                                              <button className="btn btn-дшпре dropdown-toggle" type="button"
                                                                      id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                                      aria-haspopup="true" aria-expanded="false">
                                                                  Действия
                                                              </button>
                                                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                  <button className="dropdown-item" modal-open="true" modal-id="EditPost" onClick={(event) => {
                                                                      setEditReadPostId(index);
                                                                  }}>Редактировать</button>
                                                                  <button className="dropdown-item" onClick={(event) => deletePost(posts[index]['id'])}>Удалить</button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      : false
                                              }
                                          </div>
                                      </div>
                                })
                                : <div className="alert alert-danger">Постов нет.</div>
                            : <LoadingIcon />
                    }
                    <Modal2 size="modal-medium2" id="readPost" title="Просмотр поста">
                        <h3 className="text-center" id="name_post"></h3>
                        <div id="container_post"></div>
                    </Modal2>
                </div>
            </div>
        </div>
    );
};

export default AccountTraningPage;
