import React, {useState} from 'react';
import Modal2 from "../../../components/Modal/Modal2";
import sendForm from "../../../utility/SendForm";
import {toast} from "react-toastify";
import {formatNumberWithCommas, objectSize} from "../../../utility/Utility";
import apiRequest from "../../../utility/ApiRequest";

const SallesReturnPart = (props) => {

    const [partsReceipt, setPartReceipt] = useState([]);

    return (
        <Modal2 size="modal-large2" title="Возврат запчастей" id="ReturnPart" key="ReturnPart">
            <form onSubmit={(event) => sendForm(event, (data) => {
                setPartReceipt([]);

                if (data['success']){
                    setPartReceipt(data['parts']);
                } else if (data['error']){
                    toast.error(data['error']);
                }
            }, true, true)} action="getAcceiptData" id="GetAcceiptData" method="POST"
                  className="needs-validation" noValidate>

                <input type="text" className="form-control d-none" name="id_shop" value={props.id_shops} />

                <div className="form-group row mt-3">
                    <div className="col-12">
                        <div className="input-group mb-2">
                            <input type="number" className="form-control" name="AcceptedNumber"
                                   placeholder="Введите номер чека" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>

                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <input type="submit" value="Получить список запчастей" className="btn btn-success mt-3"/>
            </form>

            {
                (objectSize(partsReceipt))
                    ? <div className="mt-2">
                        <h5>Список запчастей в чеке</h5>
                        <table>
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th>Наименование</th>
                                <th>Количество</th>
                                <th>Цена реализации</th>
                                <th>Итого</th>
                                <th>Дата реализации</th>
                                <th>Действия</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(partsReceipt).map((indexb, b) => {
                                    return <tr key={b}>
                                        <th scope="row">{b+1}</th>
                                        <td>{partsReceipt[indexb].part.val_type_part}, {partsReceipt[indexb].part.marka_name} {partsReceipt[indexb].part.model}</td>
                                        <td>{partsReceipt[indexb].part_count} шт.</td>
                                        <td>{formatNumberWithCommas(partsReceipt[indexb].part.price_by)}</td>
                                        <td>{formatNumberWithCommas(parseFloat(partsReceipt[indexb].part.price_by) * parseInt(partsReceipt[indexb].part_count))}</td>
                                        <td>{partsReceipt[indexb].date_realiz}</td>
                                        <td>
                                            <button className="btn btn-success btn-sm" onClick={(event) => {
                                                apiRequest("setNewReturnPart?", "id_shop=" + partsReceipt[indexb].part.id_shop + "&id_sales=" + partsReceipt[indexb].id_sales + "&id_part=" + partsReceipt[indexb].part.id, "POST").then((data) => {
                                                    if (data['success']){
                                                        setPartReceipt(partsReceipt.filter((element, index) => element['id'] !== partsReceipt[indexb].id));
                                                        toast.success(data['success']);
                                                    } else if (data['error']) {
                                                        toast.error(data['error']);
                                                    }
                                                });
                                            }}>Оформить возврат</button>
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    : false
            }
        </Modal2>
    );
};

export default SallesReturnPart;