import React from 'react';
import Modal2 from "../Modal/Modal2";
import {convertDateFormat, formatNumberWithCommas, objectSize} from "../../utility/Utility";
import {Link} from "react-router-dom";
import not_img from '../../images/notimage.png'
const PartCard = (props) => {

    return (
        <Modal2 size="modal-medium2" title="Карточка запчасти" id={props.id}>
            {
                (objectSize(props.dataPart))
                    ?<div>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <Link className="nav-link active" data-bs-toggle="tab" data-bs-target={"#part_card_photo_" + props.id}>Фото</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link" data-bs-toggle="tab" data-bs-target={"#part_card_info_" + props.id}>Информация</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link" data-bs-toggle="tab" data-bs-target={"#part_card_sovmestimost_" + props.id}>Совместимость</Link>
                        </li>
                    </ul>
                    <div className="tab-content p-2">
                        <div id={"part_card_photo_" + props.id} className="tab-pane active">
                            {
                                (objectSize(props.dataPart.images))
                                    ? <div id="carouselExampleControls2" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">
                                            {
                                                props.dataPart.images.map((image, key) => {
                                                        if (key === 0)
                                                            return (
                                                                <div className="carousel-item active" key={key}>
                                                                    <img className="d-block w-100" src={image} alt="" />
                                                                </div>
                                                            )
                                                        else
                                                            return (
                                                                <div className="carousel-item" key={key}>
                                                                    <img className="d-block w-100" src={image} alt="" />
                                                                </div>
                                                            )
                                                    })
                                            }
                                        </div>
                                        <a className="carousel-control-prev" href="#carouselExampleControls2" role="button"
                                           data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Назад</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselExampleControls2" role="button"
                                           data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Вперед</span>
                                        </a>
                                    </div>
                                    : <div className="d-flex justify-content-center align-items-center">
                                        <img className="d-block" style={{width: 500}} src={not_img} alt="" />
                                    </div>
                            }
                        </div>
                        <div id={"part_card_info_" + props.id} className="tab-pane">
                            <ul className="list-group">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5 className="mb-2 mt-2">Общие данные</h5>
                                        <li className="list-group-item">
                                            <strong>Штрихкод:</strong> {props.dataPart.barcode}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Автомобиль:</strong> {props.dataPart.marka_name}, {props.dataPart.model}
                                            {
                                                (props.dataPart.car_year_create)
                                                    ? ", " + props.dataPart.car_year_create
                                                    : false
                                            }
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Тип запчасти:</strong> {props.dataPart.val_type_part}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Доп. параметры запчасти:</strong> {props.dataPart.dop_param_parts}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Состояние:</strong>
                                            {
                                                (props.dataPart.status_part === 1)
                                                    ? " Б/У"
                                                    : (props.dataPart.status_part === 2)
                                                        ? " Новое"
                                                        : " Б/У c дефектом"
                                            }
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Запчасть находится на ремонте:</strong>
                                            {
                                                (props.dataPart.is_remont)
                                                    ? " Да"
                                                    : " Нет"
                                            }
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Количество:</strong> {props.dataPart.count} {props.dataPart.edinica_type}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Бронь:</strong>
                                            {
                                                (props.dataPart.count_booking)
                                                    ? <span> Да ({props.dataPart.count_booking})</span>
                                                    : " Нет"
                                            }
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Комментарии:</strong> {props.dataPart.description}
                                        </li>
                                    </div>
                                    <div className="col-md-6">
                                            <h5 className="mb-2 mt-2">Дата</h5>
                                            <li className="list-group-item">
                                                <strong>Дата закупки:</strong> {convertDateFormat(props.dataPart.date_shop)}
                                            </li>
                                            <h5 className="mb-2 mt-2">Местоположение</h5>
                                            <li className="list-group-item">
                                                    <strong>№ стелажа:</strong> {props.dataPart.row}
                                            </li>
                                            <li className="list-group-item">
                                                    <strong>№ полки:</strong> {props.dataPart.shelf}
                                            </li>
                                            <li className="list-group-item">
                                                <strong>Место:</strong> {props.dataPart.place}
                                            </li>
                                        <h5 className="mb-2 mt-2">Финансовые данные</h5>
                                        <li className="list-group-item">
                                            <strong>Закупочная цена:</strong> {formatNumberWithCommas(props.dataPart.cost_price)}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Стоимость доставки:</strong> {formatNumberWithCommas(props.dataPart.price_delivery)}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Стоимость ремонтных работ:</strong> {formatNumberWithCommas(props.dataPart.expenses_all_summ)}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Себестоимость:</strong> {formatNumberWithCommas(props.dataPart.sebestoimost)}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Наценка:</strong> {formatNumberWithCommas(props.dataPart.price)}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Цена продажи:</strong> {formatNumberWithCommas(props.dataPart.price_by)}
                                        </li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <div id={"part_card_sovmestimost_" + props.id} className="tab-pane">
                            {
                                (objectSize(props.dataPart.sovmestimost.sovmestimosti))
                                    ? <div>
                                        <h5>Данная запчасть подходит для следующих автомобилей</h5>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Наименование</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                Object.keys(props.dataPart.sovmestimost.sovmestimosti).map((avto, index) => {
                                                    return <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{props.dataPart.sovmestimost.sovmestimosti[avto].sovmest_id_marka}, {props.dataPart.sovmestimost.sovmestimosti[avto].sovmest_model}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    : <p className="alert alert-info">Нет добавленных совместимостей</p>
                            }
                        </div>
                    </div>
                    </div>
                : false
            }
        </Modal2>
    );
};

export default PartCard;