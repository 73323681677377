import React from 'react';
import Modal2 from "../Modal/Modal2";
import ReactQuill from "react-quill";
import sendForm from "../../utility/SendForm";
import {toast} from "react-toastify";
import {closeModalWindow} from "../../utility/Utility";

const ModalSendMessage = (props) => {
    return (
        <Modal2 size="modal-large2" id="ModalSendMessage" title="Предложение или отзыв">
            <form onSubmit={(event) => sendForm(event, (data) => {
                if (data['success']){
                    toast.success(data['success'])
                    closeModalWindow("ModalSendMessage");
                }
            }, true, true)} action="setPredlozhenieRevent" id="formSetPredlozhenieRevent" method="POST"
                  className="needs-validation" noValidate>
                <div>
                    <label htmlFor="inputPassword" className="col-form-label">Название темы</label>
                    <div className="form-group mt-1">
                        <div className="input-group">
                            <input type="text" className="form-control w-100" name="name"
                                   placeholder="Например: Предложение по поводу структуры управления" required />
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <label htmlFor="inputPassword" className="col-form-label">Содержание</label>
                    <div className="form-group mt-1">
                        <div className="input-group">
                            <textarea className="form-control" rows="10" name="message" required></textarea>
                            <div className="valid-feedback">
                                <i className="bi bi-hand-thumbs-up-fill"></i> Заполнено
                            </div>
                            <div className="invalid-feedback">
                                Поле обязательно к заполнению
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <label htmlFor="inputPassword" className="col-form-label">Выберите получателя</label>
                    <div className="form-group mt-1">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="type_admin" value="1" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Администратор
                            </label>
                        </div>
                        {
                            (!props.role['is_manager'])
                                ? <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="type_manager" value="1" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Управляющий
                                    </label>
                                </div>
                                : false
                        }
                    </div>
                </div>
                <div className="alert alert-danger d-none mt-3">

                </div>
                <div className="alert alert-success mt-3 d-none">

                </div>
                <button type="submit" className="btn btn-sm btn-success w-100 mt-2">Отправить</button>
            </form>
        </Modal2>
    );
};

export default ModalSendMessage;
