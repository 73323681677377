import React from 'react';
import SendForm from "../../../utility/SendForm";
import PasswordInput from "../../../components/PaswordInput/PasswordInput";
import Modal2 from "../../../components/Modal/Modal2";
import {toast} from "react-toastify";
import {closeModalWindow} from "../../../utility/Utility";

const AdminModalChangePassword = (props) => {
    return (
        <Modal2 title={"Изменение пароля для " + props.userData.fio} id={"EditPasswordUserShop_" +  + props.id}>
            <form onSubmit={(event) => SendForm(event, (data) => {
                if (data['success']){
                    toast.success(data['success']);
                    closeModalWindow("EditPasswordUserShop_" +  + props.id);
                }
            }, true, true)} action="editPasswordUserShop" id={"formEditPasswordUserShop_" +  + props.id} method="GET"
                  className="needs-validation" noValidate>
                    <input type="text" className="d-none" defaultValue={props.userData.id} name="id_user"/>
                    <input type="text" className="d-none" defaultValue={props.id_shop} name="id_shop"/>
                    <div>
                        <label htmlFor="validationCustom02" className="form-label">Пароль</label>
                        <PasswordInput name="password" type="password" className="form-control" placeholder="Введите пароль, не мение 8 символов" required />
                    </div>
                    <div className="mt-2">
                        <label htmlFor="validationCustom03" className="form-label">Повторите пароль</label>
                        <PasswordInput name="repeatPassword" type="password" className="form-control" placeholder="Повторите пароль" required />
                    </div>
                    <div className="alert alert-danger d-none mt-3">

                    </div>
                    <div className="alert alert-success mt-3 d-none">

                    </div>
                    <input type="submit" value="Изменить пароль" className="btn btn-success mt-3"/>
            </form>
        </Modal2>
    );
};

export default AdminModalChangePassword;