import React from 'react';
import {Link} from "react-router-dom";
import SendForm from "../utility/SendForm";
import OpenPartTemplatePage from "./OpenPartTemplatePage";

const ForgotPassword = () => {
    return (
        <OpenPartTemplatePage title="Восстановление пароля">
            <form action="forgotPassword" method="GET" id="formForgotPassword" onSubmit={(event) => SendForm(event, (data) => {
            }, true, false)}>
                <div className="form-group">
                    <label>Адрес электронной почты</label>
                    <input type="email" className="form-control" placeholder="Введите адрес электронной почты" name="mail" />
                </div>
                <div className="mt-3 alert alert-danger d-none"></div>
                <div className="mt-3 alert alert-success d-none"></div>
                <button type="submit" className="btn btn-success mt-2">Восстановить</button>
                <div className="checkbox text-center pt-2">
                    <Link className="text-dark" to="/">Авторизация</Link>
                </div>
            </form>
        </OpenPartTemplatePage>
    );
};

export default ForgotPassword;
