import React from 'react';
import SendForm from "../../../utility/SendForm";
import {toast} from "react-toastify";
import $ from "jquery";
import {closeModalWindow, objectSize} from "../../../utility/Utility";
import Modal2 from "../../../components/Modal/Modal2";

const AdminModalDeleteCarShop = (props) => {
    return (
        <Modal2 title="Удаление автомобиля" id="deleteCarShop">
            {
                (objectSize(props.editDataCar))
                    ? <form onSubmit={(event) => SendForm(event, (data) => {
                        if (data['success']){
                            if (data['cars'])
                                props.setShopCars(data['cars']);
                            toast.success(data['success']);
                            closeModalWindow("deleteCarShop")
                            $('.btn-close').trigger('click');
                        } else
                            toast.error(data['error']);
                    }, true, false)} action="deleteCarShop" id={"formDeleteCarShop"} method="GET"
                            className="needs-validation" noValidate key={"deleteCarShop_" + props.editDataCar.id}>
                        <input type="text" className="d-none" defaultValue={props.id_shop} name="id_shop"/>
                        <input type="text" className="d-none" defaultValue={props.editDataCar.id} name="id_car"/>
                        <h4>Вы уверены что хотите удалить данный автомобиль ?</h4>
                        <div className="alert alert-danger d-none mt-3">

                        </div>
                        <div className="alert alert-success mt-3 d-none">

                        </div>
                        <input type="submit" value="Удалить" className="btn btn-success mt-3"/>
                    </form>
                    : <div className={"alert alert-danger"}>Извините, данные автомобиля не найдены.</div>
            }

        </Modal2>
    );
};

export default AdminModalDeleteCarShop;
